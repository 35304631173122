import styles from "./SignIn.module.scss";
import Email from "../ui/email/Email";
import ConfirmButton from "../ui/confirmButton/ConfirmButton";
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import Password from "../ui/password/Password";
import SignInActions from "./signInActions/SignInActions";
import { decode, encodeState, getQueryParams, loginProbe } from "../../common/utils";
import { signInUser } from "../../api/oktaAuth";
import { useLocale } from "../../common/global";
import {
  GTMAccessClickEventsPusher,
  GTMAccessImpressionEventsPusher,
  GTMCheckoutEventsPusher,
  GTMMyAccountSignInErrorsEventsPusher,
  GTMMyAccountSignInEventsPusher,
  GTMuaVPVPusher,
  getVPVSignInURL
} from "../../common/analytics";
import { RequestStatus, useAsync, useCustomRoute } from "../../common/customHooks";
import { AUTH_ERROR_CODE, Features } from "../../common/constants";
import { useLocation } from "react-router-dom";
import AccessHeader from "../accessHeader/AccessHeader";
import { ACCESS_CHECKOUT_URL, ACCESS_PAGE_URL, getSourceConfig } from "../../common/config";
import StandaloneWrapper from "../standalone/StandaloneWrapper";
import StandaloneHeader from "../standalone/standaloneHeader/StandaloneHeader";
import StandaloneLogo from "../standalone/standaloneHeader/StandaloneLogo";
import CancelButton from "../standalone/CancelButton";
import { useStandalone } from "../standalone/StandaloneContext";
import { useFeatureToggle } from "@gucci-private/feature-toggle-js";

function SignIn({ email, isCheckout = false, confirmButtonText, onEditEmailClick, isSSALogged = false }) {
  const { getToggle } = useFeatureToggle();
  const locale = useLocale();
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(true);
  const [formValidationFailed, setFormValidationFailed] = useState(false);
  const [errorMessage, setErrorMessage ] = useState("");

  const { execute: signInUserExecute, error: signInUserError, requestStatus: signInRequestStatus } = useAsync(signInUser, false);
  const { goToRoute } = useCustomRoute();
  const queryParams = getQueryParams(useLocation());
  const { nonce, returnURI, stateToken, pkceConfig } = queryParams;
  const isGuestCheckoutAllowed = !getToggle(Features.GUEST_CHECKOUT_DISABLED);
  const isGuestCheckoutAllowedForSaleAssistant = getToggle(Features.GUEST_CHECKOUT_ENABLED_FOR_SSA);
  const isSkipLoginAllowed = isGuestCheckoutAllowed  || ( isGuestCheckoutAllowedForSaleAssistant && isSSALogged);
  const { isStandaloneLogin, isVault } = useStandalone();

  useEffect(() => {
    if (!email && !queryParams.email) {
      goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}`, {}, true, true);
    }
    GTMAccessImpressionEventsPusher({
      action: "Impression Email Login",
      label: returnURI
    });
  }, []);

  useEffect(() => {
    if (signInUserError) {
      handleSubmitError(signInUserError);
    }
  }, [signInUserError]);

  function handleSignIn() {
    if (isPasswordValid) {
      loginProbe("SignIn", "Email");
      const successCallback = () => {
        if (isCheckout) {
          GTMCheckoutEventsPusher("Customer Login");
          GTMMyAccountSignInEventsPusher({
            action: "checkout sign in",
            label: "email"
          });
        } else {
          GTMMyAccountSignInEventsPusher({
            action: "sign in",
            label: "email"
          });
        }
      };
      const state = encodeState({
        checkout: isCheckout,
        rememberMe: isRememberMeChecked,
        returnURI: returnURI,
        stateToken: stateToken,
        registration: false,
        locale: locale.country + "/" + locale.language,
        ...(isStandaloneLogin && {
          isSocial: false
        })
      });
      setErrorMessage("");
      setFormValidationFailed(false);
      signInUserExecute({
        email: email,
        password,
        state,
        nonce,
        successCallback,
        pkceConfig: decode(pkceConfig)
      });
    } else {
      setFormValidationFailed(true);
      setErrorMessage(locale.messages.PASSWORD_ERROR);
      GTMAccessClickEventsPusher({
        action: "Click Email Login Error",
        label: locale.messages.PASSWORD_ERROR
      });
    }
  }

  function handleSubmitError(error) {
    let errorMessage;
    if (error.errorCode === AUTH_ERROR_CODE) {
      errorMessage = locale.messages.AUTH_ERROR;
      setFormValidationFailed(true);
    } else {
      errorMessage = locale.messages.GENERIC_ERROR;
    }
    setErrorMessage(errorMessage);
    GTMMyAccountSignInErrorsEventsPusher({
      action: isCheckout ? "checkout signin errors" : "signin errors",
      label: errorMessage
    });
    GTMAccessImpressionEventsPusher({
      action: "Impression Email Login Error",
      label: errorMessage
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleSignIn();
  }

  useEffect(() => {
    GTMuaVPVPusher("vpv.signinStep", getVPVSignInURL(locale.country, locale.language, "password", isCheckout));
  }, []);

  const formElement = <form
    className={styles.form}
    id="form"
    noValidate="novalidate"
    onSubmit={handleSubmit}
    data-testid="sign-in-form"
  >
    <Email value={email} readOnly onEditEmailClick={onEditEmailClick} />
    <Password
      value={password}
      onChange={value => setPassword(value)}
      isPasswordValid={isPasswordValid}
      setIsPasswordValid={setIsPasswordValid}
      noValidate={true}
      formValidationFailed={formValidationFailed}
      label={locale.labels.PASSWORD}
    />
    <SignInActions
      email={email}
      isCheckout={isCheckout}
      sourceConfig={getSourceConfig()}
      isRememberMeChecked={isRememberMeChecked}
      setIsRememberMeChecked={setIsRememberMeChecked}
      isStandaloneLogin={isStandaloneLogin}
    />
    <span role="alert" className={styles.formSubmitError} data-testid="error-message">
      {errorMessage}
    </span>
    <ConfirmButton buttonText={confirmButtonText} disabled={ signInRequestStatus === RequestStatus.TRIGGERED ||  signInRequestStatus === RequestStatus.SUCCESS} onClick={ () =>
      GTMAccessClickEventsPusher({
        action: "Click Email Login",
        label: "Continue"
      })
    } />
    {
      isSkipLoginAllowed && isCheckout === true &&
      <div className={styles.guestCheckoutLink}>
        <a href={`/${locale.country}/${locale.language}/login/checkout/access/guest?email=${encodeURIComponent(email)}`}
          data-testid="skip-login-link"
          onClick={() => {
            GTMCheckoutEventsPusher("Customer as Guest"); return true;
          }}
        >{locale.labels.GUEST_USER_CHECKOUT}</a>
      </div>
    }
  </form>;

  return (<>
    {
      isVault ?
        <>
          <StandaloneLogo />
          <StandaloneWrapper>
            <StandaloneHeader />
            {formElement}
            <CancelButton label="Cancel" onClick={ () =>
              GTMAccessClickEventsPusher({
                action: "Click Email Login",
                label: "Cancel"
              })
            } />
          </StandaloneWrapper>
        </>
        :
        <>
          <AccessHeader isCheckout={isCheckout} />
          {formElement}
        </>
    }
  </>);
}

SignIn.propTypes = {
  email: PropTypes.string.isRequired,
  isCheckout: PropTypes.bool,
  confirmButtonText: PropTypes.string.isRequired,
  onEditEmailClick: PropTypes.func.isRequired,
  isSSALogged: PropTypes.bool
};

export default SignIn;
