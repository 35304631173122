import styles from "./PersonalData.module.scss";
import React from "react";
import { isNotEmptyTrimmedValue, isNameValid } from "../../../common/utils";
import * as PropTypes from "prop-types";
import BirthDateInput from "../../ui/birthDateInput/BirthDateInput";
import { Input, Select } from "@gucci-private/gucci-react-components";
import { Genders } from "../../../common/constants";
import { i18n } from "../../../common/i18n";
import { Information } from "@gucci-private/customer-sdk";

function PersonalData(props) {
  const { country, language } = props;
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = {
    ...i18n(country,language,baseUrl),
    country,
    language
  };
  const { title, gender, firstName, lastName, birthDate } = props.value;
  const { setTitle, setGender, setFirstName, setLastName, setBirthDate } = props.setValue;
  const { isTitleValid, isGenderValid, isFirstNameValid, isLastNameValid, isBirthDateValid } = props.isValid;
  const { setIsTitleValid, setIsGenderValid, setIsFirstNameValid, setIsLastNameValid, setIsBirthDateValid } = props.setIsValid;

  const lastNameOnChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setLastName(inputValue);
    setIsLastNameValid(isNotEmptyTrimmedValue(inputValue) && isNameValid(inputValue));
  };

  const firstNameOnChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setFirstName(inputValue);
    setIsFirstNameValid(isNotEmptyTrimmedValue(inputValue) && isNameValid(inputValue));
  };

  const titleOnChange = (value) => {
    setTitle(value);
    setIsTitleValid(isNotEmptyTrimmedValue(value));
  };

  const genderOnChange = (value) => {
    setGender(value);
    setIsGenderValid(isNotEmptyTrimmedValue(value));
  };

  const hasMsInTitleOptions = new Information(locale.country).hasMsInTitleOption();
  const hasMxInTitleOptions = new Information(locale.country).hasMXinTitleOption();

  const titleOptions = [
    {
      name: locale.labels.TITLE_MR,
      value: "mr"
    },
    ...(hasMsInTitleOptions ? [
      {
        name: locale.labels.TITLE_MS,
        value: "ms"
      }
    ] : []),
    {
      name: locale.labels.TITLE_MRS,
      value: "mrs"
    },
    ...(hasMxInTitleOptions ? [
      {
        name: locale.labels.TITLE_A,
        value: "A"
      }
    ] : []),
    {
      name: locale.labels.TITLE_N,
      value: "N"
    }
  ];

  const genderOptions = [
    {
      name: locale.labels.GENDER_MALE,
      value: Genders.MALE
    },
    {
      name: locale.labels.GENDER_FEMALE,
      value: Genders.FEMALE
    },
    {
      name: locale.labels.GENDER_A,
      value: Genders.GENDERX
    },
    {
      name: locale.labels.GENDER_N,
      value: Genders.NOTSAY
    }
  ];

  const lastNameInput = () => {
    return <Input
      type="text"
      id="last-name-input"
      invalid={!isLastNameValid}
      label={locale.labels.LAST_NAME}
      value={lastName}
      errorMessage={locale.messages.LAST_NAME_ERROR}
      onChange={lastNameOnChange}
      formValidationFailed={props.formValidationFailed}
      required
      withBorder={true}
    />;
  };

  const firstNameInput = () => {
    return <Input
      type="text"
      id="first-name-input"
      invalid={!isFirstNameValid}
      label={locale.labels.FIRST_NAME}
      value={firstName}
      errorMessage={locale.messages.FIRST_NAME_ERROR}
      onChange={firstNameOnChange}
      formValidationFailed={props.formValidationFailed}
      required
      withBorder={true}
    />;
  };

  return <>
    <div className={`${styles.titleAndNameContainer}`}>
      <div className={styles.selectTitleWrapper}>
        {
          new Information(locale.country).hasGenderInRegistration() ?
            <Select
              value={gender}
              invalid={!isGenderValid}
              onChange={genderOnChange}
              id={"gender-select"}
              label={locale.labels.GENDER}
              errorMessage={locale.messages.GENDER_ERROR}
              options={genderOptions}
              formValidationFailed={props.formValidationFailed}
              required
              withBorder={true}
            /> :
            <Select
              value={title}
              invalid={!isTitleValid}
              onChange={titleOnChange}
              id={"title-select"}
              label={locale.labels.TITLE}
              errorMessage={locale.messages.TITLE_ERROR}
              options={titleOptions}
              formValidationFailed={props.formValidationFailed}
              required
              withBorder={true}
            />
        }
      </div>

      <div className={styles.firstNameWrapper}>
        {
          new Information(locale.country).hasLastNameBeforeFirstName() ? lastNameInput() : firstNameInput()
        }
      </div>
    </div>

    {
      new Information(locale.country).hasLastNameBeforeFirstName() ? firstNameInput() : lastNameInput()
    }

    <BirthDateInput
      key={locale.country}
      formValidationFailed={props.formValidationFailed}
      birthDate={birthDate}
      setBirthDate={setBirthDate}
      isBirthDateValid={isBirthDateValid}
      setIsBirthDateValid={setIsBirthDateValid}
      country={locale.country}
      language={locale.language}
    />
  </>;
}

PersonalData.propTypes = {
  formValidationFailed: PropTypes.bool.isRequired,
  value: PropTypes.objectOf(PropTypes.string).isRequired,
  setValue: PropTypes.objectOf(PropTypes.func).isRequired,
  isValid: PropTypes.objectOf(PropTypes.bool).isRequired,
  setIsValid: PropTypes.objectOf(PropTypes.func).isRequired,
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default PersonalData;
