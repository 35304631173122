import "./i18n";
import { getLanguagesFromCountry, i18n } from "./i18n";
import React, { useContext } from "react";
import { getFallBackCountryUnknownRedirect, PATHS_WITHOUT_LOCALIZATION } from "./config";

export function checkCorrectLanguage() {

  if (PATHS_WITHOUT_LOCALIZATION.includes(window.location.pathname)) {
    return;
  }

  const parts = window.location.pathname.split("/");
  const country = parts[1];
  let language = parts[2]?.toLowerCase();
  const configuredLanguages = getLanguagesFromCountry(country);
  if (!configuredLanguages) {
    window.location.href = getFallBackCountryUnknownRedirect();
    return;
  }
  if (!configuredLanguages.languages.includes(language)) {
    if (language === "en") {
      const configuredEnglishLanguages = configuredLanguages.languages.filter((e) => e.startsWith("en"));
      if (configuredEnglishLanguages.length>=0) {
        replaceLanguage(country,language,configuredEnglishLanguages[0]);
        return;
      }
    }
    const defaultLanguage = configuredLanguages.defaultLanguage;
    replaceLanguage(country,language,defaultLanguage);
  }
  return;
}

function replaceLanguage(country, oldLanguage, newLanguage) {
  window.location.href = window.location.href.replace(`${country}/${oldLanguage}`, `${country}/${newLanguage}`);
}

export function getLocalization(clientSource) {
  const origin = window.location.origin;
  const parts = window.location.pathname.split("/");
  const country = parts[1];
  const language = parts[2];
  const baseUrl = `${origin}/${country}/${language}`;

  return {
    ...i18n(country, language, baseUrl, clientSource),
    country,
    language,
    baseUrl
  };
}

export const LocaleContext = React.createContext();

export const useLocale = () => {
  return useContext(LocaleContext);
};

export const getCheckoutUserDetails = (rootElement) => {
  return {
    ...rootElement.dataset
  };
};

export const UserDataContext = React.createContext();

export const useCheckoutContext = () => {
  return useContext(UserDataContext);
};
