import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getQueryParams, reloadIfPkceConfigIsNotPresents } from "../../common/utils";
import { InformativeText, Title } from "../ui/typography";
import { ACCESS_CHECKOUT_URL, ACCESS_PAGE_URL } from "../../common/config";
import { useLocale } from "../../common/global";
import styles from "./ReviewProfile.module.scss";
import * as PropTypes from "prop-types";
import SignUpForm from "../signUp/signUpForm/SignUpForm";
import StandaloneWrapper from "../standalone/StandaloneWrapper";
import StandaloneLogo from "../standalone/standaloneHeader/StandaloneLogo";
import { useStandalone } from "../standalone/StandaloneContext";
import { useCustomRoute } from "../../common/customHooks";
import { i18n } from "../../common/i18n";

function ReviewProfile({ isSocial = true }) {
  const location = useLocation();
  const globalLocale = useLocale();
  const [currentLocale, setCurrentLocale] = useState({
    country: globalLocale.country,
    language: globalLocale.language
  });
  const baseUrl = `${window.location.origin}/${currentLocale.country}/${currentLocale.language}`;
  const locale = {
    ...i18n(currentLocale.country, currentLocale.language, baseUrl),
    country: currentLocale.country,
    language: currentLocale.language,
    baseUrl
  };
  const { goToRoute } = useCustomRoute();
  const queryParams = getQueryParams(location);
  const email = queryParams.email;
  const isCheckout = queryParams.isCheckout === "true";
  const socialProvider = queryParams.socialProvider;
  const pkceConfig = queryParams.pkceConfig;
  const { isVault } = useStandalone();

  useEffect(() => {
    if (!email) {
      goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}`, {}, true, true);
    }
    reloadIfPkceConfigIsNotPresents(pkceConfig);
  }, []);

  function onCountryChange(country, language){
    setCurrentLocale({
      country,
      language
    });
  }

  const content = email && (<div>
    <Title className={"is-title-xl"}>{
      isCheckout ?
        locale.titles.CHECKOUT_REVIEW_PROFILE
        :
        locale.titles.ACCESS_GUCCI
    }</Title>
    <InformativeText className={`${styles.informativeText} is-text-light`} innerHTML={
      isCheckout ?
        locale.i18n("messages.CHECKOUT_REVIEW_INFORMATION", {
          email: `<strong class=${styles.reviewEmail}>${email}</strong>`
        })
        :
        locale.messages.REVIEW_INFORMATION + `<strong class=${styles.reviewEmail}>${email}</strong>`
    } />
    <SignUpForm
      email={email}
      isSocial={isSocial}
      socialProvider={socialProvider}
      showEmail={false}
      isCheckout={isCheckout}
      confirmButtonText={locale.labels.CREATE_MY_PROFILE}
      onCountryChange={onCountryChange}
    />
  </div>);

  return (
    <div data-testid="review-profile">
      {
        isVault ?
          <>
            <StandaloneLogo />
            <StandaloneWrapper>
              { content }
            </StandaloneWrapper>
          </>
          : content
      }
    </div>
  );
}

ReviewProfile.propTypes = {
  isSocial: PropTypes.bool
};
export default ReviewProfile;
