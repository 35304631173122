export const GTMga4EventPusher = (name, event) => {
  try {
    window.analyticsSdk.tracking.sendEvent({
      name,
      event
    });
  } catch (e) {
    /* eslint-disable no-console */
    console.error(`Problem with Analystics SDK: unable to track ${name}`);
    /* eslint-enable no-console */
  }
};

export const GTMga4CheckoutEventsPusher = (checkoutLoginType) => {
  GTMga4EventPusher("add_sign_in_info", {
    checkoutLoginType
  });
};
