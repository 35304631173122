import React from "react";
import styles from "./GucciHeaderAndFooter.module.scss";

function YugaFooter() {
  return (
    <div className={`${styles.yugaFooter} yuga-footer`}>
      <div className={styles.linkSection}>
        <a href="https://www.gucci.com/int/en/st/about-gucci" target="_blank">About gucci</a>
        <a href="https://www.yuga.com/about" target="_blank">About yuga</a>
        <a href="https://kodapendant.gucci.com/terms_of_use" target="_blank">Terms of Use</a>
        <a href="https://www.gucci.com/int/en/st/privacy-landing" target="_blank">Privacy & cookie policy</a>
        <a href="https://kodapendant.gucci.com/#FAQ" target="_blank">FAQ</a>
        <a href="http://twitter.com/gucci" className={styles.social}>
          <img src={`${window.location.origin}/twitter.png`} alt="twitter" />
        </a>
        <a href="https://discord.gg/guccivault" className={styles.social}>
          <img src={`${window.location.origin}/discord.png`} alt="discord" />
        </a>
      </div>
      <div className={styles.copyright}>
        © 2016 - 2023 Guccio Gucci S.p.A. - All rights reserved. - IT VAT nr 05142860484. SIAE LICENCE # 2294/I/1936 and 5647/I/1936
      </div>
    </div>
  );
}

export default YugaFooter;
