import {
  GTMga4CheckoutEventsPusher,
  GTMga4EventPusher
} from "./analyticsGA4";
import { doWhenLoaded, getQueryParams } from "./utils";
import { GTM } from "../common/constants";
export const GTMEventsPusher = doWhenLoaded(({ eventName, category, action, label, eventData, ga4EventName }) => {
  let ga4Name = eventName;
  if (ga4EventName !== undefined) {
    ga4Name = ga4EventName;
  }
  GTMga4EventPusher(ga4Name, {
    category,
    action,
    label
  });
  if (typeof window.GTMuaEventsPusher === "function") {
    window.GTMuaEventsPusher(eventName, category, action, label, eventData);
  } else {
    document.addEventListener("GTMfunctionsReady", () => {
      window.GTMuaEventsPusher(eventName, category, action, label, eventData);
    });
  }
});

export function GTMCheckoutEventsPusher(checkoutLoginType) {
  const checkoutOptions = {
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 2,
          option: checkoutLoginType
        }
      }
    },
    checkout_login_type: checkoutLoginType
  };

  GTMEventsPusher({
    eventName: "checkout.checkoutOptions.signin",
    category: "checkout",
    action: "checkoutOptions",
    label: "signin",
    eventData: checkoutOptions
  });
  GTMga4CheckoutEventsPusher(checkoutLoginType);
  localStorage.setItem("ga_checkoutLoginType", checkoutLoginType);
}

export function GTMEmailSubscribeEventsPusher({ action, label }) {
  GTMEventsPusher({
    eventName: "emailSubscription.subscribe",
    category: "email subscription",
    action,
    label
  });
}

export function GTMEmailOptEventsPusher({ action, label }) {
  GTMEventsPusher({
    eventName: "emailSubscription.opt",
    category: "email subscription",
    action,
    label
  });
}

export function GTMMyAccountSignInEventsPusher({ action, label }) {
  GTMEventsPusher({
    eventName: "myAccount.signIn",
    category: "my account",
    action,
    label
  });
}

export function GTMMyAccountSignInErrorsEventsPusher({ action, label }) {
  GTMEventsPusher({
    eventName: "myAccount.signinErrors",
    category: "my account",
    action,
    label
  });
}

export function GTMMyAccountCreateAccountEventsPusher({ label }) {
  GTMEventsPusher({
    eventName: "myAccount.createAccount",
    category: "my account",
    action: "create account",
    label
  });
}

export function GTMMyAccountCreateAccountErrorsEventsPusher({ label }) {
  GTMEventsPusher({
    eventName: "myAccount.createAccount",
    category: "my account",
    action: "create account errors",
    label
  });
}

export function GTMMyAccountCheckoutSkipLoginEventsPusher({ label }) {
  GTMEventsPusher({
    eventName: GTM.INTERACTION_TYPE,
    category: "my account",
    action: "checkout skip create account",
    label,
    ga4EventName: "myAccount.checkout.skipLogin"
  });
}

export function GTMMyAccountOrderConfirmationEventsPusher({ label }) {
  GTMEventsPusher({
    eventName: GTM.INTERACTION_TYPE,
    category: "my account",
    action: "orderConfirmation",
    label,
    ga4EventName: "myAccount.orderConfirmation"
  });
}

export function GTMMyAccountChangeEmailFlowCreateAccountEventsPusher() {
  GTMEventsPusher({
    eventName: "myAccount.changeEmailFlowCreateAccount",
    category: "my account",
    action: "change email flow create account"
  });
}

export function GTMMyAccountChangeEmailAtValidationEventsPusher() {
  GTMEventsPusher({
    eventName: GTM.INTERACTION_TYPE,
    category: "my account",
    action: "request a new email",
    ga4EventName: "myAccount.changeEmailAtValidation"
  });
}

export function GTMGATechEventsPusher({ action, label }) {
  GTMEventsPusher({
    eventName: GTM.NON_INTERACTION_TYPE,
    category: "GA Tech",
    action,
    label,
    ga4EventName: "gaTech"
  });
}

export function GTMAccessImpressionEventsPusher({ action, label }) {
  GTMEventsPusher({
    eventName: GTM.NON_INTERACTION_TYPE,
    category: GTM.CATEGORY_EVENT_ACCESS,
    action,
    label,
    ga4EventName: "access.impression"
  });
}

export function GTMAccessClickEventsPusher({ action, label }) {
  GTMEventsPusher({
    eventName: GTM.INTERACTION_TYPE,
    category: GTM.CATEGORY_EVENT_ACCESS,
    action,
    label,
    ga4EventName: "access.click"
  });
}

export const GTMuaVPVPusher = doWhenLoaded((event, virtualPageView, eventData) => {
  if (typeof window.GTMuaVPVPusher === "function") {
    window.GTMuaVPVPusher(event, virtualPageView, eventData);
  }
});

export const dataLayerPush = doWhenLoaded((dataLayerObject) => {
  if (window.dataLayer && typeof window.dataLayer.push === "function") {
    window.dataLayer.push(dataLayerObject);
  }
});

function country__GTMvariable() {
  return window.location.href.split("/")[3];
}

function region__GTMvariable() {
  const country = country__GTMvariable();
  const mapping = {
    us: "americas",
    uk: "emeai",
    fr: "emeai",
    de: "emeai",
    at: "emeai",
    be: "emeai",
    ie: "emeai",
    nl: "emeai",
    it: "emeai",
    ch: "emeai",
    ca: "americas",
    es: "emeai",
    jp: "japan",
    kr: "korea",
    cn: "china",
    dk: "emeai",
    fi: "emeai",
    no: "emeai",
    se: "emeai",
    cz: "emeai",
    hu: "emeai",
    pl: "emeai",
    pt: "emeai",
    si: "emeai",
    au: "australia",
    bg: "emeai",
    ro: "emeai",
    tr: "emeai",
    ae: "emeai",
    sa: "emeai",
    qa: "emeai",
    kw: "emeai",
    gr: "emeai",
    lu: "emeai",
    za: "emeai",
    nz: "australia",
    hk: "hongkong",
    mx: "latam"
  };

  return mapping[country];
}

function ua_rollup_global__GTMvariable() {
  const defaultValue = true;
  const country = country__GTMvariable();
  const mapping = {
    cn: false,
    int: false,
    th: false
  };
  const result = mapping[country];

  if (typeof result !== "undefined") {
    return result;
  }
  return defaultValue;
}

function ecommerce_products__GTMvariable() {
  const ecommerceRow = window.dataLayer.find(element => element.ecommerce && element.ecommerce.products);
  if (ecommerceRow) {
    return ecommerceRow.ecommerce.products;
  }
  return [];
}

function GTMgetDimensionsInLocalStorage(variable, id, field) {
  const purchaseLocalLinkData = localStorage.getItem(variable);
  if (purchaseLocalLinkData !== null ) {
    const productData = JSON.parse(purchaseLocalLinkData)[id];
    if (productData !== undefined) {
      return productData[field];
    }
  }
  return "";
}

export const ecommerceCheckoutSignIn = doWhenLoaded(() => {
  const products = ecommerce_products__GTMvariable();

  function rollup(c) {
    if (typeof window.getExchangeRate !== "function") {
      return;
    }
    const rate = window.getExchangeRate(c);

    return {
      event: `ecommerce.checkoutSignin.${c}`,
      ecommerce: {
        checkout: {
          actionField: {
            step: 2
          },
          products: products.map(product => {
            const { name, id, price, category, variant, quantity, position } = product;
            return {
              name,
              id,
              price: (parseFloat(price) / parseFloat(rate)).toFixed(2),
              category,
              variant,
              quantity,
              position,
              dimension5: window.getDimensionsInVariable(product, 5),
              dimension11: GTMgetDimensionsInLocalStorage("ga_linkData", id, "pl_pagetype"),
              dimension12: GTMgetDimensionsInLocalStorage("ga_linkData", id, "pl_persstrategy"),
              dimension13: GTMgetDimensionsInLocalStorage("ga_linkData", id, "pl_navcategory"),
              dimension22: GTMgetDimensionsInLocalStorage("ga_linkData", id, "cs_expid"),
              dimension23: GTMgetDimensionsInLocalStorage("ga_linkData", id, "cs_varid")
            };
          })
        }
      }
    };
  }

  dataLayerPush(rollup("country"));
  if (region__GTMvariable() !== undefined) {
    dataLayerPush(rollup("region"));
  }
  if (ua_rollup_global__GTMvariable() !== false) {
    dataLayerPush(rollup("global"));
  }

  function getItems() {
    if (typeof window.getExchangeRate !== "function") {
      return;
    }
    const rate = window.getExchangeRate("global");
    return {
      items: products.map(
        product => {
          const { name, id, price, category, variant, quantity, position } = product;
          return {
            name,
            id,
            list: "UNKNOWN",
            price: (parseFloat(price) / parseFloat(rate)).toFixed(2),
            category,
            variant,
            quantity,
            position,
            dimension5: window.getDimensionsInVariable(product, 5)
          };
        })
    };
  }

  GTMga4EventPusher("view_sign_in_info", {
    ...getItems()
  });
});

export function getVPVSignInURL(country, language, step, isCheckout, isSignUp = false) {
  //TODO: this is HEAVILY adjusted {{ url vpv signin mapping }} variable from GTM
  const prefix = `/${country}/${language}/vpv/my-account/${isCheckout ? "checkout" : "view"}/`;

  const queryParams = getQueryParams(window.location);
  const fs = "flyoutsign" in queryParams;
  const fb = "flyoutbag" in queryParams;
  const rfs = "registerflyoutsign" in queryParams;
  const rfb = "registerflyoutbag" in queryParams;
  const rbp = "registerbypage" in queryParams;

  function createURL(source) {
    return `${prefix}register-${source}-step-${step}`;
  }

  if (!isSignUp) {
    return `${prefix}step-${step}`;
  }

  if (fs) {
    return createURL("flyoutsign");
  }
  if (fb) {
    return createURL("flyoutbag");
  }
  if (rfs) {
    return createURL("cta-flyoutsign");
  }
  if (rfb) {
    return createURL("cta-flyoutbag");
  }

  if (!rbp) {
    return createURL("link");
  }
}
