import { datadogLogs } from "@datadog/browser-logs";
import { capitalize, decode, isConflictResponseCode, isSuccessResponseCode } from "./utils";
import { Genders, SocialProvider } from "./constants";
import { registerCustomerFromUser } from "../api/customerReferenceApi";
import { getUserSocial, markUserProfileCompleted } from "../api/idpUserServiceApi";

export const getSignupParameters = (currentState) => {
  const {
    email,
    firstName,
    lastName,
    queryParams,
    locale,
    marketingConsent,
    isSocialSignup,
    socialProvider,
    password,
    title,
    gender,
    birthDate,
    koreaPrivacyOptions,
    orderGUID
  } = currentState;
  const pkceConfig = decode(queryParams.pkceConfig);
  const identityOnly = !!pkceConfig;
  if (!identityOnly) {
    datadogLogs.logger.warn(`Legacy flow for customer, email ${email}, identity only: ${identityOnly}`);
  }
  const [birthYear, birthMonth, birthDay] = birthDate ? birthDate.split("-") : [];

  return {
    email,
    ...password && {
      password
    },
    profile: {
      title: title || "",
      gender: gender || Genders.NOTSAY,
      ...firstName && {
        firstName: firstName.trim()
      },
      ...lastName && {
        lastName: lastName.trim()
      },
      marketingConsent: locale.country === "kr" ? getKoreaMarketingConsent(koreaPrivacyOptions) : marketingConsent,
      ...birthYear && {
        birthYear
      },
      ...birthMonth && {
        birthMonth
      },
      ...birthDay && {
        birthDay
      },
      orderGUID: orderGUID,
      ...locale.country === "kr" && (koreaPrivacyOptions ? {
        privacyOptions: koreaPrivacyOptions
      } : {
        privacyOptions: defaultKoreaPrivacyOptions
      }),
      country: locale.country
    },
    additionalData: {
      social: isSocialSignup || false,
      ...socialProvider && {
        socialProvider
      },
      returnURI: queryParams.returnURI,
      language: locale.language,
      alwaysRedirect: queryParams.alwaysRedirect || false
    },
    identityOnly
  };
};

export const createCustomerFromUser =  async (queryParams, social, client, customerCreationFailureHandler, customerData) => {
  const pkceConfig = decode(queryParams.pkceConfig);
  if (pkceConfig && pkceConfig.clientId) {
    const {
      email,
      password,
      locale,
      gender,
      accessToken,
      title,
      firstName,
      lastName,
      birthDate,
      koreaPrivacyOptions,
      orderGUID,
      marketingConsent,
      channel
    } = customerData;
    const { isSocial, socialProvider } = social;
    const signupParameters = getSignupParameters({
      email,
      title,
      firstName,
      lastName,
      queryParams,
      locale,
      marketingConsent,
      isSocialSignup: isSocial,
      socialProvider,
      password,
      gender,
      birthDate,
      koreaPrivacyOptions,
      orderGUID
    });
    const country = locale.country;
    const source = client && client.source;
    const customerReferenceData = {
      ...signupParameters.profile,
      email: signupParameters.email,
      country: ("uk" === country.toLowerCase() ? "GB" : country).toUpperCase(),
      ...source && {
        source: source
      },
      ...(channel || queryParams.channel) && {
        recruitmentChannel: channel ?? queryParams.channel
      }
    };

    if (SocialProvider.KAKAO === socialProvider) {
      const userSocialResponse = await getUserSocial(email, signupParameters.additionalData.socialProvider);
      if (isSuccessResponseCode(userSocialResponse.status)) {
        const userSocial = JSON.parse(await userSocialResponse.text());
        customerReferenceData["socialId"] = userSocial.externalId;
        customerReferenceData["socialName"] = capitalize(SocialProvider.KAKAO);
      }
    }

    datadogLogs.logger.info("Customer Reference Data", {
      accessToken,
      customerReferenceData,
      language: locale.language
    });

    const response = await registerCustomerFromUser(accessToken, customerReferenceData, locale.language);
    if (!isSuccessResponseCode(response.status) && !isConflictResponseCode(response.status)) {
      await customerCreationFailureHandler();
      return Promise.reject(response);
    }
    const profileCompletedData = {
      email: signupParameters.email,
      profile: signupParameters.profile,
      additionalData: {
        ...signupParameters.additionalData
      }
    };
    return await markUserProfileCompleted(profileCompletedData);
  } else {
    return {
      status: 200
    };
  }
};

export const defaultKoreaPrivacyOptions = {
  "privacy1-kr": false,
  "privacy2-kr": false,
  "privacy3-kr-terms": false,
  "privacy4-kr": false,
  "privacy5-kr": false,
  "privacy6-kr": false
};

export const getNamesFromToken = (idToken) => {
  let firstName = "";
  let lastName = "";
  try {
    const result = decode(idToken.split(".")[1]);
    firstName = result.firstName;
    lastName = result.lastName;
  } catch (e) {
    datadogLogs.logger.error("Unable to decode id token");
  }
  return {
    firstName: firstName,
    lastName: lastName
  };
};

export const getKoreaMarketingConsent = (privacyOptions) => {
  return privacyOptions ? privacyOptions["privacy1-kr"] && privacyOptions["privacy5-kr"] : false;
};
