import { is4byteCharPresent } from "./utils";

function hasNumber(password){
  const regex = /\d/;
  return regex.test(password);
}

function hasSpecialCharacter(password){
  const regex = /[!+,\-./:;<=>?@]/;
  return regex.test(password);
}

export function validatePassword(password) {
  const lengthCheck = password.length>=8;
  const numberCheck = hasNumber(password);
  const specialCharacterCheck = hasSpecialCharacter(password);
  return {
    isValid: lengthCheck && numberCheck && specialCharacterCheck,
    passedValidations: {
      lengthCheck,
      numberCheck,
      specialCharacterCheck
    }
  };
}

export function validateEmail(email) {
  const regex = /^(?:[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]|[^\u0000-\u007F])+[^.]@(?:[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]|[^\u0000-\u007F])+(?:\.|\u3002)(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]|[^\u0000-\u007F\u3002]){2,20}$/ig;

  const validLength = email.length >= 5 && email.length <= 100;
  const validPattern = regex.test(email);
  const has4byteChar = is4byteCharPresent(email);
  return validLength && validPattern && !has4byteChar;
}
