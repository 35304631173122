import React from "react";
import { Image } from "semantic-ui-react";
import SVG from "react-inlinesvg";
import styles from "./StandaloneLogo.module.scss";
import { useStandalone } from "../StandaloneContext";

function StandaloneLogo() {
  const { client } = useStandalone();

  return (
    <div className={styles.heroImage}>
      {client.svg ?
        <SVG src={client.svg} />
        : client.img ?
          <Image src={client.img} alt={client.name} />
          : <></>
      }
    </div>
  );
}

export default StandaloneLogo;
