import React, { createContext, useContext, useState } from "react";
import * as PropTypes from "prop-types";
import { decode, getPkceConfig } from "../../common/utils";
import { ClientType } from "../../common/constants";
import {
  DISPLAY_MODE_STANDALONE,
  getClient,
  getSourceConfig
} from "../../common/config";

const StandaloneCtx = createContext({});

export const useStandalone = () => useContext(StandaloneCtx);

function StandaloneContext({ children }) {
  const pkceConfig = getPkceConfig();
  const sourceConfig = getSourceConfig();

  const [client, setClient] = useState(pkceConfig && getClient(decode(pkceConfig).clientId));
  const [isStandaloneLogin, setIsStandaloneLogin] = useState(DISPLAY_MODE_STANDALONE === sourceConfig.display && client);
  const [isVault, setIsVault] = useState(client?.source === ClientType.THE_VAULT);
  const [isGcom, setIsGcom] = useState(client?.source === ClientType.GUCCI_STORE);
  const [isInternational, setIsInternational] = useState(client?.international);
  const [countryList, setCountryList] = useState(client?.countryList);

  const changeClient = async (pkceConfig, displayMode) => {
    setClient(getClient(decode(pkceConfig).clientId));
    setIsStandaloneLogin(DISPLAY_MODE_STANDALONE === displayMode);
    setIsVault(client?.source === ClientType.THE_VAULT);
    setIsGcom(client?.source === ClientType.GUCCI_STORE);
    setIsInternational(client?.international);
    setCountryList(client?.countryList);
  };

  return (
    <StandaloneCtx.Provider value={{
      client,
      isStandaloneLogin,
      changeClient,
      isVault,
      isGcom,
      isInternational,
      countryList
    }}>
      {children}
    </StandaloneCtx.Provider>
  );
}

StandaloneContext.propTypes = {
  children: PropTypes.node.isRequired
};

export default StandaloneContext;
