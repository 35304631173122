import React from "react";
import * as PropTypes from "prop-types";
import { DISPLAY_MODE_STANDALONE, getClient, getSourceConfig } from "../../../common/config";
import { decode, getQueryParamPkceConfig } from "../../../common/utils";
import { ClientType } from "../../../common/constants";

export const StandaloneTheme = React.lazy(() => import("./StandaloneTheme"));
export const VaultTheme = React.lazy(() => import("./VaultTheme"));
export const GucciStoreTheme = React.lazy(() => import("./GucciStoreTheme"));
export const GucciAppTheme = React.lazy(() => import("./GucciAppTheme"));
export const YugaTheme = React.lazy(() => import("./YugaTheme"));
export const BAATheme = React.lazy(() => import("./BAATheme"));

function themeSelector(value) {
  const favicon = document.getElementById("favicon");
  if (favicon) {
    favicon.href = window.location.origin+"/favicon.png";
  }

  switch (value) {
  case ClientType.THE_VAULT:
    return <VaultTheme />;
  case ClientType.GUCCI_STORE:
    return <GucciStoreTheme />;
  case ClientType.GUCCI_APP:
    return <GucciAppTheme />;
  case ClientType.YUGA:
    if (favicon) {
      favicon.href = window.location.origin+"/favicon-yuga-2.ico";
    }
    return <YugaTheme />;
  case ClientType.BAA_SPA:
    return <BAATheme />;
  default:
    return <StandaloneTheme />;
  }
}

function ThemeWrapper({ children, suspenseFallback = <></> }) {
  const isDisplayModeStandalone = DISPLAY_MODE_STANDALONE === getSourceConfig().display;
  const pkceConfig = getQueryParamPkceConfig();
  const client = pkceConfig && getClient(decode(pkceConfig).clientId);
  return (
    <>
      <React.Suspense fallback={suspenseFallback}>
        {isDisplayModeStandalone && themeSelector(client?.source)}
      </React.Suspense>
      {children}
    </>
  );
}

ThemeWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  suspenseFallback: PropTypes.element
};

export default ThemeWrapper;
