export const URL_PARAM_OVERLAY_RESET_LINK_EXPIRED = "resetLinkExpired";
export const MODAL_PARENT_ID = "signin-modal-wrapper";
export const APP_PARENT_ID = "signin-app-wrapper";

//ERROR CODES
export const AUTH_ERROR_CODE = "E0000004";
export const SAME_PASSWORD_ERROR = "E0000080";

//RESPONSE CODES
export const RESPONSE_OK = 200;
export const RESPONSE_CONFLICT = 409;

//COMPUTED VALUES
export const CURRENT_YEAR = (new Date()).getFullYear();
export const CURRENT_MONTH = (new Date()).getMonth() + 1;
export const CURRENT_DAY = (new Date()).getDate();
export const MIN_ALLOWED_YEAR = parseInt(CURRENT_YEAR - 100);

export const ClientType = {
  GUCCI_APP: "GUCCI_APP",
  THE_VAULT: "THE_VAULT",
  GUCCI_STORE: "GUCCI_STORE",
  SIGNIN_SPA: "SIGNIN_SPA",
  BAA_SPA: "BAA_SPA",
  YUGA: "YUGA",
  DEFAULT: "DEFAULT"
};

export const GUCCI_STORE_GTM = "GTM-5CMWJM";

export const ProfileStatusType = {
  NEW: "NEW",
  UNVERIFIED_NEW: "UNVERIFIED_NEW",
  UNVERIFIED_EXISTING: "UNVERIFIED_EXISTING",
  VERIFIED: "VERIFIED",
  SOCIAL_NEW: "SOCIAL_NEW",
  SOCIAL_EXISTING: "SOCIAL_EXISTING",
  WIRE_EDIT_NEW: "WIRE_EDIT_NEW"
};

export const OperationalMode = {
  ACTIVATE_AND_SET_PASSWORD : "Activate and set password",
  RESET_PASSWORD: "Reset password",
  SET_PASSWORD: "Set password"
};

export const Features = {
  GOOGLE_REGISTRATION: "google-registration",
  APPLE_REGISTRATION: "apple-registration",
  KAKAO_REGISTRATION: "kakao-registration",
  REGISTER_AFTER_CHECKOUT: "register-after-checkout",
  GUEST_CHECKOUT_DISABLED: "guest-checkout-disabled",
  GUEST_CHECKOUT_ENABLED_FOR_SSA: "guest-checkout-enabled-for-ssa",
  STANDALONE_SOCIAL_SIGNUP: "standalone-social-signup"
};

export const featuresList = [
  Features.GOOGLE_REGISTRATION,
  Features.KAKAO_REGISTRATION,
  Features.APPLE_REGISTRATION,
  Features.REGISTER_AFTER_CHECKOUT,
  Features.GUEST_CHECKOUT_DISABLED,
  Features.GUEST_CHECKOUT_ENABLED_FOR_SSA,
  Features.STANDALONE_SOCIAL_SIGNUP
];

export const SocialProvider = {
  APPLE: "apple",
  GOOGLE: "google",
  KAKAO: "kakao"
};

export const Genders = {
  FEMALE: "FEMALE",
  MALE: "MALE",
  GENDERX: "GENDERX",
  NOTSAY: "NOTSAY"
};

export const KakaoFollowFlag = {
  ADDED: "FOLLOW_ADDED",
  BLOCKED: "FOLLOW_BLOCKED",
  NONE: "FOLLOW_NONE"
};

//GTM
export const GTM = {
  NON_INTERACTION_TYPE: "nonInteractionEvent",
  INTERACTION_TYPE: "interactionEvent",
  CATEGORY_EVENT_ACCESS: "Access"
};

export const ENGLISH_LANG_FOR_INPUT = "en";
