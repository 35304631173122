import React from "react";
import styles from "./DataOutsideCountryConsent.module.scss";
import * as PropTypes from "prop-types";
import { Checkbox } from "@gucci-private/gucci-react-components";
import { i18n } from "../../../common/i18n";

function DataOutsideCountryConsent({ country, language, setDataOutsideCountry, dataOutsideCountry, setIsDataOutsideCountryValid, isDataOutsideCountryValid }) {
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country, language, baseUrl);

  function handleOnChange(event) {
    setDataOutsideCountry(event.target.checked);
    setIsDataOutsideCountryValid(event.target.checked);
  }

  return (
    <div className={styles.wrapper}>
      <Checkbox
        id="data-outside-country-consent"
        formValidationFailed={!isDataOutsideCountryValid}
        checked={dataOutsideCountry}
        onChange={handleOnChange}
        label={locale.i18n("labels.DATA_OUTSIDE_COUNTRY_CONSENT_CHECKBOX")}
        withBorder={true}
      />
    </div>
  );
}

DataOutsideCountryConsent.propTypes = {
  dataOutsideCountry: PropTypes.bool.isRequired,
  setDataOutsideCountry: PropTypes.func.isRequired,
  setIsDataOutsideCountryValid: PropTypes.func.isRequired,
  isDataOutsideCountryValid: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default DataOutsideCountryConsent;