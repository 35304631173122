import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import * as PropTypes from "prop-types";
import {
  OperationalMode,
  ProfileStatusType,
  URL_PARAM_OVERLAY_RESET_LINK_EXPIRED
} from "../../common/constants";
import {
  GTMAccessClickEventsPusher,
  GTMCheckoutEventsPusher,
  GTMMyAccountChangeEmailFlowCreateAccountEventsPusher,
  GTMMyAccountSignInErrorsEventsPusher
} from "../../common/analytics";
import { RequestStatus, useAsync, useCustomRoute } from "../../common/customHooks";
import { getQueryParams, isGuestCheckoutAllowedFromOptimizely, doGuestCheckout, reloadIfPkceConfigIsNotPresents } from "../../common/utils";
import { useLocale } from "../../common/global";
import { validateEmail } from "../../common/validators";
import { getUser } from "../../api/idpUserServiceApi";
import UserCheckForm from "../userCheckForm/UserCheckForm";
import SignIn from "../signIn/SignIn";
import Overlay from "../ui/overlay/Overlay";
import ForgotPassword from "../forgotPassword/ForgotPassword";
import {
  ACCESS_CHECKOUT_URL,
  ACCESS_PAGE_URL,
  getSourceConfig,
  SOCIAL_EXISTS_CHECKOUT_URL,
  SOCIAL_EXISTS_URL,
  COUNTRIES_WITHOUT_GUEST_CHECKOUT
} from "../../common/config";
import SocialExists from "../socialExists/SocialExists";
import SignUp from "../signUp/SignUp";
import SetPassword from "../setPassword/SetPassword";
import { getAssistantCustomerData } from "../../api/hybrisDistanceSalesApi";
import EmailVerification from "../emailVerification/EmailVerification";

function Access(props) {
  const locale = useLocale();
  const location = useLocation();
  const { path } = useRouteMatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [formValidationFailed, setformValidationFailed] = useState(false);
  const isCheckout = props.isCheckout;
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const queryParams = getQueryParams(location);
  const { alwaysRedirect, nonce, overlay: showOverlay, returnURI, stateToken, pkceConfig } = queryParams;
  const overlayIsReset = queryParams.overlayIsReset === "true";
  const [isOpen, setIsOpen] = useState(true);
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  const { execute: executeGetUser, value: getUserResponse, error: getUserReqError, requestStatus: getUserRequestStatus, setRequestStatus: getUserSetRequestStatus } = useAsync(getUser, false);
  const { execute: executeGetAssistantCustomerData, value: getAssistantCustomerDataResponse } = useAsync(getAssistantCustomerData, false);
  const isGuestCheckoutAllowed = !COUNTRIES_WITHOUT_GUEST_CHECKOUT.includes(locale.country);
  const { goToRoute } = useCustomRoute();
  const [isSSALogged, setIsSSALogged] = useState(false);

  useEffect(() => {
    executeGetAssistantCustomerData(locale.country, locale.language);
    reloadIfPkceConfigIsNotPresents(pkceConfig);
  }, []);

  useEffect(() => {
    if (getAssistantCustomerDataResponse && getAssistantCustomerDataResponse.status === 200) {
      //Check if the response is not empty
      const contentType = getAssistantCustomerDataResponse.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        getAssistantCustomerDataResponse.json().then(res => {
          const { asmID } = res;
          if (asmID != null) {
            setIsSSALogged(true);
          }
        });
      }
    }
  }, [getAssistantCustomerDataResponse]);

  const onEditEmailClick = () => {
    GTMMyAccountChangeEmailFlowCreateAccountEventsPusher();
    setShouldAutoFocus(true);
    goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}`, {}, true);
  };

  const handleExistingUser = (getUserResponse) => {
    getUserResponse.json().then(profileStatus => {
      if (profileStatus.status === ProfileStatusType.SOCIAL_EXISTING || profileStatus.status === ProfileStatusType.SOCIAL_NEW) {
        promptForSocial(profileStatus.status);
      } else if (profileStatus.status === ProfileStatusType.WIRE_EDIT_NEW) {
        goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}/emailChallenge`, {}, true);
      } else {
        goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}/signin`, {}, true);
      }
    });
  };

  const promptForSocial = (status) => {
    // todo - use location search instead of individual query params being passed
    goToRoute(`${isCheckout ? SOCIAL_EXISTS_CHECKOUT_URL : SOCIAL_EXISTS_URL}`, {
      email,
      status,
      nonce,
      stateToken,
      returnURI,
      pkceConfig,
      ...alwaysRedirect && {
        alwaysRedirect
      }
    });
  };

  useEffect(() => {
    if (getUserResponse) {
      setEmail(email.toLowerCase());
      if (getUserResponse.status === 200) {
        getUserSetRequestStatus(RequestStatus.NEW);
        handleExistingUser(getUserResponse);
      } else if (getUserResponse.status === 404) {
        getUserSetRequestStatus(RequestStatus.NEW);
        if (isGuestCheckoutAllowedFromOptimizely(isGuestCheckoutAllowed) && isCheckout) {
          doGuestCheckout(email, locale, GTMCheckoutEventsPusher);
        } else if (getSourceConfig().signupEnabled) {
          goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}/signup`, {}, true);
        } else {
          //TODO Temporary error message, waiting to understand final UX
          setErrorMessage(locale.messages.GENERIC_ERROR);
        }
      } else {
        setErrorMessage(locale.messages.GENERIC_ERROR);
      }
    }
  }, [getUserResponse]);

  useEffect(() => {
    if (getUserReqError) {
      setErrorMessage(locale.messages.GENERIC_ERROR);
    }
  }, [getUserReqError]);

  function onUserCheckSubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    if (isEmailValid) {
      executeGetUser(email);
    } else {
      setformValidationFailed(true);
      GTMAccessClickEventsPusher({
        action: "Click Access Page Error",
        label: locale.messages.EMAIL_ERROR
      });
    }
  }

  useEffect(() => {
    const hasError = queryParams.error;
    if (hasError) {
      GTMMyAccountSignInErrorsEventsPusher({
        action: "signin errors",
        label: "access general error"
      });
      setErrorMessage(locale.messages.GENERIC_ERROR);
    }
    (async function existingUserCheck() {
      const emailParam = queryParams.email;
      if (emailParam) {
        setEmail(emailParam);
        if (validateEmail(emailParam)) {
          setIsEmailValid(true);
          const isAccessPage = location.pathname === `/${locale.country}/${locale.language}${ACCESS_PAGE_URL}`;
          const isCheckoutAccessPage = location.pathname === `/${locale.country}/${locale.language}${ACCESS_CHECKOUT_URL}`;
          if ((isAccessPage || isCheckoutAccessPage) && !hasError) {
            await executeGetUser(emailParam);
          }
        } else {
          setformValidationFailed(true);
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route exact path={path}>
        <>
          {showOverlay === URL_PARAM_OVERLAY_RESET_LINK_EXPIRED &&
            <Overlay
              title={locale.titles.OVERLAY_RESET_LINK_EXPIRED}
              message={overlayIsReset ? locale.messages.OVERLAY_RESET_LINK_EXPIRED : locale.messages.OVERLAY_ACTIVATION_LINK_EXPIRED}
              isOpen={isOpen}
              setIsOpen={setIsOpen} />
          }
          <div data-testid="access-gucci">
            <UserCheckForm
              shouldAutoFocus={shouldAutoFocus}
              email={email}
              isEmailValid={isEmailValid}
              setIsEmailValid={setIsEmailValid}
              setEmail={setEmail}
              onSubmit={onUserCheckSubmit}
              errorMessage={errorMessage}
              formValidationFailed={formValidationFailed}
              disableConfirm={getUserRequestStatus === RequestStatus.TRIGGERED || (getUserRequestStatus === RequestStatus.SUCCESS && errorMessage === "")}
              isCheckout={isCheckout}
            />
          </div>
        </>
      </Route>
      <Route exact path={`${path}/signin`}>
        <SignIn
          email={email}
          confirmButtonText={isCheckout ? locale.labels.CHECKOUT_CONTINUE : locale.labels.CONFIRM}
          isCheckout={isCheckout}
          onEditEmailClick={onEditEmailClick}
          isSSALogged={isSSALogged}
        />
      </Route>
      <Route path={`${path}/signup`}>
        <SignUp email={email} isCheckout={isCheckout} onEditEmailClick={onEditEmailClick} />
      </Route>
      <Route path={`${path}/signin/forgot`}>
        <ForgotPassword isCheckout={isCheckout} email={email} onEditEmailClick={onEditEmailClick} />
      </Route>
      <Route path={`${path}/socialExists`}>
        <SocialExists isCheckout={isCheckout} isSSALogged={isSSALogged} />
      </Route>
      <Route path={`${path}/forgotPassword`}>
        <div data-testid="reset-password">
          <SetPassword isCheckout={isCheckout} mode={OperationalMode.RESET_PASSWORD} />
        </div>
      </Route>
      <Route path={`${path}/emailChallenge`}>
        <EmailVerification isCheckout={isCheckout} onEditEmailClick={onEditEmailClick} email={email}
          isSSALogged={isSSALogged} />
      </Route>
    </Switch>
  );
}

Access.propTypes = {
  isCheckout: PropTypes.bool
};

export default Access;
