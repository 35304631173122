import { useCallback, useEffect, useState } from "react";
import { useLocale } from "./global";
import { useHistory, useLocation } from "react-router-dom";
import { getQueryParams } from "./utils";
import { useFeatureToggle } from "@gucci-private/feature-toggle-js";
import { Features, SocialProvider } from "./constants";

export const useAsync = (asyncFunction, immediate = true) => {
  const [requestStatus, setRequestStatus] = useState(RequestStatus.NEW);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const execute = useCallback((...args) => {
    setValue(null);
    setError(null);
    setRequestStatus(RequestStatus.TRIGGERED);
    return asyncFunction(...args)
      .then( (response) => {
        setRequestStatus(RequestStatus.SUCCESS);
        setValue(response);
      })
      .catch((error) => {
        setRequestStatus(RequestStatus.FAILED);
        setError(error);
      });
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return {
    execute,
    value,
    error,
    requestStatus,
    //TODO Why this setter is needed?
    setRequestStatus
  };
};

export const useSocialProviders = () => {
  const { getToggle } = useFeatureToggle();
  const location = useLocation();
  const isGoogleRegistrationEnabled = getToggle(Features.GOOGLE_REGISTRATION);
  const isKakaoRegistrationEnabled = getToggle(Features.KAKAO_REGISTRATION);
  const pongoapple22 = "romeomontecchi" === getQueryParams(location).pongoapple22;
  const isAppleRegistrationEnabled = pongoapple22 || getToggle(Features.APPLE_REGISTRATION);

  return {
    [SocialProvider.GOOGLE]: isGoogleRegistrationEnabled,
    [SocialProvider.APPLE]: isAppleRegistrationEnabled,
    [SocialProvider.KAKAO]: isKakaoRegistrationEnabled
  };
};

export const useCustomRoute = () => {
  const locale = useLocale();
  const location = useLocation();
  const history = useHistory();

  const goToRoute = (url, queryParams = {}, preserveCurrentParams = false, replace= false) => {
    let fullUrl = `/${locale.country}/${locale.language}${url}`;
    let queryParamsString = [];

    Object.entries({
      ...(preserveCurrentParams ? getQueryParams(location) : {}),
      ...queryParams
    }).forEach(([key, value]) => {
      if (value !== undefined) {
        queryParamsString.push(`${key}=${value}`);
      }
    });
    queryParamsString = queryParamsString.join("&");
    if (queryParamsString.length) {
      fullUrl += `?${queryParamsString}`;
    }

    if (replace){
      history.replace(fullUrl);
    } else {
      history.push(fullUrl);
    }
  };
  const redirectToRoute = (url, queryParams = {}, basePath = window.location.origin) => {
    let fullUrl = `${basePath}/${locale.country}/${locale.language}${url}`;
    let queryParamsString = [];

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined) {
        queryParamsString.push(`${key}=${value}`);
      }
    });
    queryParamsString = queryParamsString.join("&");
    if (queryParamsString.length) {
      fullUrl += `?${queryParamsString}`;
    }
    window.location.href = fullUrl;
  };

  return {
    goToRoute,
    redirectToRoute
  };
};

export const RequestStatus = {
  NEW: "New",
  TRIGGERED: "Triggered",
  SUCCESS: "Success",
  FAILED: "Failed"
};
