import React from "react";
import { useLocale } from "../../../common/global";
import { SubTitle } from "../../ui/typography";
import ConfirmButton from "../../ui/confirmButton/ConfirmButton";
import CancelButton from "../../standalone/CancelButton";
import styles from "./GucciAppLogout.module.scss";
import { closeSession } from "@gucci-private/gucci-auth";
import { getGucciAppReturnScheme } from "../../../common/config";

function GucciAppLogout() {
  const locale = useLocale();
  const CLOSE_URI =  getGucciAppReturnScheme() + "close";
  const LOGOUT_URI = getGucciAppReturnScheme() + "logout";

  const handleLogoutYesButtonClick = () => {
    closeSession(() => window.location.href = LOGOUT_URI);
  };

  const handleLogoutNoButtonClick = () => {
    window.location.href = CLOSE_URI;
  };

  return (<div className={styles.outerDiv}>
    <div data-testid="gucciapp-logout">
      <SubTitle>{locale.messages.LOGOUT}</SubTitle>
      <ConfirmButton buttonText={locale.labels.CONFIRM} onClick={handleLogoutYesButtonClick} />
      <CancelButton label={locale.labels.CANCEL} onClick={handleLogoutNoButtonClick} goBack={false} />
    </div>
  </div>
  );
}

export default GucciAppLogout;
