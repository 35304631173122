import React from "react";
import * as PropTypes from "prop-types";
import { MIN_ALLOWED_YEAR } from "../../../common/constants";
import { DateSelector } from "@gucci-private/gucci-react-components";
import { countries } from "../../../common/config";
import { i18n } from "../../../common/i18n";
import { Information } from "@gucci-private/customer-sdk";
import { useStandalone } from "../../standalone/StandaloneContext";
import { ClientType } from "../../../common/constants";

function BirthDateInput({ birthDate, setBirthDate, isBirthDateValid, setIsBirthDateValid, formValidationFailed, country, language }) {
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = {
    ...i18n(country,language,baseUrl),
    language,
    country,
    baseUrl
  };
  const { client } = useStandalone();
  const isYearOfBirthMandatory = !(locale.country === "us" && [ClientType.GUCCI_STORE, ClientType.GUCCI_APP, ClientType.BAA_SPA].includes(client?.source));

  const config = {
    day: {
      min: 1,
      max: 31,
      placeholder: locale.labels.PLACEHOLDER_DD,
      label: locale.labels.DAY
    },
    month: {
      min: 1,
      max: 12,
      placeholder: locale.labels.PLACEHOLDER_MM,
      label: locale.labels.MONTH
    },
    year: {
      min: MIN_ALLOWED_YEAR,
      max: new Date().getFullYear(),
      placeholder: locale.labels.PLACEHOLDER_YYYY,
      label: locale.labels.YEAR,
      required: isYearOfBirthMandatory
    }
  };

  const { registrationAge } = countries[locale.country] || {};

  const minAgeConfiguration = {
    minRegistrationAge: registrationAge,
    minRegistrationAgeErrorMessage: locale.i18n("messages.REGISTRATION_AGE_ERROR", {
      age: registrationAge
    })
  };

  const isCountryWithMMddYYYYFormat = new Information(locale.country).hasMM_DD_YYYY_DobFormat();
  const isCountryWithYYYYmmDDFormat = new Information(locale.country).hasYYYY_MM_DD_DobFormat();
  const dateFormat = isCountryWithMMddYYYYFormat ? "MM-DD-YYYY" : (isCountryWithYYYYmmDDFormat ? "YYYY-MM-DD" : "DD-MM-YYYY");

  return (
    <DateSelector
      key={locale.country}
      label={locale.labels.DATE_OF_BIRTH}
      configuration={config}
      localeDateFormat={dateFormat}
      errorMessage={locale.messages.BIRTHDATE_ERROR}
      minAgeConfiguration={registrationAge ? minAgeConfiguration : undefined}
      date={birthDate}
      setDate={setBirthDate}
      isValid={isBirthDateValid}
      setIsValid={setIsBirthDateValid}
      formValidationFailed={formValidationFailed}
      withBorder={true}
      data-testid="date-selector"
    />
  );
}

BirthDateInput.propTypes = {
  birthDate: PropTypes.string.isRequired,
  setBirthDate: PropTypes.func.isRequired,
  isBirthDateValid: PropTypes.bool.isRequired,
  setIsBirthDateValid: PropTypes.func.isRequired,
  formValidationFailed: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default BirthDateInput;
