import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AccessHeader from "../accessHeader/AccessHeader";
import SignUpForm from "./signUpForm/SignUpForm";
import { ACCESS_CHECKOUT_URL, ACCESS_PAGE_URL } from "../../common/config";
import { useCustomRoute } from "../../common/customHooks";
import { getQueryParams } from "../../common/utils";
import { useLocation } from "react-router-dom";
import StandaloneWrapper from "../standalone/StandaloneWrapper";
import StandaloneLogo from "../standalone/standaloneHeader/StandaloneLogo";
import StandaloneHeader from "../standalone/standaloneHeader/StandaloneHeader";
import { useLocale } from "../../common/global";
import CancelButton from "../standalone/CancelButton";
import { GTMAccessClickEventsPusher, GTMAccessImpressionEventsPusher } from "../../common/analytics";
import { useStandalone } from "../standalone/StandaloneContext";
import { ClientType } from "../../common/constants";

function SignUp({ email, isCheckout, onEditEmailClick }) {
  const { goToRoute } = useCustomRoute();
  const queryParams = getQueryParams(useLocation());
  const locale = useLocale();
  const [currentLocale, setCurrentLocale] = useState({
    country: locale.country,
    language: locale.language
  });
  const { isVault, client } = useStandalone();
  const returnURI = queryParams.returnURI;

  useEffect(() => {
    if (!email && !queryParams.email) {
      goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}`, {}, true, true);
    }
    GTMAccessImpressionEventsPusher({
      action: "Impression Email Sign Up",
      label: returnURI
    });
  }, []);

  function onCountryChange(country, language){
    setCurrentLocale({
      country,
      language
    });
  }

  return <>
    <div data-testid="sign-up">
      {
        isVault ?
          <>
            <StandaloneLogo />
            <StandaloneWrapper>
              <StandaloneHeader country={currentLocale.country}
                language={currentLocale.language} />
              <SignUpForm
                email={email}
                isCheckout={isCheckout}
                onEditEmailClick={onEditEmailClick}
                onCountryChange={onCountryChange}
              />
              <CancelButton
                label="Cancel"
                onClick={ () =>
                  GTMAccessClickEventsPusher({
                    action: "Click Email Sign up",
                    label: "Cancel"
                  })
                }
                country={currentLocale.country}
                language={currentLocale.language}
              />
            </StandaloneWrapper>
          </>
          :
          <>
            <AccessHeader isCheckout={isCheckout} />
            <SignUpForm
              email={email}
              isCheckout={isCheckout}
              onEditEmailClick={onEditEmailClick}
              onCountryChange={onCountryChange}
            />
          </>
      }
    </div>
    {client?.source === ClientType.YUGA && <span className="yuga-hand" />}
  </>;
}

SignUp.propTypes = {
  email: PropTypes.string.isRequired,
  isCheckout: PropTypes.bool,
  onEditEmailClick: PropTypes.func
};

export default SignUp;
