import React from "react";
import { ONE_TRUST_TOKEN } from "../../common/config";

function OneTrustScripts() {
  const black = "#000";
  const white = "#fff";

  const authBlockScript = document.createElement("script");

  authBlockScript.src = `https://cdn.cookielaw.org/consent/${ONE_TRUST_TOKEN}/OtAutoBlock.js`;
  authBlockScript.defer = true;
  authBlockScript.type = "text/javascript";

  const sdkStubScript = document.createElement("script");

  sdkStubScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  sdkStubScript.defer = true;
  sdkStubScript.type = "text/javascript";
  sdkStubScript.dataset.documentLanguage = "true";
  sdkStubScript.dataset.domainScript = ONE_TRUST_TOKEN;

  document.head.appendChild(authBlockScript);
  document.head.appendChild(sdkStubScript);

  return (
    <>
      <script
        type="text/javascript"
        data-testid="ot-script"
        defer={true}
        dangerouslySetInnerHTML={{
          __html: `
    function OptanonWrapper() {
    var t = document.querySelectorAll("a.custom-one-trust-cookie-policy-link:not([data-one-trust-cookie-policy-link-fixed]");
    [].forEach.call(t,
    function (link) {
      var cookiePolicyUrl = "/st/privacy-landing";
      link.setAttribute("href", cookiePolicyUrl),
      link.setAttribute("target", "_blank"),
      link.setAttribute("data-one-trust-cookie-policy-link-fixed", "")
    })
  }`
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `#ot-sdk-btn.ot-sdk-show-settings,#ot-sdk-btn.optanon-show-settings { background-color: ${white} !important; border-color: ${black} !important; color: ${black} !important; }#ot-sdk-btn.ot-sdk-show-settings:hover,#ot-sdk-btn.optanon-show-settings:hover { background-color: ${black} !important; border-color: ${black} !important; color: ${white} !important; }`
        }}
      />
    </>
  );
}

export default OneTrustScripts;
