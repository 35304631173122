import React, { useEffect, useState } from "react";
import styles from "./Social.module.scss";
import { useLocale } from "../../common/global";
import {
  resetChallengeTokens,
  signInSocialUser
} from "../../api/oktaAuth";
import { useLocation } from "react-router-dom";
import {
  capitalize,
  encodeState,
  getQueryParams,
  loginProbe
} from "../../common/utils";
import * as PropTypes from "prop-types";
import { RequestStatus, useAsync } from "../../common/customHooks";
import {
  GTMAccessImpressionEventsPusher,
  GTMAccessClickEventsPusher,
  GTMMyAccountSignInEventsPusher
} from "../../common/analytics";
import { socialProvidersConfigs } from "../../common/socialConfig";
import { SIGN_IN_CLIENT_ID } from "../../common/config";
import TokenManager from "../../api/tokenManager";

export function Social({ isCheckout, isStandaloneLogin, socialProvidersStatus, labels }) {
  const { execute: signInUserExecute, error: signInUserError, requestStatus: signInRequestStatus } = useAsync(signInSocialUser, false);
  const [ errorMessage, setErrorMessage ] = useState("");
  const [ socialProviderUsed, setSocialProviderUsed ] = useState("");

  const locale = useLocale();
  const location = useLocation();
  const { nonce, returnURI, stateToken, pkceConfig } = getQueryParams(location);

  const buttonLabels = labels ? labels : locale.labels;

  const storeStandalonePkceConfig = (state, nonce, socialProviderName) => {
    const standaloneAuthConfig = {
      pkceConfig,
      state,
      nonce,
      socialProviderName
    };
    localStorage.setItem("standaloneAuthConfig", JSON.stringify(standaloneAuthConfig));
  };

  async function handleSocialLogin(socialProviderName) {
    setSocialProviderUsed(socialProviderName);
    setErrorMessage("");
    loginProbe("SignIn", socialProviderName);
    GTMMyAccountSignInEventsPusher({
      action: isCheckout ? `${socialProviderName} button click in checkout` : `${socialProviderName} button click`,
      label: location.pathname
    });
    GTMAccessClickEventsPusher({
      action: `Click ${capitalize(socialProviderName)} Login`,
      label: "Continue"
    });
    const state = encodeState({
      returnURI: returnURI,
      stateToken: stateToken,
      locale: locale.country + "/" + locale.language,
      checkout: isCheckout || false,
      rememberMe: true,
      registration: false,
      ...(isStandaloneLogin && {
        isSocial: true
      })
    });

    let pkceConfigToUse;
    storeStandalonePkceConfig(state, nonce, socialProviderName);
    try {
      await resetChallengeTokens();
    } catch (error) {
      setErrorMessage(locale.messages.GENERIC_ERROR);
      GTMAccessImpressionEventsPusher({
        action: "Impression " + socialProviderUsed + " Login Error",
        label: "Social sign in error"
      });
    }
    pkceConfigToUse = {
      clientId: SIGN_IN_CLIENT_ID,
      codeChallenge: TokenManager.getCodeChallenge(),
      redirectUri: window.location.origin + "/access/authorization/callback",
      scopes: ["openid"]
    };

    signInUserExecute({
      state,
      nonce,
      pkceConfig: pkceConfigToUse,
      socialProviderName
    });
  }

  useEffect(() => {
    if (signInUserError) {
      setErrorMessage(locale.messages.GENERIC_ERROR);
      GTMAccessImpressionEventsPusher({
        action: "Impression " + socialProviderUsed + " Login Error",
        label: "Social sign in error"
      });
    }
  }, [signInUserError]);

  return (<div className={styles.socialWrapper}>
    {
      socialProvidersConfigs
        .filter(provider => socialProvidersStatus[provider.name])
        .map((socialProviderConfig) => {
          return socialProviderConfig.button({
            disabled: signInRequestStatus === RequestStatus.TRIGGERED || (signInRequestStatus === RequestStatus.SUCCESS && errorMessage === ""),
            id: socialProviderConfig.testId,
            key: socialProviderConfig.labelKey,
            label: buttonLabels[socialProviderConfig.labelKey] ? buttonLabels[socialProviderConfig.labelKey] : "",
            onClick: () => handleSocialLogin(socialProviderConfig.name)
          });
        })
    }
    {errorMessage && (
      <span role="alert" className={styles.formSubmitError} data-testid="social-error-message">
        {errorMessage}
      </span>
    )}
  </div>);
}

Social.propTypes = {
  isCheckout: PropTypes.bool,
  socialProvidersStatus: PropTypes.objectOf(PropTypes.bool),
  isStandaloneLogin: PropTypes.bool,
  labels: PropTypes.string
};

export default Social;
