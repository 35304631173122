import React from "react";
import styles from "./PrivacyPolicyForKorea.module.scss";
import * as PropTypes from "prop-types";
import { Checkbox } from "@gucci-private/gucci-react-components";
import { i18n } from "../../../common/i18n";

function PrivacyPolicyForKorea({ isPrivacy3Valid, isPrivacy4Valid, privacy1, privacy3, privacy4, setIsPrivacy3Valid, setIsPrivacy4Valid, setPrivacy1, setPrivacy2, setPrivacy3, setPrivacy4, setPrivacy5, setPrivacy6, country, language }) {
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country,language,baseUrl);

  function onChange(event, setter, validitySetter) {
    setter(event.target.checked);
    if (typeof validitySetter === "function") {
      validitySetter(event.target.checked);
    }
  }

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{locale.titles.PRIVACY_OPTIONS}</h3>
      <Checkbox
        id="privacy_3_6_2"
        formValidationFailed={!isPrivacy3Valid}
        checked={privacy3}
        onChange={(event) => {
          onChange(event, setPrivacy3, setIsPrivacy3Valid);
          onChange(event, setPrivacy6);
          onChange(event, setPrivacy2);
        }}
        label={locale.labels.PRIVACY_3_6_2}
        withBorder={true}
      />
      <Checkbox
        id="privacy_4"
        formValidationFailed={!isPrivacy4Valid}
        checked={privacy4}
        onChange={(event) => {
          onChange(event, setPrivacy4, setIsPrivacy4Valid);
        }}
        label={locale.labels.PRIVACY_4_OTHER}
        withBorder={true}
      />
      <Checkbox
        id="privacy_1_5"
        // this is the only one NOT mandatory
        checked={privacy1}
        onChange={(event) => {
          onChange(event, setPrivacy1);
          onChange(event, setPrivacy5);
        }}
        label={locale.labels.PRIVACY_1_5_MARKETING}
        withBorder={true}
      />
    </div>
  );
}

PrivacyPolicyForKorea.propTypes = {
  isPrivacy3Valid: PropTypes.bool.isRequired,
  isPrivacy4Valid: PropTypes.bool.isRequired,
  privacy1: PropTypes.bool.isRequired,
  privacy3: PropTypes.bool.isRequired,
  privacy4: PropTypes.bool.isRequired,
  setIsPrivacy3Valid: PropTypes.func.isRequired,
  setIsPrivacy4Valid: PropTypes.func.isRequired,
  setPrivacy1: PropTypes.func.isRequired,
  setPrivacy2: PropTypes.func.isRequired,
  setPrivacy3: PropTypes.func.isRequired,
  setPrivacy4: PropTypes.func.isRequired,
  setPrivacy5: PropTypes.func.isRequired,
  setPrivacy6: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default PrivacyPolicyForKorea;
