import React from "react";
import * as PropTypes from "prop-types";

function StandaloneWrapper({ children }) {
  return (
    <div className={"standaloneWrapper"}>
      { children }
    </div>
  );
}

StandaloneWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default StandaloneWrapper;
