import {
  capitalize,
  decode,
  encodeState,
  getQueryParams,
  isSuccessResponseCode,
  splitKoreanNames
} from "../common/utils";
import { isCRMCustomerExisting } from "../api/customerReferenceHelper";
import { initOktaTokens, signInSocialUser } from "../api/oktaAuth";
import { closeSession } from "@gucci-private/gucci-auth";
import TokenManager from "../api/tokenManager";
import {
  getSocialReviewStatus,
  getUserSocial,
  getUserSocialFollow,
  updateUserSocialProviders
} from "../api/idpUserServiceApi";
import { datadogLogs } from "@datadog/browser-logs";
import { getUser, updateUserProfile } from "../api/customerReferenceApi";
import { Genders, KakaoFollowFlag, SocialProvider } from "../common/constants";
import { KAKAO_CHANNEL_ID } from "../common/config";
import { Consent } from "@gucci-private/customer-sdk";
import { createCustomerFromUser, defaultKoreaPrivacyOptions, getNamesFromToken } from "../common/signupHelper";

function getStandaloneAuthConfig() {
  return JSON.parse(localStorage.getItem("standaloneAuthConfig"));
}

function redirectToSignInHome(queryParams, standaloneAuthConfig) {
  const state = decode(standaloneAuthConfig.state);
  Object.assign(queryParams, {
    pkceConfig: standaloneAuthConfig.pkceConfig,
    returnURI: state.returnURI
  });
  const queryString = Object.keys(queryParams).map(key => key + "=" + queryParams[key]).join("&");
  window.location.href = `${window.location.origin}/${state.locale}/access/view?${queryString}`;
}

async function performStandaloneSocialSignin(standaloneAuthConfig) {
  const state = standaloneAuthConfig.state;
  const nonce = standaloneAuthConfig.nonce;
  const pkceConfig = standaloneAuthConfig.pkceConfig;
  const socialProviderName = standaloneAuthConfig.socialProviderName;

  const newurl = window.location.origin + window.location.pathname + "?pkceConfig=" + pkceConfig;
  window.history.pushState({}, "", newurl);

  await signInSocialUser({
    state: state,
    nonce: nonce,
    pkceConfig: decode(pkceConfig),
    shouldCloseSession: false,
    idpName: socialProviderName
  });
}

async function getSocialUser(preferredUsername, standaloneAuthConfig) {
  let socialUser;
  if (standaloneAuthConfig.socialProviderName === SocialProvider.KAKAO) {
    const userSocialResponse = await getUserSocial(preferredUsername, standaloneAuthConfig.socialProviderName);
    if (isSuccessResponseCode(userSocialResponse.status)) {
      const text = await userSocialResponse.text();
      socialUser = JSON.parse(text);
    }
  }

  return socialUser;
}

async function getSocialFollow(email, socialId, standaloneAuthConfig) {
  let socialFollow;
  if (standaloneAuthConfig.socialProviderName === SocialProvider.KAKAO) {
    const socialFollowResponse = await getUserSocialFollow(email, socialId);
    if (isSuccessResponseCode(socialFollowResponse.status)) {
      const text = await socialFollowResponse.text();
      socialFollow = JSON.parse(text);
    } else {
      datadogLogs.logger.warn("Failed requesting getUserSocialFollow");
    }
  } else {
    datadogLogs.logger.info(`No getUserSocialFollow request: ${standaloneAuthConfig.socialProviderName}, ${email}`);
  }

  return socialFollow;
}

async function getSocialGender(socialGender) {
  let gender = null;
  if (socialGender === "female") {
    gender = Genders.FEMALE;
  } else if (socialGender === "male") {
    gender = Genders.MALE;
  }
  return gender;
}

async function redirectToSocialReview(idToken, standaloneAuthConfig) {
  const token = decode(idToken.split(".")[1]);
  const state = decode(standaloneAuthConfig.state);
  const { preferred_username, firstName, lastName } = token;
  const { returnURI, checkout, locale, stateToken } = state;
  const socialUser = await getSocialUser(preferred_username, standaloneAuthConfig);

  let socialNames;
  let gender;
  let birthdate;
  let phone;
  let socialId;
  let followFlag = KakaoFollowFlag.NONE;
  let followDate;

  if ((SocialProvider.KAKAO === standaloneAuthConfig.socialProviderName) && socialUser) {
    socialId = socialUser.externalId ?? undefined;
    if (socialUser.profile) {
      socialNames = splitKoreanNames(socialUser.profile.displayName);
      gender = await getSocialGender(socialUser.profile.gender);
      birthdate = socialUser.profile.birthdate ?? undefined;
      phone = socialUser.profile.phoneNumber ?? undefined;
    }

    const socialFollow = await getSocialFollow(preferred_username, socialId, standaloneAuthConfig);

    if (socialFollow) {
      for (const i in socialFollow.channels) {
        const aChannel = socialFollow.channels[i];
        if (aChannel.channelPublicId === KAKAO_CHANNEL_ID) {
          followFlag = `FOLLOW_${aChannel.relation}`;
          followDate = aChannel.updatedAt;
        }
      }
    }
  }

  let queryParamsToAdd = {
    email: preferred_username,
    firstName: socialNames ? (socialNames["first"] ?? firstName) : firstName,
    lastName: socialNames ? (socialNames["last"] ?? lastName) : lastName,
    returnURI,
    isCheckout: checkout,
    pkceConfig: standaloneAuthConfig.pkceConfig,
    socialProvider: standaloneAuthConfig.socialProviderName,
    nonce: standaloneAuthConfig.nonce,
    stateToken: stateToken
  };
  queryParamsToAdd = Object.assign(queryParamsToAdd, gender && {
    gender
  });
  queryParamsToAdd = Object.assign(queryParamsToAdd, birthdate && {
    birthdate
  });
  queryParamsToAdd = Object.assign(queryParamsToAdd, phone && {
    phone
  });
  queryParamsToAdd = Object.assign(queryParamsToAdd, socialId && {
    socialId
  });
  queryParamsToAdd = Object.assign(queryParamsToAdd, followFlag && {
    followFlag
  });
  queryParamsToAdd = Object.assign(queryParamsToAdd, followDate && {
    followDate
  });

  queryParamsToAdd["phone"] = phone && queryParamsToAdd["phone"] ? encodeURIComponent(queryParamsToAdd["phone"]) : queryParamsToAdd["phone"];
  queryParamsToAdd["followDate"] = followDate && queryParamsToAdd["followDate"] ? encodeURIComponent(queryParamsToAdd["followDate"]) : queryParamsToAdd["followDate"];

  const queryString = Object.keys(queryParamsToAdd).map(key => key + "=" + queryParamsToAdd[key]).join("&");

  window.location.href = `${window.location.origin}/${locale}/access/socialReviewProfile?${queryString}`;
}

const decodeEmail = (idToken) => decode(idToken.split(".")[1]).preferred_username;

const isSocialReviewRequired = async (res) => {
  if (!isSuccessResponseCode(res.status)) {
    datadogLogs.logger.warn("Error while fetching social review");
    throw new Error("Error while fetching social review");
  }
  const result = await res.json();
  return result.status === "REQUIRED";
};

const getLocale = (state) => {
  try {
    const [country, language] = state.locale.split("/");
    return {
      country,
      language
    };
  } catch {
    return undefined;
  }
};

export async function performSocialSignInOrReviewProfile(client) {
  let queryParams = getQueryParams(window.location);

  if (queryParams.code) {
    try {
      await initOktaTokens();
      const idToken = TokenManager.getIdToken();
      const accessToken = TokenManager.getAccessToken();
      const isCustomerExisting = await isCRMCustomerExisting(accessToken);
      let standaloneAuthConfig = getStandaloneAuthConfig();
      const authConfigState = decode(standaloneAuthConfig.state);
      const { socialProviderName } = standaloneAuthConfig;
      const email = decodeEmail(idToken);
      const isWireEditCustomerResponse = await getSocialReviewStatus(email);
      const socialReviewRequired = await isSocialReviewRequired(isWireEditCustomerResponse);
      if (isCustomerExisting || !socialReviewRequired) {
        // WireEdit registered customers will not go through social review
        if (!isCustomerExisting) {
          // Land on my account page on successful registration
          standaloneAuthConfig = {
            ...standaloneAuthConfig,
            state: encodeState({
              ...authConfigState,
              registration: true
            })
          };
          //if sign-in url is used to login, pkce would not be present in queryParams
          if (!queryParams.pkceConfig) {
            queryParams = {
              ...queryParams,
              pkceConfig: standaloneAuthConfig.pkceConfig
            };
          }
          const locale = getLocale(authConfigState);
          const consent = new Consent(locale.country);
          const isMarketingConsentImplicit = consent.isMarketingConsentImplicit();
          const isMarketingConsentExplicitlyPreset = consent.isMarketingConsentExplicitlyPreset();
          const marketingConsentDefaultValue = isMarketingConsentImplicit || isMarketingConsentExplicitlyPreset;
          const socialData = {
            isSocial: true,
            socialProvider: socialProviderName
          };
          const { firstName, lastName } = getNamesFromToken(idToken);
          const customerData = {
            email,
            locale,
            gender: Genders.NOTSAY,
            accessToken,
            firstName,
            lastName,
            ...locale.country === "kr" && {
              koreaPrivacyOptions: defaultKoreaPrivacyOptions
            },
            marketingConsent: marketingConsentDefaultValue,
            channel: "wr" //This customer is assumed to be registered from WireEdit, hence channel is passed as wr(Wire)
          };
          await createCustomerFromUser(queryParams, socialData, client,  () => {}, customerData);
        }
        if (idToken && accessToken && socialProviderName) {
          const email = decodeEmail(idToken);
          const response = await updateUserSocialProviders(email, socialProviderName, accessToken);
          const text = await response.text();
          const country = getLocale(authConfigState).country;

          datadogLogs.logger.info(`SocialPromotion ${socialProviderName} ${text} ${email} ${country}`);

          if (socialProviderName && SocialProvider.KAKAO === socialProviderName) {
            const userResponse = await getUser(accessToken);
            if (isSuccessResponseCode(userResponse.status)) {
              const user = await userResponse.json();
              const userSocialResponse = await getUserSocial(email, socialProviderName);
              if (isSuccessResponseCode(userSocialResponse.status)) {
                const userSocial = JSON.parse(await userSocialResponse.text());
                user.socialId = userSocial.externalId;
                user.socialName = capitalize(socialProviderName);
                await updateUserProfile(user, accessToken);
              }
            }
          }
        }
        await performStandaloneSocialSignin(standaloneAuthConfig);
      } else {
        await redirectToSocialReview(idToken, getStandaloneAuthConfig());
      }
    } catch (error) {
      datadogLogs.logger.warn("SignIn :" + JSON.stringify(error));
      await closeSession();
      queryParams = {
        ...queryParams,
        error: "GenericError"
      };
    }
  }
  if (queryParams.error) {
    redirectToSignInHome(queryParams, getStandaloneAuthConfig());
  }
}
