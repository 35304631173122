import React from "react";
import * as PropTypes from "prop-types";
import { i18n } from "../../common/i18n";
import styles from "./ValueProposition.module.scss";

function ValueProposition({ country, language, titleKey, contentKey }) {
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country, language, baseUrl);
  const title = locale.i18n(titleKey);
  const content = locale.i18n(contentKey);
  return (
    <article className={`col-12 col-md-4 ${styles["item"]}`}>
      {
      // eslint-disable-next-line no-warning-comments
      /* <div>
        <div aria-hidden="true" className="item-dot"></div>
      </div> */}
      <h3 className="is-title-s">{title}</h3>
      <p className="is-text-light">{content}</p>
    </article>
  );
}

ValueProposition.propTypes = {
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  titleKey: PropTypes.string.isRequired,
  contentKey: PropTypes.string.isRequired
};
export default ValueProposition;
