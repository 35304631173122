import React from "react";
import * as PropTypes from "prop-types";
import styles from "./CancelButton.module.scss";
import { useLocale } from "../../common/global";
import { i18n } from "../../common/i18n";

function CancelButton({ onClick = () => {}, label="CANCEL", country, language, goBack = true }) {

  const globalLocale = useLocale();
  const currentCountry = country ? country : globalLocale.country;
  const currentLanguage = language ? language : globalLocale.language;
  const locale = i18n(currentCountry,currentLanguage,globalLocale.baseUrl);

  return (
    <div className={styles.cancelButtonWrapper}>
      <button type="submit"
        className={styles.cancelButton}
        data-testid="cancel-button"
        onClick={() => {
          onClick();
          goBack && window.history.back();
        }}>
        { label === "CONTINUE" ? locale.labels.CONTINUE : locale.labels.CANCEL }
      </button>
    </div>
  );
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  country: PropTypes.string,
  language: PropTypes.string,
  goBack: PropTypes.bool
};

export default CancelButton;
