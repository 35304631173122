import "core-js";
import "unfetch/polyfill/index";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Modal from "react-modal";
import App from "./App";
import { getLocalization, LocaleContext, getCheckoutUserDetails, UserDataContext, checkCorrectLanguage } from "./common/global";
import { FeatureToggleContext } from "@gucci-private/feature-toggle-js";
import { MODAL_PARENT_ID, APP_PARENT_ID, featuresList } from "./common/constants";
import ThemeWrapper from "./components/ui/themes/ThemeWrapper";
import { decode, getQueryParams } from "./common/utils";
import { getClient } from "./common/config";

window.SignIn = {
  init({ node }) {

    if (!node) {
      return;
    }

    checkCorrectLanguage();

    const appElement = document.createElement("div");
    const modalElement = document.createElement("div");
    modalElement.setAttribute("id", MODAL_PARENT_ID);
    appElement.setAttribute("id", APP_PARENT_ID);

    node.appendChild(appElement);
    node.appendChild(modalElement);
    Modal.setAppElement(appElement);

    const { pkceConfig } = getQueryParams(window.location);
    const client = pkceConfig && getClient(decode(pkceConfig).clientId);
    const localization = getLocalization(client?.source);

    ReactDOM.render(
      <ThemeWrapper>
        <LocaleContext.Provider value={localization}>
          <FeatureToggleContext.Provider environment={process.env.REACT_APP_ENVIRONMENT} features={featuresList}
            variant={localization.country}>
            <UserDataContext.Provider value={getCheckoutUserDetails(node)}>
              <App/>
            </UserDataContext.Provider>
          </FeatureToggleContext.Provider>
        </LocaleContext.Provider>
      </ThemeWrapper>,
      appElement
    );
  },
  ...(window.SignIn || {})
};

window.SignIn.init({
  node:document.getElementById("root")
});
