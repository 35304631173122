import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { verifyIdpRecoveryToken } from "../../api/oktaAuth";
import SetPasswordForm from "./setPasswordForm/SetPasswordForm";
import { OperationalMode, URL_PARAM_OVERLAY_RESET_LINK_EXPIRED } from "../../common/constants";
import { ACCESS_PAGE_URL } from "../../common/config";
import { getQueryParams, redirectToLocalUrl } from "../../common/utils";
import { useLocale } from "../../common/global";
import Loader from "../ui/loader/Loader";
import { useLocation } from "react-router-dom";

function SetPassword({ isCheckout = false, mode = OperationalMode.ACTIVATE_AND_SET_PASSWORD }) {
  const locale = useLocale();
  const [transaction, setTransaction] = useState(null);
  const queryParams = getQueryParams(useLocation());
  const returnURI = queryParams["returnURI"]? queryParams["returnURI"] : "/";

  async function executeVerifyIdpRecoveryToken() {
    try {
      const transaction = await verifyIdpRecoveryToken();
      setTransaction(transaction);
    } catch (error) {
      redirectToLocalUrl(`${ACCESS_PAGE_URL}?overlay=${URL_PARAM_OVERLAY_RESET_LINK_EXPIRED}&overlayIsReset=${mode === OperationalMode.RESET_PASSWORD}&returnURI=${returnURI}`, locale.baseUrl);
    }
  }

  useEffect(() => {
    executeVerifyIdpRecoveryToken();
  }, []);

  return transaction
    ? <SetPasswordForm isCheckout={isCheckout} mode={mode} transaction={transaction} />
    : <Loader />;
}

SetPassword.propTypes = {
  isCheckout: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(OperationalMode))
};

export default SetPassword;
