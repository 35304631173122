import React from "react";
import styles from "./ConfirmButton.module.scss";
import * as PropTypes from "prop-types";
import { Button } from "@gucci-private/gucci-react-components";

function ConfirmButton({ buttonText, disabled = false, onClick }) {
  return (
    <div className={styles.confirmButtonWrapper}>
      <Button label={buttonText} onClick={onClick} disabled={disabled}
        id="confirm-button" />
    </div>
  );
}

ConfirmButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default ConfirmButton;
