import passwordStyles from "./Password.module.scss";
import * as PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { validatePassword } from "../../../common/validators";
import { isNotEmpty } from "../../../common/utils";
import { Input } from "@gucci-private/gucci-react-components";
import { i18n } from "../../../common/i18n";

function Password(props) {
  const { country, language } = props;
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country,language,baseUrl);

  const passwordInput = useRef(null);
  const [passwordValidation, setPasswordValidation] = useState({
    isValid: false,
    passedValidations: {
      lengthCheck: false,
      numberCheck: false,
      specialCharacterCheck: false
    }
  });
  const [isMasked, setIsMasked] = useState(true);
  const [isComponentUnTouched, setIsComponentUnTouched] = useState(true);

  function onChange(event) {
    const inputValue = event.target.value;
    props.onChange(inputValue);
    if (!props.showValidationMessages) {
      props.setIsPasswordValid(isNotEmpty(inputValue));
    } else {
      const validation = validatePassword(inputValue);
      setPasswordValidation(validation);
      props.setIsPasswordValid(validation.isValid);
    }
  }

  function togglePassword() {
    setIsMasked(!isMasked);
    passwordInput.current.focus();
  }
  const isError = !props.isPasswordValid && (!isComponentUnTouched || props.formValidationFailed);
  function showValidationMessages() {
    return (
      <div
        id="password-validation-messages"
        {...isError && {
          "role": "alert"
        }}
        data-testid="password-validation-messages"
        className={passwordStyles.validations}>
        <div className={`${passwordStyles.passwordErrorMessage}
            ${isNotEmpty(props.value) && passwordValidation.passedValidations.lengthCheck? passwordStyles.validationPassed: ""}`} >
          {locale.messages.PASSWORD_CHARACTER_CHECK}
        </div>
        <div className={`${passwordStyles.passwordErrorMessage}
            ${isNotEmpty(props.value) && passwordValidation.passedValidations.numberCheck? passwordStyles.validationPassed: ""}`}>
          {locale.messages.PASSWORD_NUMBER_CHECK}
        </div>
        <div className={`${passwordStyles.passwordErrorMessage}
            ${isNotEmpty(props.value) && passwordValidation.passedValidations.specialCharacterCheck? passwordStyles.validationPassed: ""}`}>
          {locale.messages.PASSWORD_SPECIAL_CHARACTER_CHECK}
        </div>
      </div>
    );
  }

  return (
    <>
      {
        locale.country === "jp" &&
        <p className={passwordStyles.passwordInfoText}>
          {locale.messages.PASSWORD_INFORMATION}
        </p>
      }
      <div data-testid="password-container" className={`${passwordStyles.wrapper} ${(isError ? passwordStyles.inputWrapperWithError : "")}`}>
        <Input
          ref={passwordInput}
          type={isMasked ? "password": "text"}
          id="password-input"
          placeholder={locale.labels.PASSWORD}
          value={props.value}
          onChange={onChange}
          label={props.label}
          invalid = {!props.isPasswordValid}
          formValidationFailed={props.formValidationFailed}
          onBlur={() => {
            setIsComponentUnTouched(false);
          }}
          required
          {...props.showValidationMessages && {
            "aria-describedby": "password-validation-messages"
          }}
          withBorder={true}
        />

        <button type="button" className={`${passwordStyles.toggleShowPassword} ${isMasked ? "" : passwordStyles.passwordShown}`} onClick={togglePassword}
          data-testid="toggle-show-password-button" aria-pressed={!isMasked}>
          <svg>
            <use xlinkHref="#svg-icon-eye" />
          </svg>
          <span className="visuallyHidden">{locale.labels.SHOW_PASSWORD}</span>
        </button>
        {props.showValidationMessages && showValidationMessages()}
      </div>
    </>
  );
}

Password.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isPasswordValid: PropTypes.bool.isRequired,
  setIsPasswordValid: PropTypes.func.isRequired,
  showValidationMessages: PropTypes.bool,
  label: PropTypes.string.isRequired,
  formValidationFailed: PropTypes.bool,
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

Password.defaultProps = {
  showValidationMessages: false,
  focusEnable: false,
  formValidationFailed: false,
  country: window.location.pathname.split("/")[1],
  language: window.location.pathname.split("/")[2]
};

export default Password;
