import * as PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Input } from "@gucci-private/gucci-react-components";
import styles from "./Email.module.scss";
import { validateEmail } from "../../../common/validators";
import { i18n } from "../../../common/i18n";

function Email(props) {
  const { country, language } = props;
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country,language,baseUrl);

  const inputRef = useRef(null);

  const onChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    if (props.onChange) {
      props.onChange(inputValue);
    }
    if (props.setValidity) {
      props.setValidity(validateEmail(inputValue));
    }
  };

  useEffect(() => {
    if (props.autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  },[]);

  return (
    <div className = {`${styles.container} ${props.readOnly ? styles.hasEditButton : ""}`}>
      <Input
        autoFocus={props.autofocus}
        ref={inputRef}
        type="email"
        id="email-input"
        label={locale.labels.EMAIL}
        value={props.value}
        onChange={onChange}
        readOnly={props.readOnly}
        invalid = {props.invalid}
        errorMessage={locale.messages.EMAIL_ERROR}
        formValidationFailed = {props.formValidationFailed}
        required
        withBorder={true}
      />
      {props.readOnly && <button type="button" data-testid = {"edit-email"} className={styles.edit}
        onClick={props.onEditEmailClick}><span className="visuallyHidden">{locale.labels.EDIT_EMAIL}</span></button>}
    </div>
  );
}

Email.propTypes = {
  value: PropTypes.string.isRequired,
  autofocus: PropTypes.bool,
  invalid: PropTypes.bool,
  readOnly: PropTypes.bool,
  formValidationFailed: PropTypes.bool,
  onChange:  (props) => {
    if (!props.readOnly) {
      if (typeof(props.onChange) !== "function") {
        return new Error(`\`Email\` requires onChange handler to be a function when not in readOnly mode, was \`${typeof(props.onChange)}\``);
      }
    }
  },
  setValidity: PropTypes.func,
  onEditEmailClick: (props) => {
    if (props.readOnly) {
      if (typeof(props.onEditEmailClick) !== "function") {
        return new Error(`\`Email\` requires onEditEmailClick to be a function when in readOnly mode and not locked, was \`${typeof(props.onEditEmailClick)}\``);
      }
    }
  },
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

Email.defaultProps = {
  country: window.location.pathname.split("/")[1],
  language: window.location.pathname.split("/")[2]
};
export default Email;
