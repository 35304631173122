function getTranslations(language, baseUrl) {
  switch (language) {
  case "ar":
    return arabic(baseUrl);
  case "de":
    return german(baseUrl);
  case "en_gb":
    return englishGreatBritain(baseUrl);
  case "es":
    return spanish(baseUrl);
  case "es_mx":
    return mexicanSpanish(baseUrl);
  case "fr":
    return french(baseUrl);
  case "it":
    return italian(baseUrl);
  case "ja":
    return japanese(baseUrl);
  case "ko":
    return korean(baseUrl);
  case "zh":
    return simplifiedChinese(baseUrl);
  case "zh_hk":
    return traditionalChinese(baseUrl);
  default:
    return englishGreatBritain(baseUrl);
  }
}

function arabic(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "سجّل اشتراكك باستخدام بريدك الإلكتروني وكلمة المرور أو يمكنك إنشاء ملف تعريف في حال كنت مشتركاً جديداً."
    }
  };
}

function german(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an oder erstellen Sie ein Profil, wenn Sie neu sind."
    }
  };
}
function englishGreatBritain(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Sign in with your email and password or create a profile if you are new."
    }
  };
}
function spanish(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Inicie sesión con su correo electrónico y contraseña o cree un perfil si no lo tiene."
    }
  };
}
function mexicanSpanish(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Inicie sesión con su correo electrónico y contraseña o cree un perfil si es nuevo."
    }
  };
}
function french(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Connectez-vous à l’aide de votre adresse e-mail et votre mot de passe ou créez un profil si vous êtes un nouveau client."
    }
  };

} function italian(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Accedi con il tuo indirizzo e-mail e la password oppure crea un profilo se non sei già iscritto."
    }
  };

} function japanese(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "アカウント登録されたメールアドレスとパスワードでサインインをしてください。初めて利用されるお客さまは、新規で専用アカウントを作成してください。"
    }
  };

} function korean(baseUrl) {
  return {};
}

function simplifiedChinese(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "使用电子邮件和密码登录，亦或创建新顾客档案"
    }
  };
}

function traditionalChinese(baseUrl) {
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "請使用您的電郵及密碼登入，如果您是新用戶請建立個人檔案。"
    }
  };
}

export { getTranslations as gucciStoreTranslations };

