import React, { useEffect } from "react";
import SignUpForm from "../signUp/signUpForm/SignUpForm";
import { ACCESS_PAGE_URL } from "../../common/config";
import { useCustomRoute } from "../../common/customHooks";
import { getQueryParams } from "../../common/utils";
import { useLocale } from "../../common/global";
import { i18n } from "../../common/i18n";
import { useLocation } from "react-router-dom";
import styles from "./SignupExistingCustomer.module.scss";
import OneTrustScripts from "../oneTrust/OneTrustScripts";

function SignupExistingCustomer() {
  const { goToRoute } = useCustomRoute();
  const queryParams = getQueryParams(useLocation());
  const globalLocale = useLocale();
  const country = globalLocale.country;
  const language = globalLocale.language;
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country, language, baseUrl);
  const email = queryParams.email;
  const firstName = queryParams.firstName;
  const lastName = queryParams.lastName;

  useEffect(() => {
    if (!email) {
      goToRoute(`${ACCESS_PAGE_URL}`, {}, true, true);
    }
  }, []);

  return (
    <div className={styles.signUpExistingCustomer}>
      <OneTrustScripts />
      <div className={styles.existingCustomerHeader}>
        <h2 className="is-title-xl">
          <span dangerouslySetInnerHTML={{
            __html: locale.titles.DOS_TITLE
          }} />
        </h2>
        <p className="has-mb-8">{`${firstName} ${lastName}, ${locale.titles.DOS_SUBTITLE}`}</p>
        <p><b>{email}</b></p>
      </div>
      <SignUpForm
        email={queryParams.email}
        showEmail={false}
        existingDosCustomer={true}
        confirmButtonText={locale.labels.DOS_SUBMIT}
      />
    </div>
  );
}

export default SignupExistingCustomer;
