import React, { useEffect } from "react";
import { ga4AnalyticsUrl } from "../../common/config";
import { useStandalone } from "../standalone/StandaloneContext";
import { createScriptDOMElement } from "../../common/utils";

function GA4Loader() {
  const { isStandaloneLogin } = useStandalone();

  useEffect(() => {
    if (isStandaloneLogin) {
      const analyticsSdk = createScriptDOMElement({
        testId: "ga4-dummy-script",
        text: `window.analyticsSdk = {
          tracking: {
            sendEvent: function ({ name: name, event: event }) {},
          },
        };`
      });
      document.body.appendChild(analyticsSdk);

      const ga4Analytics = createScriptDOMElement({
        testId: "ga4-umd",
        src: `${ga4AnalyticsUrl}/index.umd.js?v=${new Date().getTime()}`
      });
      document.body.appendChild(ga4Analytics);
    }
  }, []);

  return (<></>);
}

export default GA4Loader;

