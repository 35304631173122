import Email from "../ui/email/Email";
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { ACCESS_CHECKOUT_URL, ACCESS_PAGE_URL } from "../../common/config";
import { RequestStatus, useAsync, useCustomRoute } from "../../common/customHooks";
import { useLocale } from "../../common/global";
import ConfirmButton from "../ui/confirmButton/ConfirmButton";
import { InformativeText, Title } from "../ui/typography";
import { issueEmailChallenge } from "../../api/idpUserServiceApi";
import styles from "../forgotPassword/ForgotPassword.module.scss";
import {
  GTMAccessClickEventsPusher,
  GTMAccessImpressionEventsPusher,
  GTMCheckoutEventsPusher
} from "../../common/analytics";
import { Features, RESPONSE_OK } from "../../common/constants";
import { getQueryParams } from "../../common/utils";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import signInStyle from "../signIn/SignIn.module.scss";
import { useFeatureToggle } from "@gucci-private/feature-toggle-js";
import OTPVerification from "./OTPVerification/OTPVerification";

function EmailVerification({ isCheckout = false, email, onEditEmailClick, isSSALogged = false }) {
  const locale = useLocale();
  const { goToRoute } = useCustomRoute();
  const { path } = useRouteMatch();
  const [errorMessage, setErrorMessage] = useState("");
  const queryParams = getQueryParams(useLocation());
  const returnURI = queryParams.returnURI;
  const { getToggle } = useFeatureToggle();
  const isGuestCheckoutAllowed = !getToggle(Features.GUEST_CHECKOUT_DISABLED);
  const isGuestCheckoutAllowedForSaleAssistant = getToggle(Features.GUEST_CHECKOUT_ENABLED_FOR_SSA);
  const isSkipLoginAllowed = isGuestCheckoutAllowed  || ( isGuestCheckoutAllowedForSaleAssistant && isSSALogged);
  const {
    execute: executeEmailChallenge,
    error: emailChallengeError,
    value: emailChallengeResponse,
    requestStatus: emailChallengeRequestStatus
  } = useAsync(issueEmailChallenge, false);

  const returnToAccessPage = () => {
    onEditEmailClick();
    goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}`, {}, true);
  };

  const showErrorMessage = () => {
    setErrorMessage(locale.messages.GENERIC_ERROR);
  };

  useEffect(() => {
    if (email === "") {
      returnToAccessPage();
    }
    window.scrollTo(0, 0);
    GTMAccessImpressionEventsPusher({
      action: "Impression Send Email Challenge",
      label: returnURI
    });
  }, []);

  useEffect(() => {
    if (emailChallengeError) {
      showErrorMessage();
      GTMAccessImpressionEventsPusher({
        action: "Impression Send Email Challenge Error",
        label: "Email Challenge generic error"
      });
    }
  }, [emailChallengeError]);

  useEffect(() => {
    if (emailChallengeResponse) {
      if (emailChallengeResponse.status === RESPONSE_OK) {
        // This customer is assumed to be registered from WireEdit, hence channel is passed as wr as query parameter
        goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}/emailChallenge/verifyOTP`, {
          channel: "wr"
        }, true);
      }
    }
  }, [emailChallengeResponse]);

  const onSendOTP = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    executeEmailChallenge(email);
    GTMAccessClickEventsPusher({
      action: "Click Email Verification Page",
      label: "Request OTP"
    });
  };

  return (
    <Switch>
      <Route exact path={path}>
        <>
          <Title className={"is-title-xl"}>{locale.titles.ACCOUNT_EXISTS}</Title>
          <InformativeText
            className={"is-text-medium"}>{locale.messages.EMAIL_VERIFICATION_INFORMATION}</InformativeText>
          <form data-testid="email-otp-form"
            className={signInStyle.form}>
            <Email readOnly onEditEmailClick={returnToAccessPage} value={email}/>
            <span className={styles.formSubmitError} data-testid="error-message">
              {errorMessage}
            </span>
            <ConfirmButton
              disabled={emailChallengeRequestStatus === RequestStatus.TRIGGERED || (emailChallengeRequestStatus === RequestStatus.SUCCESS && errorMessage === "")}
              buttonText={locale.labels.REQUEST_OTP} onClick={onSendOTP}/>
            {
              isSkipLoginAllowed && isCheckout === true &&
              <div className={signInStyle.guestCheckoutLink}>
                <a href={`/${locale.country}/${locale.language}/login/checkout/access/guest?email=${encodeURIComponent(email)}`}
                  onClick={() => {
                    GTMCheckoutEventsPusher("Customer as Guest");
                    return true;
                  }}
                >{locale.labels.GUEST_USER_CHECKOUT}</a>
              </div>
            }
          </form>
        </>
      </Route>
      <Route path={`${path}/verifyOTP`}>
        <OTPVerification isCheckout={isCheckout} onEditEmailClick={onEditEmailClick} email={email}
          isSSALogged={isSSALogged}/>
      </Route>
    </Switch>
  );
}

EmailVerification.propTypes = {
  isCheckout: PropTypes.bool,
  email: PropTypes.string.isRequired,
  onEditEmailClick: PropTypes.func.isRequired,
  isSSALogged: PropTypes.bool
};

export default EmailVerification;