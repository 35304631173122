import * as PropTypes from "prop-types";
import React from "react";
import styles from "./SignInActions.module.scss";
import { useLocale } from "../../../common/global";
import { useLocation } from "react-router-dom";
import { getVPVSignInURL, GTMuaVPVPusher, GTMAccessClickEventsPusher } from "../../../common/analytics";
import { ACCESS_CHECKOUT_URL, ACCESS_PAGE_URL, SourceConfigPropType } from "../../../common/config";
import { useCustomRoute } from "../../../common/customHooks";

function SignInActions({ email, isCheckout = false, sourceConfig, isRememberMeChecked, setIsRememberMeChecked, isStandaloneLogin }) {
  const locale = useLocale();
  const location = useLocation();
  const { goToRoute } = useCustomRoute();
  const displayStaySignedIn = sourceConfig.rememberMeEnabled !== false;

  function onChange(event) {
    setIsRememberMeChecked(event.target.checked);
  }

  const craftForgotPasswordURL = () => {
    return `${isCheckout? ACCESS_CHECKOUT_URL: ACCESS_PAGE_URL}/signin/forgot${location.search}`;
  };

  function handleForgotPassword() {
    goToRoute(craftForgotPasswordURL());
    GTMuaVPVPusher(
      "vpv.signinStep",
      getVPVSignInURL(locale.country, locale.language, "forgot-password", isCheckout, false)
    );
    GTMAccessClickEventsPusher({
      action: "Click Email Login",
      label: "Forgot Password"
    });
  }

  return <div className={styles.signInActions}>
    {
      displayStaySignedIn && <div className={styles.checkboxWrapper}>
        <input
          type="checkbox"
          className={styles.checkbox}
          id="remember-me-checkbox"
          data-testid="remember-me-checkbox"
          checked={isRememberMeChecked}
          onChange={onChange}
        />
        <label htmlFor="remember-me-checkbox" className={styles.checkboxLabel}>
          {locale.labels.REMEMBER_ME}
        </label>
      </div>
    }

    <div>
      <button
        type="button"
        id="forgot-password-button"
        data-testid="forgot-password-button"
        className={styles.forgotPasswordLink}
        onClick={handleForgotPassword}
      >{locale.labels.FORGOT_PASSWORD}</button>
    </div>
  </div>;
}

SignInActions.propTypes = {
  email: PropTypes.string.isRequired,
  isCheckout: PropTypes.bool,
  isRememberMeChecked: PropTypes.bool.isRequired,
  setIsRememberMeChecked: PropTypes.func.isRequired,
  sourceConfig: SourceConfigPropType,
  isStandaloneLogin: PropTypes.bool
};

export default SignInActions;
