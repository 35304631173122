import React from "react";
import * as PropTypes from "prop-types";
import { i18n } from "../../common/i18n";
import styles from  "./ValuePropositions.module.scss";
import ValueProposition from "./ValueProposition";

function ValuePropositions({ country, language, isDosCustomer = false, isConcessions = false }) {
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country, language, baseUrl);

  let valueArr = [1,2,3];
  let titleLabel=1;

  if (isDosCustomer) {
    valueArr = [1,8,3];
    titleLabel = 3;
  }

  if (isConcessions) {
    valueArr = [4,5,3];
    titleLabel = 2;
  }

  const title = locale.i18n(`value_propositions.TITLE${titleLabel}`);

  return (
    <div className={styles["wrapper"]} id="value-propositions">
      <h2 className={`${styles["title"]} is-title-m`}>{title}</h2>
      <div className="container">
        <div className="grid">
          {valueArr.map((i) => {
            return (<ValueProposition
              country={country}
              language={language}
              titleKey={`value_propositions.VALUE${i}.TITLE`}
              contentKey={`value_propositions.VALUE${i}.CONTENT`}
            />);
          })}
        </div>
      </div>
    </div>
  );
}

ValuePropositions.propTypes = {
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  isDosCustomer: PropTypes.bool,
  isConcessions: PropTypes.bool
};
export default ValuePropositions;
