import React from "react";
import styles from "./StandaloneHeader.module.scss";

import { InformativeText, SubTitle, Title } from "../../ui/typography";
import * as PropTypes from "prop-types";
import { i18n } from "../../../common/i18n";
import { useStandalone } from "../StandaloneContext";
import Social from "../../social/Social";
import { useSocialProviders } from "../../../common/customHooks";
import { useFeatureToggle } from "@gucci-private/feature-toggle-js";
import { Features } from "../../../common/constants";
import { getQueryParams } from "../../../common/utils";
import { useLocation } from "react-router-dom";

function StandaloneHeader({ country, language, socialAccountExists = false }) {
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const { getToggle } = useFeatureToggle();
  const { client } = useStandalone();
  const locale = i18n(country, language, baseUrl, client?.source);
  const socialProvidersStatus = useSocialProviders();
  const pongosocial22 = "romeomontecchi" === getQueryParams(useLocation()).pongosocial22;
  const shouldShowSocialButtons = pongosocial22 || getToggle(Features.STANDALONE_SOCIAL_SIGNUP);

  return (
    <>
      <div className={styles.heroContent}>
        {!socialAccountExists && <>
          <Title className={"is-title-xl"}>
            {locale.titles.ACCESS_GUCCI}
          </Title>
          {shouldShowSocialButtons && <>
            {Object.values(socialProvidersStatus).includes(true) && <>
              <Social isCheckout={false} isStandaloneLogin={true} socialProvidersStatus={socialProvidersStatus}
                labels={locale.labels} />
              <InformativeText className="is-text-light">{locale.messages.OR}</InformativeText>
            </>}
            <SubTitle>{locale.titles.CONTINUE_WITH_EMAIL}</SubTitle>
          </>}
          <InformativeText>
            {locale.messages.REGISTRATION_INFORMATION}
          </InformativeText>
        </>
        }
      </div>
    </>
  );
}

StandaloneHeader.propTypes = {
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  socialAccountExists: PropTypes.bool
};

StandaloneHeader.defaultProps = {
  country: window.location.pathname.split("/")[1],
  language: window.location.pathname.split("/")[2]
};

export default StandaloneHeader;
