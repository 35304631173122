import React from "react";
import styles from "./index.module.scss";
import * as PropTypes from "prop-types";

export function Title(props) {
  return (
    <h1 className={`${styles.title} ${props.className ? props.className : ""}`} data-testid="title">
      {props.children}
    </h1>
  );
}

export function SubTitle(props) {
  return (
    <h2 className={`${styles.subtitle} ${props.className ? props.className : ""}`} data-testid="subtitle">
      {props.children}
    </h2>
  );
}

export function InformativeText(props) {
  if (props.innerHTML) {
    return (
      <div className={`${styles.message} ${props.className ? props.className : ""}`} dangerouslySetInnerHTML={{
        __html: props.innerHTML
      }} data-testid="informative-text" />
    );
  }
  return (
    <div className={`${styles.message} ${props.className ? props.className : ""}`} data-testid="informative-text">
      {props.children}
    </div>
  );
}

export function Icon(props) {
  return (
    <svg className={styles.icon} data-testid="icon">
      <use xlinkHref={props.href}></use>
    </svg>
  );
}

Title.propTypes = {
  children: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]
  ).isRequired,
  className: PropTypes.string
};

SubTitle.propTypes = {
  children: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]
  ).isRequired,
  className: PropTypes.string
};

InformativeText.propTypes = {
  children: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]
  ),
  className: PropTypes.string,
  innerHTML: PropTypes.string
};

Icon.propTypes = {
  href: PropTypes.string.isRequired
};
