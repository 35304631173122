import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import styles from "./SetPasswordForm.module.scss";
import { ACCESS_PAGE_URL } from "../../../common/config";
import Password from "../../ui/password/Password";
import {
  OperationalMode,
  RESPONSE_CONFLICT,
  RESPONSE_OK,
  SAME_PASSWORD_ERROR
} from "../../../common/constants";
import ConfirmButton from "../../ui/confirmButton/ConfirmButton";
import { getCartAvailability, getQueryParams, encodeState, decode } from "../../../common/utils";
import { RequestStatus, useAsync, useCustomRoute } from "../../../common/customHooks";
import { useLocale } from "../../../common/global";
import { useLocation } from "react-router-dom";
import { createCustomer } from "../../../api/idpUserServiceApi";
import {
  GTMCheckoutEventsPusher,
  GTMGATechEventsPusher,
  GTMMyAccountCreateAccountEventsPusher,
  GTMMyAccountSignInErrorsEventsPusher,
  GTMMyAccountSignInEventsPusher,
  GTMuaVPVPusher,
  getVPVSignInURL
} from "../../../common/analytics";
import { signInUser } from "../../../api/oktaAuth";
import Loader from "../../ui/loader/Loader";
import { useStandalone } from "../../standalone/StandaloneContext";

function SetPasswordForm({ isCheckout = false, mode, transaction }) {
  const locale = useLocale();
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValidationFailed, setFormValidationFailed] = useState(false);
  const [showSamePasswordErrorMessage, setShowSamePasswordErrorMessage] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const { execute: createCustomerExecute, value: createCustomerValue, error: createCustomerError, requestStatus: createCustomerRequestStatus } = useAsync(createCustomer, false);
  const { execute: resetPasswordExecute, value: resetPasswordValue, error: resetPasswordError, requestStatus: resetPasswordRequestStatus } = useAsync(transaction.resetPassword, false);
  const { execute: signInUserExecute, error: signInUserError } = useAsync(signInUser, false);
  const { isStandaloneLogin } = useStandalone();

  const location = useLocation();
  const { goToRoute } = useCustomRoute();
  const { profileToken, recoveryToken, returnURI, stateToken, nonce, pkceConfig } = getQueryParams(location);
  const successCallback = () => {
    if (isCheckout) {
      GTMCheckoutEventsPusher("Customer Login");
    } else {
      GTMMyAccountSignInEventsPusher({
        action: "sign in",
        label: createCustomerValue !== null ? "email" : "reset password"
      });
    }
  };

  useEffect(() => {
    if (resetPasswordValue) {
      if (resetPasswordValue.status === "SUCCESS") {
        if (createCustomerValue !== null) {
          GTMuaVPVPusher("vpv.signinStep", getVPVSignInURL(locale.country, locale.language, "typ-validated", isCheckout, true));
          GTMMyAccountCreateAccountEventsPusher({
            label: "email-validated"
          });
          const isPersistedCartAvailable = localStorage.getItem("emailVerification.cart");
          const isCurrentCartAvailable = getCartAvailability().toString();
          if (isPersistedCartAvailable === "true") {
            GTMGATechEventsPusher({
              action: "emailVerification.cart",
              label: (isPersistedCartAvailable === isCurrentCartAvailable) ? "KEPT" : "LOST"
            });
          } else if (isPersistedCartAvailable == null) {
            GTMGATechEventsPusher({
              action: "emailVerification.cart",
              label: "CHANGE BROWSER"
            });
          }
          localStorage.removeItem("emailVerification.cart");
        } else {
          GTMuaVPVPusher("vpv.signinStep", getVPVSignInURL(locale.country, locale.language, "typ-reset-password", isCheckout, false));
          const isResetPasswordFlowOnTheSameBrowser = localStorage.getItem("forgotPasswordFlowBegun") === "true";
          GTMGATechEventsPusher({
            action: "resetPasswordFlowOnTheSameBrowser",
            label: `${isCheckout ? "checkout" : "signin"}${isResetPasswordFlowOnTheSameBrowser ? "-same" : "-different"}`
          });
          localStorage.removeItem("forgotPasswordFlowBegun");
        }

        const state = encodeState({
          checkout: false,
          rememberMe: true,
          returnURI: decodeURIComponent(returnURI),
          stateToken: stateToken,
          registration: false,
          locale: locale.country + "/" + locale.language,
          ...(isStandaloneLogin && {
            isSocial: false
          })
        });
        setPasswordSuccess(true);
        const signInTimeout = window.setTimeout(() => {
          signInUserExecute({
            email: transaction.user.profile.login,
            password: newPassword,
            state,
            nonce,
            successCallback,
            pkceConfig: decode(pkceConfig)
          });
        }, 1000);
        return () => {
          window.clearTimeout(signInTimeout);
        };

      } else {
        setErrorMessage(locale.messages.GENERIC_ERROR);
      }
    }
  }, [resetPasswordValue]);

  useEffect(() => {
    if (resetPasswordError) {
      if (resetPasswordError.errorCode === SAME_PASSWORD_ERROR) {
        setShowSamePasswordErrorMessage(true);
        setFormValidationFailed(true);
        setIsPasswordValid(false);
      } else {
        setErrorMessage(locale.messages.GENERIC_ERROR);
      }
    }
  }, [resetPasswordError]);

  useEffect(() => {
    if (signInUserError) {
      GTMMyAccountSignInErrorsEventsPusher({
        action: "signin errors",
        label: "Sorry, something went wrong. Please try again."
      });
      goToRoute(`${ACCESS_PAGE_URL}/signin`, {
        error: true,
        email: transaction.user.profile.login,
        returnURI,
        stateToken,
        nonce,
        pkceConfig: pkceConfig
      }, false, true);
    }
  }, [signInUserError]);

  useEffect(() => {
    if (createCustomerValue) {
      if (createCustomerValue.status === RESPONSE_OK || createCustomerValue.status === RESPONSE_CONFLICT) {
        resetPasswordExecute({
          newPassword
        });
      } else {
        setErrorMessage(locale.messages.GENERIC_ERROR);
      }
    }
  }, [createCustomerValue]);

  useEffect(() => {
    if (createCustomerError) {
      setErrorMessage(locale.messages.GENERIC_ERROR);
    }
  }, [createCustomerError]);

  function handleSubmit(e) {
    e.preventDefault();
    if (isPasswordValid) {
      setErrorMessage("");
      setFormValidationFailed(false);
      setShowSamePasswordErrorMessage(false);

      if (mode === OperationalMode.ACTIVATE_AND_SET_PASSWORD) {
        createCustomerExecute(transaction.user.id, transaction.user.profile.login, profileToken, recoveryToken);
      } else {
        resetPasswordExecute({
          newPassword
        });
      }
    } else {
      setFormValidationFailed(true);
    }
  }

  const formContentHTML = (mode === OperationalMode.RESET_PASSWORD) ? (locale.i18n("titles.CHANGING_PASSWORD_FOR", {
    email: `<strong data-testid={'email'}>${transaction.user.profile.login}</strong>`
  })) : (locale.i18n("titles.SETTING_PASSWORD_FOR", {
    email: `<strong data-testid={'email'}>${transaction.user.profile.login}</strong>`
  }));

  function showContent() {
    return (
      <p className={styles.message} dangerouslySetInnerHTML={{
        __html: formContentHTML
      }}>
      </p>
    );
  }

  return (
    <>
      {
        passwordSuccess &&
        <>
          <h1 className={styles.title} data-testid="password-success-message">{locale.titles.PASSWORD_UPDATED}</h1>
          <div>
            <p className={styles.message}
              dangerouslySetInnerHTML={{
                __html: locale.messages.PASSWORD_UPDATED_HTML
              }}>
            </p>
            <div className={styles.loader}>
              <Loader />
            </div>
          </div>
        </>
      }
      {
        !passwordSuccess &&
        <form
          className={styles.form}
          noValidate="novalidate"
          onSubmit={handleSubmit}
          data-testid={mode === OperationalMode.RESET_PASSWORD ? "reset-password-form" : "set-password-form"}
        >
          <h1 className={styles.title} data-testid={"valid-token-message"}>{mode === OperationalMode.RESET_PASSWORD ? locale.labels.RESET_PASSWORD : locale.labels.CHOOSE_PASSWORD}</h1>
          <div>{showContent()}</div>

          {errorMessage &&
            <span className={styles.errorMessage} data-testid="error-global-message">{errorMessage}</span>}
          {showSamePasswordErrorMessage &&
            <span className={styles.errorMessage} data-testid="same-password-error-message">{locale.messages.SAME_PASSWORD_ERROR}</span>}

          <Password
            label={(mode === OperationalMode.RESET_PASSWORD) ? locale.labels.NEW_PASSWORD : locale.labels.CREATE_PASSWORD}
            value={newPassword}
            onChange={value => setNewPassword(value)}
            isPasswordValid={isPasswordValid}
            setIsPasswordValid={setIsPasswordValid}
            showValidationMessages={true}
            formValidationFailed={formValidationFailed}
          />

          <ConfirmButton buttonText={locale.labels.CONFIRM} disabled={
            createCustomerRequestStatus === RequestStatus.TRIGGERED || (createCustomerRequestStatus === RequestStatus.SUCCESS && errorMessage === "") ||
            resetPasswordRequestStatus === RequestStatus.TRIGGERED || (resetPasswordRequestStatus === RequestStatus.SUCCESS && errorMessage === "")} />

        </form>
      }
    </>
  );
}

SetPasswordForm.propTypes = {
  isCheckout: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(OperationalMode)),
  transaction: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
      profile: PropTypes.shape({
        login: PropTypes.string
      })
    }),
    resetPassword: PropTypes.func
  }).isRequired
};

export default SetPasswordForm;