import React, { useEffect } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { init } from "@gucci-private/gucci-auth";
import { getAuthConfig, DATADOG_CLIENT_ID, SIGNUP_EXISTING_CUSTOMER } from "./common/config";
import styles from "./App.module.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SetPassword from "./components/setPassword/SetPassword";
import SignupExistingCustomer from "./components/signupExistingCustomer/SignupExistingCustomer";
import Access from "./components/access/Access";
import Checkout from "./components/checkout/Checkout";
import StandaloneContext from "./components/standalone/StandaloneContext";
import ReviewProfile from "./components/reviewProfile/ReviewProfile";
import "@gucci-private/gucci-react-components/dist/index.css";
import "@gucci-private/gucci-design-system/dist/index.css";
import smoothscroll from "smoothscroll-polyfill";
import Loader from "./components/ui/loader/Loader";
import { useFeatureToggle } from "@gucci-private/feature-toggle-js";
import { OperationalMode } from "./common/constants";
import { getQueryParams, isEmpty, isNotEmpty } from "./common/utils";
import GoogleTagManagerLoader from "./components/analytics/GoogleTagManagerLoader";
import GucciAppLogout from "./components/gucciapp/logout/GucciAppLogout";
import SocialAuthorizationCallback from "./components/callback/SocialAuthorizationCallback";
import ExternalAccess from "./components/externalAccess/ExternalAccess";
import { useLocale } from "./common/global";
import GucciHeader from "./components/gucciHeaderAndFooter/GucciHeader";
import GucciFooter from "./components/gucciHeaderAndFooter/GucciFooter";
import ValuePropositions from "./components/valuePropositions/ValuePropositions";
import LoaderWithTexts from "./components/ui/loader/LoaderWithTexts";
import GA4Loader from "./components/analytics/GA4Loader";
import YugaHeader from "./components/gucciHeaderAndFooter/YugaHeader";
import YugaFooter from "./components/gucciHeaderAndFooter/YugaFooter";

function App() {
  const isDosCustomer = window.location.pathname.includes(SIGNUP_EXISTING_CUSTOMER);

  const featureToggle = useFeatureToggle();
  const locale = useLocale();
  const isRTL = locale.language === "ar" || locale.language === "he"; // he is for hebrew
  document.body.dir = isRTL ? "rtl" : "ltr";

  function forcePageReloadWhenCached(event) {
    if (event.persisted) {
      window.location.reload();
    }
  }

  const initDatadog = () => {
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_ID,
      site: "datadoghq.com",
      forwardErrorsToLogs: false,
      sampleRate: 100,
      version: "0.1.0",
      service: "sign-in-spa",
      env: `${process.env.REACT_APP_ENVIRONMENT === "PROD" ? "prd" : "dev"}`
    });
  };

  useEffect(() => {
    async function initData() {
      smoothscroll.polyfill();
      window.addEventListener("pageshow", forcePageReloadWhenCached);
      initDatadog();
      await init(getAuthConfig());
    }

    initData();
    return () => window.removeEventListener("pageshow", forcePageReloadWhenCached);

  },[]);

  if (!featureToggle) {
    return (
      <>
        <div className={`${styles.mainContainer} main-container`}>
          <section>
            <Loader/>
          </section>
        </div>

      </>
    );
  }

  return (
    <>
      <svg id="svg-icons" style={{
        position: "absolute",
        left: "-9999px",
        width: 0,
        height: 0,
        visibility: "hidden"
      }}>
        <symbol viewBox="0 0 200 136" id="svg-icon-eye">
          <title>eye</title>
          <g>
            <path d="M100,160 C60,131.72526 40,98.3919271 40,60 C40,21.6080729 60,-11.7252604 100,-40 C140,-11.7252604 160,21.6080729 160,60 C160,98.3919271 140,131.72526 100,160 Z M100,104 C124.300529,104 144,84.300529 144,60 C144,35.699471 124.300529,16 100,16 C75.699471,16 56,35.699471 56,60 C56,84.300529 75.699471,104 100,104 Z M100,82 C87.8497355,82 78,72.1502645 78,60 C78,47.8497355 87.8497355,38 100,38 C112.150264,38 122,47.8497355 122,60 C122,72.1502645 112.150264,82 100,82 Z" transform="translate(100.000000, 67.500000) rotate(-90.000000) translate(-100.000000, -60.000000)" />
          </g>
        </symbol>
        <symbol viewBox="0 0 50 50" id="svg-icon-inbox">
          <title>inbox</title>
          <path d="M37.3,6.2h-1.8V5h4.2V0.3h-5.6v5.9h-17c-7.6,0-11.6,6.4-11.6,9.1v16.8L1,39.5c-1.2,1.7-1.3,3.7-0.4,5.4	c1.1,1.9,3.3,3.2,5.6,3.2c0.3,0,0.5,0,0.8,0c8.2,0,14.4-5.1,16.6-7.9l2.1-3.1v12.8h1.4V35l1.4-2.1h2.3v17h1.4V32.8h16.7V15.3	C48.9,12.9,46.2,6.2,37.3,6.2z M35.5,1.7h2.9v2h-2.9V1.7z M6.9,15.3c0-2.2,3.6-7.7,10.3-7.7s10.3,5.5,10.3,7.7v1.2H9v15H6.9V15.3z	 M27.5,20.3L21,25.2l-9.8-7.3h16.3V20.3z M10.4,19L21,26.8l6.5-4.8v9.5H10.4V19z M25.7,34.7L25.7,34.7L25.7,34.7l-3.1,4.7 C20.5,42,14.4,47,6.3,46.7c-1.8,0-3.6-1-4.4-2.5c-0.7-1.2-0.6-2.6,0.3-4l4.4-7.3h20.3L25.7,34.7z M47.7,31.5H28.8v-15v-1.2	c0-1.9-2-5.7-5.9-7.7h11.2v6.3c-1.4,0.3-2.5,1.6-2.5,3.1c0,1.8,1.4,3.2,3.2,3.2s3.3-1.4,3.2-3.3c0-1.5-1.1-2.8-2.5-3.1V7.6h1.9 c8.5,0,10.3,6.4,10.3,7.7V31.5z M34.8,15.2c1.1,0,1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8S33,18,33,17S33.8,15.2,34.8,15.2z"/>
        </symbol>
      </svg>

      <main className={`${styles.mainContainer} main-container ${isDosCustomer ? "dos-customer" : ""}`} data-testid={"mainContainer"}>
        <StandaloneContext>
          <LoaderWithTexts />
          <GucciHeader />
          <YugaHeader />
          <div className="background-wrapper container">
            <div className="grid">
              <section className="col-12 start-0 col-md-10 start-md-2 col-xl-8 start-xl-3">
                <Router>
                  <GoogleTagManagerLoader />
                  <GA4Loader />
                  <Switch>
                    <Route path="/*/access/setPassword" render={ routeProps => {
                      const queryParams = getQueryParams(routeProps.location);
                      const profileToken = queryParams.profileToken;
                      const profileObject = isNotEmpty(profileToken) ? JSON.parse(atob(profileToken)) : {};

                      const isEmailVerificationAfterCheckout = isNotEmpty(profileObject) && isNotEmpty(profileObject.profile.orderGUID);
                      const mode = isEmpty(profileToken) ? OperationalMode.SET_PASSWORD : OperationalMode.ACTIVATE_AND_SET_PASSWORD;

                      return <div data-testid="set-password">
                        <SetPassword isCheckout={isEmailVerificationAfterCheckout} mode={mode} />
                      </div>;
                    }}/>
                    <Route path="/*/access/checkout" >
                      <Checkout />
                    </Route>
                    {/* todo - have urls hyphenated rather than camel case */}
                    <Route path="/*/access/socialReviewProfile" >
                      <ReviewProfile isSocial={true}/>
                    </Route>
                    <Route path="/*/access/reviewProfile" >
                      <ReviewProfile isSocial={false}/>
                    </Route>
                    <Route path="/*/access/view/signupExistingCustomer" >
                      <SignupExistingCustomer />
                    </Route>
                    <Route path="/*/access/view" >
                      <Access />
                    </Route>
                    <Route path="/*/gucciapp/logout" >
                      <GucciAppLogout />
                    </Route>
                    <Route path={["/access/authorization/callback", "/*/access/authorization/callback"]}>
                      <SocialAuthorizationCallback />
                    </Route>
                    <Route path="/*/access/external" >
                      <ExternalAccess/>
                    </Route>
                  </Switch>
                </Router>
              </section>
            </div>
          </div>

          <Router>
            <Route path={[ "/*/access/socialReviewProfile" , "/*/access/view", "/*/access/external" ]}
              render={routeProps => {
                const { location: { pathname } } = routeProps;
                const queryParams = getQueryParams(routeProps.location);
                const isConcessions = !!queryParams.channel;
                const isDosCustomer = pathname.indexOf("/signupExistingCustomer") > -1;
                return (
                  <ValuePropositions
                    country={locale.country}
                    language={locale.language}
                    isConcessions={isConcessions}
                    isDosCustomer={isDosCustomer} />
                );
              }}
            />
          </Router>

          <GucciFooter />
          <YugaFooter />
        </StandaloneContext>
      </main>
    </>
  );
}

export default App;
