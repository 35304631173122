import React from "react";
import styles from "./GucciHeaderAndFooter.module.scss";

function GucciHeader() {
  return (
    <div className={`${styles.gucciHeader} gucci-header`}>
      <span className={styles.logo} />
    </div>
  );
}

export default GucciHeader;
