import { customerReferenceBaseUrl } from "../common/config";
import { ClientType } from "../common/constants";

function requestHeaders(accessToken) {
  return {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`
  };
}

function registrationRequestHeaders(accessToken, source, country, language) {
  const headers = {
    ...requestHeaders(accessToken),
    "x-language": language,
    "x-redirect": `${window.location.origin}/${country}/${language}/my-account`
  };

  if (source !== ClientType.THE_VAULT) {
    return {
      ...headers,
      "x-event-name": "WELCOME"
    };
  } else {
    return headers;
  }
}

export function getUser(accessToken) {
  const path = "users/me";
  const requestOptions = {
    method: "GET",
    headers: requestHeaders(accessToken)
  };
  const url = `${customerReferenceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function registerCustomerFromUser(accessToken, data, language) {
  const path = "users/me";
  const requestBody = JSON.stringify({
    ...data
  });
  const requestOptions = {
    method: "POST",
    headers: registrationRequestHeaders(accessToken, data.source, data.country.toLowerCase(), language),
    body: requestBody
  };
  const url = `${customerReferenceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function updateUserProfile(user, accessToken) {
  const path = "users/me/profile";
  const requestBody = JSON.stringify({
    ...user
  });
  const requestOptions = {
    method: "PUT",
    headers: registrationRequestHeaders(accessToken),
    body: requestBody
  };
  const url = `${customerReferenceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}