import { getOktaAuth, isSessionExists, closeSession, getEndpoint, verifyRecoveryToken } from "@gucci-private/gucci-auth";
import {
  getAuthConfig, getFirstLevelDomain,
  idpServiceBaseUrl,
  knownSocialProviderConfiguration,
  SIGN_IN_CLIENT_ID
} from "../common/config";
import { extractQueryParamValueFromUrl } from "../common/utils";
import TokenManager from "./tokenManager";

async function authenticate(username, password) {
  const oktaAuth = await getOktaAuth();
  return oktaAuth.signInWithCredentials({
    username,
    password,
    sendFingerprint: true
  });
}

async function authorizeAndRedirect(state, nonce, additionalAuthConfig, pkceConfig) {
  const oauthOptions = {
    ...getAuthConfig(),
    ...additionalAuthConfig,
    nonce,
    state
  };

  if (oauthOptions.pkce === true && pkceConfig && pkceConfig.codeChallenge) {
    const tokenParams = {
      ...oauthOptions,
      clientId: pkceConfig.clientId,
      redirectUri: pkceConfig.redirectUri,
      codeChallenge: pkceConfig.codeChallenge,
      responseType: "code",
      codeChallengeMethod: "S256"
    };
    const oktaEndpoint = await getEndpoint(tokenParams, oauthOptions);

    const channel = extractQueryParamValueFromUrl("channel");

    if (channel) {
      let date = new Date();
      date.setTime(date.getTime() + (60 * 1000));
      document.cookie = `channel=${channel}; expires=${date}; domain=${getFirstLevelDomain()}; path=/`;
    }

    window.location.href = oktaEndpoint;
    return;
  }
  const oktaAuth = await getOktaAuth();
  return oktaAuth.token.getWithRedirect(oauthOptions);
}

export function verifyIdpRecoveryToken() {
  const recoveryToken = extractQueryParamValueFromUrl("recoveryToken");
  const issuer = idpServiceBaseUrl;
  return verifyRecoveryToken(issuer, recoveryToken);
}

export async function signInUser({ email, password, state, nonce, successCallback, pkceConfig }) {
  await closeSession();
  const res = await authenticate(email, password);
  successCallback();
  await authorizeAndRedirect(state, nonce, {
    sessionToken: res.sessionToken
  },
  pkceConfig);
}

export async function signInSocialUser({ state, nonce, shouldCloseSession = true, pkceConfig, socialProviderName }) {
  if (shouldCloseSession) {
    await closeSession();
  }
  await authorizeAndRedirect(state, nonce,{
    idp: knownSocialProviderConfiguration[socialProviderName]
  }, pkceConfig);
}

export async function signInWithoutPrompt({ email, password, clientId }) {
  const oktaAuth = await getOktaAuth();
  await closeSession();
  const res = await authenticate(email, password);
  return oktaAuth.token.getWithoutPrompt({
    clientId: SIGN_IN_CLIENT_ID,
    redirectUri: window.location.origin + "/access/authorization/callback",
    sessionToken: res.sessionToken,
    scopes: ["openid", "profile"]
  });
}

export async function attemptAutoSignIn({ state, nonce, pkceConfig }) {
  if (!await isSessionExists()) {
    throw new Error("Failed to get the current session");
  }

  return await authorizeAndRedirect(
    state,
    nonce,
    {},
    pkceConfig
  );
}

export async function resetChallengeTokens() {
  const oktaAuth = await getOktaAuth();
  const challengeTokens = await oktaAuth.token.prepareTokenParams(getAuthConfig());
  TokenManager.setChallengeTokens(challengeTokens);
}

async function exchangeOktaUserTokens(challengeTokens, code) {
  if (!challengeTokens) {
    throw new Error("Challenge tokens missing");
  }
  const oktaAuth = await getOktaAuth();
  return oktaAuth.token
    .exchangeCodeForTokens({
      ...challengeTokens,
      clientId: SIGN_IN_CLIENT_ID,
      redirectUri: window.location.origin + "/access/authorization/callback",
      authorizationCode: code
    });
}

export async function initOktaTokens() {
  const code = extractQueryParamValueFromUrl("code");
  const challengeTokens = TokenManager.popChallengeTokens();
  const oktaUserTokens = await exchangeOktaUserTokens(challengeTokens, code);
  const userTokens = await oktaUserTokens.tokens;
  TokenManager.setUserTokens(userTokens);
}

