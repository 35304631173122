import React from "react";
import { SocialProvider } from "./constants";
import * as PropTypes from "prop-types";
import {
  AppleSignInButton,
  GoogleSignInButton,
  KakaoSignInButton
} from "@gucci-private/gucci-react-components";

const buttonPropTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export function appleButton({ disabled, id, key, label, onClick }) {
  return (
    <AppleSignInButton
      disabled={disabled}
      id={id}
      key={key}
      label={label}
      onClick={onClick}
    />
  );
}

appleButton.propTypes = buttonPropTypes;

export function googleButton({ disabled, id, key, label, onClick }) {
  return (
    <GoogleSignInButton
      disabled={disabled}
      id={id}
      key={key}
      label={label}
      onClick={onClick}
    />
  );
}

googleButton.propTypes = buttonPropTypes;

export function kakaoButton({ disabled, id, key, label, onClick }) {
  return (
    <KakaoSignInButton
      disabled={disabled}
      id={id}
      key={key}
      label={label}
      onClick={onClick}
    />
  );
}

kakaoButton.propTypes = buttonPropTypes;

export const socialProvidersConfigs = [
  {
    name: SocialProvider.KAKAO,
    testId: "kakao-button",
    labelKey: "CONTINUE_WITH_KAKAO",
    button: kakaoButton
  },
  {
    name: SocialProvider.GOOGLE,
    testId: "google-button",
    labelKey: "CONTINUE_WITH_GOOGLE",
    button: googleButton
  },
  {
    name: SocialProvider.APPLE,
    testId: "apple-button",
    labelKey: "CONTINUE_WITH_APPLE",
    button: appleButton
  }
];

