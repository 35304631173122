import React, { useEffect, useState } from "react";
import { Select } from "@gucci-private/gucci-react-components";
import * as PropTypes from "prop-types";
import { getCountryList, i18n } from "../../../common/i18n";
import styles from "./CountrySelection.module.scss";

function CountrySelection({ changeCountry, currentCountry, language, providedCountryList }) {
  const [country, setCountry] = useState(currentCountry);
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = {
    ...i18n(country,language,baseUrl),
    country,
    language
  };
  const countryList = providedCountryList || getCountryList(locale.language);

  useEffect(() => {
    changeCountry(country);
  }, [country]);

  const handleCountryChange = value => setCountry(value);

  return (
    <div className={styles.countrySelection}>
      <Select
        value={country}
        onChange={handleCountryChange}
        id={"country-select"}
        label={locale.i18n("labels.COUNTRY")}
        errorMessage={locale.messages.GENERIC_ERROR}
        options={countryList}
        formValidationFailed={false}
        withBorder={true}
      />
    </div>
  );
}

CountrySelection.propTypes = {
  changeCountry: PropTypes.func,
  currentCountry: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  providedCountryList: PropTypes.array
};

export default CountrySelection;
