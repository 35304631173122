import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import styles from "./ForgotPassword.module.scss";
import { useLocale } from "../../common/global";
import { RESPONSE_OK } from "../../common/constants";
import {
  GTMAccessClickEventsPusher,
  GTMAccessImpressionEventsPusher,
  GTMuaVPVPusher,
  getVPVSignInURL
} from "../../common/analytics";
import { RequestStatus, useAsync, useCustomRoute } from "../../common/customHooks";
import { recoverPassword } from "../../api/idpUserServiceApi";
import { InformativeText, Title } from "../ui/typography";
import Email from "../ui/email/Email";
import ConfirmButton from "../ui/confirmButton/ConfirmButton";
import { extractQueryParamValueFromUrl, getQueryParams } from "../../common/utils";
import { ACCESS_CHECKOUT_URL, ACCESS_PAGE_URL } from "../../common/config";
import StandaloneWrapper from "../standalone/StandaloneWrapper";
import StandaloneLogo from "../standalone/standaloneHeader/StandaloneLogo";
import CancelButton from "../standalone/CancelButton";
import { useStandalone } from "../standalone/StandaloneContext";
import { useLocation } from "react-router-dom";

function ForgotPassword({ isCheckout = false, email, onEditEmailClick }) {
  const locale = useLocale();
  const [isSuccess, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { execute: forgotPasswordExecute, value: forgotPasswordValue, error: forgotPasswordError, requestStatus: forgotPasswordStatus } = useAsync(recoverPassword, false);
  const { goToRoute } = useCustomRoute();
  const { isStandaloneLogin } = useStandalone();
  const queryParams = getQueryParams(useLocation());
  const returnURI = queryParams.returnURI;

  const returnToAccessPage = () => {
    onEditEmailClick();
    goToRoute(`${isCheckout? ACCESS_CHECKOUT_URL: ACCESS_PAGE_URL}`, {}, true);
  };

  useEffect(() => {
    if (forgotPasswordError) {
      showErrorMessage();
      GTMAccessImpressionEventsPusher({
        action: "Impression Forgot Password Error",
        label: "Forgot password generic error"
      });
    }
  }, [forgotPasswordError]);

  useEffect(() => {
    if (forgotPasswordValue) {
      handleForgotPasswordResponse(forgotPasswordValue);
    }
  }, [forgotPasswordValue]);

  function handleSubmit(event) {
    event.preventDefault();
    setErrorMessage("");

    forgotPasswordExecute(
      email,
      locale.country,
      locale.language,
      isCheckout,
      extractQueryParamValueFromUrl("returnURI"),
      extractQueryParamValueFromUrl("cart")
    );
  }

  function handleForgotPasswordResponse(response) {
    if (response.status === RESPONSE_OK) {
      setSuccess(true);
      GTMuaVPVPusher("vpv.signinStep", getVPVSignInURL(locale.country, locale.language, "typ-forgot", isCheckout, false));
      GTMAccessImpressionEventsPusher({
        action: "Impression Forgot Password Link Sent",
        label: returnURI
      });
      localStorage.setItem("forgotPasswordFlowBegun", "true");
    } else {
      showErrorMessage();
      GTMAccessImpressionEventsPusher({
        action: "Impression Forgot Password Error",
        label: "error:" + response.status
      });
    }
  }

  function showErrorMessage() {
    setErrorMessage(locale.messages.GENERIC_ERROR);
  }

  useEffect(() => {
    if (email === "") {
      returnToAccessPage();
    }
    window.scrollTo(0, 0);
    GTMAccessImpressionEventsPusher({
      action: "Impression Forgot Password",
      label: returnURI
    });
  }, []);

  const content = <>
    {
      !isSuccess &&
      <div>
        <Title className={"is-title-l"}>
          {locale.labels.FORGOT_PASSWORD}
        </Title>
        <InformativeText className={"is-text-medium"}>
          {locale.messages.FORGOT_PASSWORD_INFORMATION}
        </InformativeText>
        <form
          id="form"
          noValidate="novalidate"
          onSubmit={handleSubmit}
          data-testid="forgot-password-form"
        >
          <Email readOnly onEditEmailClick={returnToAccessPage} value={email} />
          <span className={styles.formSubmitError} data-testid="error-message">
            {errorMessage}
          </span>
          <ConfirmButton buttonText={locale.labels.CONFIRM}
            disabled={forgotPasswordStatus === RequestStatus.TRIGGERED || (forgotPasswordStatus === RequestStatus.SUCCESS && errorMessage === "")}
            onClick={() => GTMAccessClickEventsPusher({
              action: "Click Forgot Password",
              label: "Continue"
            })} />
        </form>
        {isStandaloneLogin && <CancelButton label="Cancel" onClick={() => GTMAccessClickEventsPusher({
          action: "Click Forgot Password",
          label: "Cancel"
        })} />}
      </div>
    }
    {
      isSuccess &&
      <div>
        <Title className={"is-title-xl"}>
          {locale.titles.FORGOT_PASSWORD_EMAIL_SENT}
        </Title>
        <InformativeText className={styles.informativeText}>
          {locale.messages.FORGOT_PASSWORD_SUCCESS_INFORMATION}
          <strong className={styles.thankYouEmail}>{email}</strong>
          {locale.messages.FORGOT_PASSWORD_INSTRUCTION}
        </InformativeText>
        {isStandaloneLogin && <CancelButton onClick={() => GTMAccessClickEventsPusher({
          action: "Click Forgot Password Link Sent",
          label: "Continue"
        })} label="CONTINUE" />}
      </div>
    }
  </>;

  return (
    <>
      {
        isStandaloneLogin ?
          <>
            <StandaloneLogo />
            <StandaloneWrapper>
              {content}
            </StandaloneWrapper>
          </>
          :
          <>{content}</>
      }
    </>);
}

ForgotPassword.propTypes = {
  isCheckout: PropTypes.bool,
  email: PropTypes.string.isRequired,
  onEditEmailClick: PropTypes.func.isRequired
};
export default ForgotPassword;
