/* eslint-disable */
function getTranslations(language, baseUrl) {
  switch (language) {
  case "ar":
    return arabic(baseUrl);
  case "de":
    return german(baseUrl);
  case "en_gb":
    return englishGreatBritain(baseUrl);
  case "es":
    return spanish(baseUrl);
  case "fr":
    return french(baseUrl);
  case "it":
    return italian(baseUrl);
  case "ja":
    return japanese(baseUrl);
  case "ko":
    return korean(baseUrl);
  case "zh":
    return simplifiedChinese(baseUrl);
  case "zh_hk":
    return traditionalChinese(baseUrl);
  default:
    return englishGreatBritain(baseUrl);
  }
}

function arabic(baseUrl) {
  const LINE_BREAK = "<br />";
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/en-US/st/privacy";
  const PRIVACY_POLICY_VAULT_LINK=`<a href="https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy" target="_blank">سياسة الخصوصية</a>`;
  const PRIVACY_POLICY_MAIL_TO_LINK = "<a href=\"mailto:privacy@gucci.com\">privacy@gucci.com</a>";
  const PRIVACY_POLICY_HTML_VAULT_PART_1 = "أنت مدعو لقراءة " + PRIVACY_POLICY_VAULT_LINK + " الخاصة بنا بالكامل. على أية حال، هاك بعض النقاط الرئيسية التي نود مشاركتها معك:";
  const PRIVACY_POLICY_HTML_PART_2 = "أنت بصدد إنشاء ملف GUCCI الخاص بك. وهذا ما سوف يجيز لـ GUCCI أن تقدم لك تجربة شخصية و مناسبة ، وأن تزودك بالمنتجات والخدمات والمعلومات التي تطلبها من GUCCI وتتواصل معك. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. وقد نستخدم أية معلومات إتصال تشاركها معنا للاتصال بك.";
  const PRIVACY_POLICY_HTML_PART_2__QA = "أنت بصدد إنشاء ملف GUCCI الخاص بك. سوف تقوم GUCCI بإستخدام ملفك لتزويدك بالمنتجات والخدمات والمعلومات التي تشتريها أو تطلبها من GUCCI، وستقدم لك مساعدة وخدمة عملاء مخصصة. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. وقد نستخدم أية معلومات إتصال تشاركها معنا للاتصال بك. ";
  const PRIVACY_POLICY_HTML_PART_2__KW = "أنت بصدد إنشاء ملف GUCCI الخاص بك. وهذا ما سوف يجيز لـ GUCCI أن تقدم لك تجربة شخصية و مناسبة ، وأن تزودك بالمنتجات والخدمات والمعلومات التي تطلبها من GUCCI وتتواصل معك. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. فعبر إنشاء ملف GUCCI الخاص بك، أنت تؤكد بأنك قد قرأت وفهمت وتوافق على سياسة الخصوصية الخاصة بنا.";
  const PRIVACY_POLICY_HTML_PART_2__SA = "أنت بصدد إنشاء ملف GUCCI الخاص بك. وهذا ما سوف يجيز لـ GUCCI أن تقدم لك تجربة شخصية و مناسبة ، وأن تزودك بالمنتجات والخدمات والمعلومات التي تطلبها من GUCCI وتتواصل معك. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. فعبر إنشاء ملف GUCCI الخاص بك، أنت تؤكد بأنك قد قرأت وفهمت وتوافق على سياسة الخصوصية الخاصة بنا.";
  const PRIVACY_POLICY_HTML_PART_2__AE = "أنت بصدد إنشاء ملف GUCCI الخاص بك. وهذا ما سوف يجيز لـ GUCCI أن تقدم لك تجربة شخصية و مناسبة ، وأن تزودك بالمنتجات والخدمات والمعلومات التي تطلبها من GUCCI وتتواصل معك. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. فعبر إنشاء ملف GUCCI الخاص بك، أنت تؤكد بأنك قد قرأت وفهمت وتوافق على سياسة الخصوصية الخاصة بنا.";
  const PRIVACY_POLICY_HTML_PART_3 = "يجوز لقوانين الخصوصية أن تمنحك حقوقًا معينة مثل الحق في الدخول إلى بياناتك وحذفها وتعديلها وتصحيحها، أو تقييد معالجتها أو الاعتراض عليها، بالإضافة إلى الحق في قابلية نقل البيانات. كما يمكنك رفع شكوى إلى الجهة المختصة لديك. ويمكنك سحب موافقتك أو حذف ملفك الشخصي في أي وقت. لمزيد من المعلومات بشأن ممارسات الخصوصية وبشأن حقوقك، يرجى الاتصال بنا على " + PRIVACY_POLICY_MAIL_TO_LINK + ".";
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "سجّل اشتراكك باستخدام بريدك الإلكتروني وكلمة المرور من غوتشي أو يمكنك إنشاء ملف تعريف في حال كنت مشتركاً جديداً."
    },
    "titles": {
      "ACCESS_GUCCI": "المتابعة إلى VAULT"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": `باختيار "إنشاء ملفي"، فإنك تؤكد أنك قرأت وفهمت ${PRIVACY_POLICY_VAULT_LINK} الخاصة بنا، وأنك تريد إنشاء ملف الخاص بك  GUCCI`,
      "PRIVACY_POLICY_TEXT__QA": `باختيار "إنشاء ملفي"، فإنك تؤكد موافقتك على ${PRIVACY_POLICY_VAULT_LINK} الخاصة بنا، وأنك تريد إنشاء ملف الخاص بك  GUCCI`,
      "PRIVACY_POLICY_TEXT__KW": `باختيار "إنشاء ملفي"، فإنك تؤكد موافقتك على ${PRIVACY_POLICY_VAULT_LINK} الخاصة بنا، وأنك تريد إنشاء ملف الخاص بك  GUCCI`,
      "PRIVACY_POLICY_TEXT__SA": `باختيار "إنشاء ملفي"، فإنك تؤكد موافقتك على ${PRIVACY_POLICY_VAULT_LINK} الخاصة بنا، وأنك تريد إنشاء ملف الخاص بك  GUCCI`,
      "PRIVACY_POLICY_TEXT__AE": `باختيار "إنشاء ملفي"، فإنك تؤكد موافقتك على ${PRIVACY_POLICY_VAULT_LINK} الخاصة بنا، وأنك تريد إنشاء ملف الخاص بك  GUCCI`,
      "PRIVACY_POLICY_HTML": PRIVACY_POLICY_HTML_VAULT_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3,
      "PRIVACY_POLICY_HTML__QA": PRIVACY_POLICY_HTML_VAULT_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2__QA + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3,
      "PRIVACY_POLICY_HTML__KW": PRIVACY_POLICY_HTML_VAULT_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2__KW + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3,
      "PRIVACY_POLICY_HTML__SA": PRIVACY_POLICY_HTML_VAULT_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2__SA + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3,
      "PRIVACY_POLICY_HTML__AE": PRIVACY_POLICY_HTML_VAULT_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2__AE + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3,
      "PRIVACY_POLICY_SHORT_HTML":  PRIVACY_POLICY_VAULT_LINK + "باختيارك \"متابعة\" أدناه فإنك توافق على "

    }
  };
}

function german(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/en-US/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `Indem Sie "Erstelle Mein Profil" wählen, bestätigen Sie, dass Sie unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> gelesen und verstanden haben und dass Sie Ihr GUCCI-Profil erstellen möchten.`;
  const getPrivacyPolicyTextCountry = LINK => `Indem Sie "Erstelle Mein Profil" wählen, bestätigen Sie, dass Sie mit unserer <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> einverstanden sind und dass Sie Ihr GUCCI Profil erstellen möchten.`;
  const getPrivacyPolicyHTML = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br/>
                              <br/>
                              Sie sind dabei Ihr GUCCI Profil zu erstellen. Dies ermöglicht es GUCCI, Ihnen ein personalisiertes und maßgeschneidertes Erlebnis anzubieten, Ihnen Produkte, Dienstleistungen und Informationen, die Sie von GUCCI anfordern, zur Verfügung zu stellen und mit Ihnen zu kommunizieren. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren. <br/>
                              <br/>
                              Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTML_QA = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br />
                                  <br />
                                  Sie sind dabei Ihr GUCCI Profil zu erstellen. GUCCI wird Ihr Profil verwenden, um Ihnen die Produkte, Dienstleistungen und Informationen zur Verfügung zu stellen, die Sie kaufen oder von GUCCI anfordern, um Ihnen maßgeschneiderte Unterstützung und Kundendienst anzubieten, um Ihnen maßgeschneiderte Werbemitteilungen zu senden, um unsere Geschäfte sicher und in Übereinstimmung mit unseren Verpflichtungen abzuwickeln und um unsere Dienstleistungen zu optimieren und zu verbessern. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren. Durch die Erstellung Ihres GUCCI Profils bestätigen Sie, dass Sie unsere <a href="${baseUrl}/st/privacy-landing" target="_blank">Datenschutzrichtlinie</a> gelesen und verstanden haben, und dass Sie damit einverstanden.<br />
                                  <br />
                                  Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTML_Country = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br />
                                  <br />
                                  Sie sind dabei Ihr GUCCI Profil zu erstellen. Dies ermöglicht es GUCCI, Ihnen ein personalisiertes und maßgeschneidertes Erlebnis anzubieten, Ihnen Produkte, Dienstleistungen und Informationen, die Sie von GUCCI anfordern, zur Verfügung zu stellen und mit Ihnen zu kommunizieren. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren. Durch die Erstellung Ihres GUCCI Profils bestätigen Sie, dass Sie unsere <a href="${baseUrl}/st/privacy-landing" target="_blank">Datenschutzrichtlinie</a> gelesen und verstanden haben, und dass Sie damit einverstanden.<br />
                                  <br />
                                  Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `Wenn Sie unten auf „Weiter“ klicken, erklären Sie sich mit unseren Datenschutzbestimmungen einverstanden. <a href="${LINK}" target="_blank">Datenschutzbesti</a>.`;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Melden Sie sich mit Ihrer GUCCI E-Mail-Adresse und Ihrem Passwort an oder erstellen Sie ein Profil, wenn Sie neu sind."
    },
    "titles": {
      "ACCESS_GUCCI": "Weiter zu VAULT"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTML_QA(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTML_Country(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__SA": getPrivacyPolicyHTML_Country(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__AE": getPrivacyPolicyHTML_Country(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(vaultPrivacyLandingUrl)
    }
  };
}

function englishGreatBritain(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/en-US/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `By choosing "Create my profile", you confirm that you have read and understood our <a href="${LINK}" target="_blank">privacy policy</a>, and that you want to create your GUCCI profile.`;
  const getPrivacyPolicyTextUS = LINK => `By choosing "Create my profile", you acknowledge the terms of our <a href="${LINK}" target="_blank">privacy policy</a>, and you confirm that you want to create your GUCCI profile.`;
  const getPrivacyPolicyTextCountry = LINK => `By choosing "Create my profile", you confirm that you agree to our <a href="${LINK}" target="_blank">privacy policy</a>, and that you want to create your GUCCI profile.`;
  const getPrivacyPolicyHTML = LINK => `You are invited to read our <a href="${LINK}" target="_blank">privacy policy</a> in full. In any event, here are some key points we would like to share with you:<br />
                              <br />
                              You are about to create your GUCCI profile. This will allow GUCCI to offer you a personalized and tailored experience, provide you with products, services and information you request from GUCCI and communicate with you. All your personal information will be kept, used and linked to your GUCCI profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. <br />
                              <br />
                              Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLQA = LINK => `You are invited to read our <a href="${LINK}" target="_blank">privacy policy</a> in full. In any event, here are some key points we would like to share with you:<br />
                                  <br />
                                  You are about to create your GUCCI profile. GUCCI will use your profile to provide you with the products, services and information you purchase or request from GUCCI, offer you customized assistance and customer service, send you tailored promotional communications, conduct our business securely and in accordance with our obligations, and optimize and improve our services. All your personal information will be kept, used and linked to your GUCCI profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. <br />
                                  <br />
                                  Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `By choosing 'continue' below, you accept the terms of our <a href="${LINK}" target="_blank">privacy policy</a>.`;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Sign in with your GUCCI email and password or create a profile if you are new."
    },
    "titles": {
      "ACCESS_GUCCI": "Continue to VAULT"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__US": getPrivacyPolicyTextUS(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLQA(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`)

    }
  };
}
function spanish(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/en-US/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `Eligiendo "Crear mi perfil", confirma que ha leído y entendido nuestra <a href="${LINK}" target="_blank">política de privacidad</a>, y que desea crear su perfil GUCCI.`;
  const getPrivacyPolicyTextCountry = LINK => `Eligiendo "Crear mi perfil", confirma que acepta nuestra <a href="${LINK}" target="_blank">política de privacidad</a>, y que desea crear su perfil GUCCI.`;
  const getPrivacyPolicyHTML = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
                              <br />
                              Está a punto de crear su perfil GUCCI. Esto permitirá a GUCCI ofrecerle una experiencia personalizada y a medida, proporcionarle con productos, servicios e información que usted solicite de GUCCI y comunicarse con usted. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Podemos usar cualquier información de contacto que haya compartido con nosotros para contactarle. <br />
                              <br />
                              Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLCountry = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
                                  <br />
                                  Está a punto de crear su perfil GUCCI. GUCCI usará su perfil para proporcionarle productos, servicios e información que usted compre o solicite por parte de GUCCI, ofrecerle asistencia personalizada y servicio de atención al cliente, enviarle comunicaciones comerciales personalizadas, llevar a cabo nuestro negocio de forma segura y de acuerdo con nuestras obligaciones, y optimizar y mejorar nuestros servicios. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Podemos usar cualquier información de contacto que haya compartido con nosotros para contactarle. Al crear su perfil GUCCI, confirma haber leído, entendido y acepta nuestra <a href="${baseUrl}/st/privacy-landing" target="_blank">política de privacidad</a>.<br />
                                  <br />
                                  Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `Al seleccionar 'continuar' en la parte inferior, acepta los términos de la <a href="${LINK}" target="_blank">política de privacidad</a>.`;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Inicie sesión con su correo electrónico y contraseña de GUCCI o cree un perfil si no lo tiene."
    },
    "titles": {
      "ACCESS_GUCCI": "Continuar a VAULT"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(vaultPrivacyLandingUrl)
    }
  };
}
function french(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/en-US/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `En choisissant “Créer mon profil”, vous confirmez avoir lu et compris notre <a href=\"${LINK}\" target="_blank">politique de confidentialité</a>, et vouloir créer votre profil GUCCI.`;
  const getPrivacyPolicyTextCountry = LINK => `En choisissant “Créer mon profil”, vous confirmez accepter notre <a href=\"${LINK}\" target="_blank">politique de confidentialité</a>, et vouloir créer votre profil GUCCI.`;
  const getPrivacyPolicyHTML = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels :<br />
                              <br />
                              Vous êtes sur le point de créer votre profil GUCCI. Ce profil permettra à GUCCI de vous proposer une expérience personnalisée et sur mesure, de vous fournir les produits, services et renseignements que vous demandez à GUCCI, et de communiquer avec vous. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter. <br />
                              <br />
                              Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLQA = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels:<br />
                                  <br />
                                  Vous êtes sur le point de créer votre profil GUCCI. GUCCI utilisera votre profil pour vous fournir les produits, les services et les informations que vous achetez ou demandez à GUCCI, pour vous proposer un service client et une assistance personnalisés, pour vous envoyer des communications promotionnelles personnalisées, pour exercer nos activités en toute sécurité et conformément à nos obligations, et pour optimiser et améliorer nos services. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter. En créant votre profil GUCCI, vous confirmez que vous avez lu et compris et que vous acceptez notre <a href="${baseUrl}/st/privacy-landing" target="_blank">politique de confidentialité</a>. <br />
                                  <br />
                                  Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLCountry = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels:<br />
                                  <br />
                                  Vous êtes sur le point de créer votre profil GUCCI. Ce profil permettra à GUCCI de vous proposer une expérience personnalisée et sur mesure, de vous fournir les produits, services et renseignements que vous demandez à GUCCI, et de communiquer avec vous. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter. En créant votre profil GUCCI, vous confirmez que vous avez lu et compris et que vous acceptez notre <a href="${baseUrl}/st/privacy-landing" target="_blank">politique de confidentialité</a>. <br />
                                  <br />
                                  Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `En choisissant de «continuer» ci-dessous, vous acceptez les conditions de notre <a href="${LINK}" target="_blank">politique de confidentialité</a>.`;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Connectez-vous à l’aide de votre adresse e-mail et votre mot de passe GUCCI ou créez un profil si vous êtes un nouveau client."
    },
    "titles": {
      "ACCESS_GUCCI": "Continuez vers VAULT"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLQA(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__SA": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__AE": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(vaultPrivacyLandingUrl)
    }
  };

} function italian(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/it-IT/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const vaultPrivacyUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `Optando per "Creazione profilo", Lei conferma di avere letto e compreso la nostra <a href=\"${LINK}\" target=\"_blank\">informativa sulla privacy</a>, e di voler creare il suo profilo GUCCI.`;
  const getPrivacyPolicyTextCountry = LINK => `Optando per "Creazione profilo", Lei conferma di accettare la nostra <a href=\"${LINK}\" target="_blank">informativa sulla privacy</a>, e di voler creare il suo profilo GUCCI.`;
  const getPrivacyPolicyHTML = LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
                              <br />
                              Lei sta per creare il Suo profilo GUCCI. Ciò consentirà a GUCCI di offrirLe un’esperienza personalizzata e su misura, fornirLe prodotti, servizi ed informazioni che Lei richiederà a GUCCI e comunicare con Lei. Tutte le Sue informazioni personali saranno conservate, utilizzate e collegate al Suo profilo GUCCI. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa. <br />
                              <br />
                              Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLQA = LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
                                  <br />
                                  Lei sta per creare il Suo profilo GUCCI. GUCCI utilizzerà il Suo profilo per fornirLe i prodotti, i servizi e le informazioni da Lei acquistati o richiesti da GUCCI, per offrirLe assistenza e servizio clienti personalizzati, per inviarLe comunicazioni promozionali su misura, per condurre la nostra attività in modo sicuro ed in conformità con i nostri obblighi e per ottimizzare e migliorare i nostri servizi. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa. Con la creazione del Suo profilo GUCCI, Lei conferma di avere letto e compreso e di accettare la nostra <a href="${baseUrl}/st/privacy-landing" target="_blank">informativa sulla privacy</a>.<br />
                                  <br />
                                  Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLCountry = LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
                                  <br />
                                  Lei sta per creare il Suo profilo GUCCI. Ciò consentirà a GUCCI di offrirLe un’esperienza personalizzata e su misura, fornirLe prodotti, servizi ed informazioni che Lei richiederà a GUCCI e comunicare con Lei. Tutte le Sue informazioni personali saranno conservate, utilizzate e collegate al Suo profilo GUCCI. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa. Con la creazione del Suo profilo GUCCI, Lei conferma di avere letto e compreso e di accettare la nostra <a href="${baseUrl}/st/privacy-landing" target="_blank">informativa sulla privacy</a>.<br />
                                  <br />
                                  Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `Scegliendo "continua" accetti i termini della nostra politica sulla privacy. <a href="${LINK}" target="_blank">Informativa sulla privacy</a>.`;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Accedi con il tuo indirizzo e-mail e la password GUCCI oppure crea un profilo se non sei già iscritto."
    },
    "titles": {
      "ACCESS_GUCCI": "Continua su VAULT"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT" : getPrivacyPolicyText(vaultPrivacyUrl),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLQA(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__SA": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__AE": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(vaultPrivacyLandingUrl)

    }
  };

} function japanese(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/ja-JP/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `「プロフィールを作成する」を選ぶと、弊社<a href="${LINK}" target="_blank">個人情報保護方針</a>を読んで理解し、GUCCIプロフィールの作成をご希望なさったことになります。`;
  const getPrivacyPolicyHTML = LINK => `弊社<a href="${LINK}" target="_blank">個人情報保護方針</a>をよくお読みください。主要ポイントの詳細を次で説明いたします：<br />
                              <br />
                              GUCCI プロファイルの作成について。GUCCIはあなた個人の好みに合わせたパーソナライズド体験を提案すると同時に、あなたがGUCCIにリクエストした製品・サービス・情報をお届けし、あなたと交流します。あなたの個人情報は全て、GUCCIプロフィールに保存、使用、紐づけされます。プロフィールには、あなたから直接得た情報をはじめ、弊社商品を扱うショップや他の取引先から得た情報も含みます。弊社は世界各地で事業展開しており、あなたの個人情報は、私どものグループ傘下企業及び各国の現地正式取引先と、安全に共有されます。弊社ではあなたの個人情報を保護するために、安全対策を講じています。弊社は、あなたが弊社と共有している連絡先を通じて連絡を差し上げることがあります。<br />
                              <br />
                              個人情報保護法は、あなた個人のデータへのアクセス、削除、変更、修正、ないしは処理の制限や異議などへの権利をはじめ、データポータビリティ権といった権利をあなたに保証します。お住まいの地域の個人情報保護委員会に訴えることも可能です。あなたは何時も同意の取消しやプロフィールの削除ができます。弊社における個人情報保護実施やあなたの権利についての詳細は、<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>へお問い合わせください。
      `;
  const getPrivacyPolicyShortHTML = LINK => `ご選択いただくことで、弊社のプライバシーポリシーに同意いただいたことになります。 <a href="${LINK}" target="_blank">プライバシーポリシーはこちら</a>`;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "My Gucci アカウントのメールアドレスとパスワードでサインインしてください。初めて利用されるお客さまは新規でMy Gucciアカウントを作成してください。"
    },
    "titles": {
      "ACCESS_GUCCI": "Vaultへアクセスする"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(vaultPrivacyLandingUrl)
    }
  };

} function korean(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/en-US/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `GUCCI 프로필을 생성함으로써, 귀하는 본사의 <a href="${LINK}" target="_blank">개인 정보 보호 정책</a>을 읽고 이해했음을 확인합니다. 상품과 서비스 제공에 필요한 개인 정보의 수집, 사용 및 제3자 제공에 동의하지 않는 경우, GUCCI의 상품과 서비스를 제공 받지 못할 수 있습니다.`;
  const getPrivacyPolicyHTML = LINK => `해당 문서는 본사의 <a href="${LINK}" target="_blank">개인 정보 보호 정책</a> 전문입니다. 본사가 귀하에게 안내하고자 하는 요점은 다음과 같습니다:<br />
                              <br />
                              귀하는 현재 GUCCI 프로필을 생성하고자 하고 있습니다. 이는 GUCCI으로 하여금 귀하에게 개인화되고 맞춤화된 서비스를 제공하고, 귀하가 GUCCI에 요청하는 상품, 서비스 및 정보를 제공하고 귀하와 소통하는 것을 도울 것입니다.<br />
                              <br />
                              귀하가 신청서에 기입한 모든 개인 정보와 귀하가 판매원 또는 다른 방식(예를 들어 소셜 미디어, 쿠키 등)을 통해 자발적으로 공유한 다른 개인 정보(이하 “개인 정보”)는 보관 및 사용되고, 귀하의 GUCCI 프로필에 연결될 것입니다. 귀하의 프로필은 귀하로부터 직접 받은 정보와 함께 본사의 소매점 및 타 상업적 파트너로부터 받은 정보를 포함할 수 있습니다. 현지 법률에 따라, 본사는 귀하의 개인 정보를 <b>귀하가 GUCCI을 이용하여 소통한 마지막 일시로부터 5년간</b> 보유할 것입니다. 마지막 소통은 고객에 의해 시작된 마지막 소통으로 정의되며 본사의 시스템이나 판매원에 의해 추적할 수 있습니다. 그 예로 귀하에게 전화, 판매 이메일, 또는 대면이 마지막으로 제의되고, 그에 대해 귀하가 이메일에 포함된 본사 웹사이트를 클릭하거나 전용 계정에 대한 제의에 따라 본사 매장에 가는 등 호의적으로 응답했을 때 등이 있습니다. GUCCI으로부터 온 이메일을 열어보는 것은 마지막 소통에 해당되지 않습니다. 이메일에 포함된 링크를 클릭하는 것과 본사의 매장에 방문하는 것이 마지막 소통에 해당됩니다.<br />
                              <br />
                              본사가 국제적으로 운영되기 때문에, <b>본사는 귀하의 개인 정보를 GUCCI과 동일한 목적으로, 위에 명기된 기간 동안 우리 그룹의 기업들과 세계 곳곳에 위치한 승인된 파트너들과 안전하게 공유할 수 있습니다(기업들과 승인된 파트너들의 리스트는 다음 링크를 참고하시기 바랍니다: https://www.gucci.com/kr/ko/st/privacy-landing). 본사는 귀하의 개인 정보를 보호하기 위해 보안 장치를 시행할 것입니다</b>.<br />
                              <br />
                              본사는 귀하가 본사와 공유한 연락처를 사용할 수 있습니다. <b>이는 귀하의 관심사와 쇼핑 습관에 대해 문자 메시지, 멀티미디어 메시지, 이메일, 우편, 인터넷, 소셜 미디어 및 휴대폰 등의 다양한 경로를 통해 귀하와 소통하기 위함입니다. 또, 이는 주로 자료 수집을 통해 본사의 서비스를 향상시키고, 위에 명기된 기간 동안 본사 웹사이트를 통해 귀하에게 노출된 광고를 선별하여 그와 유사한 타겟 마케팅을 하기 위함입니다</b>.<br />
                              <br />
                              <br />
                              개인 정보 보호법은 귀하에게 개인 정보 이동권과 더불어 귀하의 데이터에 대한 접근, 삭제, 수정 및 정정, 처리에 대한 제한 및 반대의 권리와 같은 특정 권리를 부여할 수 있습니다. 귀하는 또한 유능한 규제 기관과 함께 항의를 제기할 수 있습니다. 귀하는 언제든지 동의를 철회하거나 프로필을 삭제할 수 있습니다. 본사의 개인 정보 관리와 귀하의 권리에 대한 더 많은 정보를 원하시면, <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>으로 연락 주시기 바랍니다.
      `;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "Sign in with your GUCCI email and password or create a profile if you are new."
    },
    "titles": {
      "ACCESS_GUCCI": "구찌 계정으로 로그인하기"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl)
    }
  };
}

function simplifiedChinese(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/en-US/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `当您选择”建立我的个人账户”，您即确认您已阅读并理解我们的<a href="${LINK}" target="_blank">隐私政策</a>，且您希望建立您的GUCCI个人账户。`;
  const getPrivacyPolicyTextCountry = LINK => `当您选择"建立我的个人帐户"，您即确认您已同意我们的<a href="${LINK}" target="_blank">隐私政策</a>，且您希望建立GUCCI的个人账户。`;
  const getPrivacyPolicyHTML = LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                              <br />
                              您即将在GUCCI建立个人帐户,这将允许GUCCI带给您个人化和度身定制的体验，提供您所要求的GUCCI产品、服务及信息并与您联络。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您。<br />
                              <br />
                              隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改或更正您的个人信息，或限制我们处理或传送您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyHTMLQA = LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                                  <br />
                                  您即将在GUCCI建立个人帐户。GUCCI将会运用您的个人账户，以向您提供于GUCCI所购买或要求的产品或服务，提供您客制化的客户服务和协助，寄送给您量身订制的营销信息，依据我们的职责安全地履行业务，优化和改进我们的服务。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您。当您建立您的GUCCI个人帐户时，您即确认您已阅读、理解并同意我们的<a href="${baseUrl}/st/privacy-landing" target="_blank">隐私政策</a>。<br />
                                  <br />
                                  隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改或更正您的个人信息，或限制我们处理或传送您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyHTMLCountry = LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                                  <br />
                                  您即将在GUCCI建立个人帐户, 这将允许GUCCI带给您个人化和度身定制的体验，提供您所要求的GUCCI产品、服务及信息并与您联络。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您。<br />
                                  <br />
                                  隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改、更正您的个人信息，或限制我们处理或传输您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyShortHTML = LINK => `若接受我们的条款，请选择下方的“继续” <a href="${LINK}" target="_blank">隐私政策</a>。`;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "使用GUCCI账户的电子邮件和密码登录，亦或创建新顾客档案"
    },
    "titles": {
      "ACCESS_GUCCI": "继续浏览VAULT"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLQA(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML__SA": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTM__AE": getPrivacyPolicyHTMLCountry(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(vaultPrivacyLandingUrl)
    }
  };
}

function traditionalChinese(baseUrl) {
  const vaultURI=window.location.search.split("returnURI")[1] ? window.location.search.split("returnURI")[1] : "https://vault.gucci.com/en-US/st/privacy";
  const vaultPrivacyLandingUrl=`https://${vaultURI.split("/")[2]}/${vaultURI.split("/")[3]}/st/privacy`;
  const getPrivacyPolicyText = LINK => `當您選擇"建立我的個人帳戶"，您即確認您已閱讀並理解我們的<a href="${LINK}" target="_blank">私隱政策</a>，且您希望建立GUCCI的個人檔案。`;
  const getPrivacyPolicyHTML = LINK => `敬請閱讀我們完整的<a href="${LINK}" target="_blank">私隱政策</a>。以下是政策關鍵資訊，請您務必瞭解： <br />
                              <br />
                              您即將在GUCCI建立個人帳戶，讓GUCCI帶給您個人化和度身訂制的體驗，提供您所要求的GUCCI產品、服務及資訊並與您聯絡。所有您的個人資料將儲存、運用於及連結至您的GUCCI個人帳戶。您的個人帳戶將包含我們及我們的零售商或其他商業夥伴向您取得的資料。由於我們是跨國企業，我們可能會將您的個人資料安全地與分布在世界各地的本集團公司以及我們認可的合作夥伴進行共享。我們會採用安全措施以保護您的個人資料。我們可能會使用任何您提供的聯絡資料聯絡您。<br />
                              <br />
                              隱私權相關法規賦予您若干權利。您可以向我們要求取得、刪除、修改、更正您的個人資料;或限制我們處理或傳送您個人資料之權利。您亦可向相關監管機構提出申訴，或於任何時候撤回同意或刪除個人帳戶。如您想取得關於我們的實踐私隱的措施及您的權利的資訊，您可以通過電子郵件與我們聯絡：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。<br />
      `;
  const getPrivacyPolicyShortHTML = LINK => `當您點選下方的「繼續」後，即表示接受我們的<a href="${LINK}" target="_blank">私隱政策</a>。`;
  return {
    "messages": {
      "REGISTRATION_INFORMATION": "請使用您的GUCCI電郵及密碼登入，而如果您是新用戶則請建立個人檔案。"
    },
    "titles": {
      "ACCESS_GUCCI": "繼續前往VAULT"
    },
    "texts": {
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(vaultPrivacyLandingUrl),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(vaultPrivacyLandingUrl)
    }
  };
}

export { getTranslations as vaultTranslations };

