import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";

const fetchToggles = (environment, features, variant) => {
  return fetch(`${getToggleBaseUrl(environment)}/toggles/${environment}?key=${features.join(",")}&variant=${variant}`)
    .then(response => response.status === 200 ? response.json() : []);
};

const getToggleBaseUrl = (environment) => {
  let toggleBaseUrl = "https://nonprod-feature-toggle.dev.guccidigital.io";
  return environment === "prod" ? "https://prod-feature-toggle.guccidigital.io" : toggleBaseUrl;
};

const reduceToggles = (results) => {
  return results.reduce((previousValue, result) => ({
    ...previousValue,
    [result.key]: result.value
  }), {});
};

const OriginalFeatureToggleContext = React.createContext();

const FeatureToggleProvider = ({ environment, features, variant, children }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    let isMounted = true;

    fetchToggles(environment.toLowerCase(), features, variant).then(results => {
      if (isMounted) {
        setValue(reduceToggles(results));
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <OriginalFeatureToggleContext.Provider value={value}>
      {children}
    </OriginalFeatureToggleContext.Provider>
  );
};

FeatureToggleProvider.propTypes = {
  environment: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  variant: PropTypes.string.isRequired,
  children: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]
  )
};

export const FeatureToggleContext = {
  Consumer: OriginalFeatureToggleContext.Consumer,
  Provider: FeatureToggleProvider
};

export const useFeatureToggle = () => {
  const features = useContext(OriginalFeatureToggleContext);

  if (!features) {
    return;
  }

  return {
    getToggle: (key) => features[key] === true ? true : false
  };
};
