import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./SocialExists.module.scss";
import Social from "../social/Social";
import { InformativeText, Title } from "../ui/typography";
import { useLocale } from "../../common/global";
import { getQueryParams, redirectToLocalUrl } from "../../common/utils";
import * as PropTypes from "prop-types";
import {
  GTMAccessClickEventsPusher,
  GTMAccessImpressionEventsPusher,
  GTMCheckoutEventsPusher,
  GTMuaVPVPusher
} from "../../common/analytics";
import { Features, SocialProvider } from "../../common/constants";
import StandaloneWrapper from "../standalone/StandaloneWrapper";
import StandaloneLogo from "../standalone/standaloneHeader/StandaloneLogo";
import StandaloneHeader from "../standalone/standaloneHeader/StandaloneHeader";
import CancelButton from "../standalone/CancelButton";
import { useStandalone } from "../standalone/StandaloneContext";
import { useFeatureToggle } from "@gucci-private/feature-toggle-js";
import { useAsync } from "../../common/customHooks";
import { getUserSocialProviders } from "../../api/idpUserServiceApi";
import Loader from "../ui/loader/Loader";

export function SocialExists({ isCheckout, isSSALogged = false }) {
  const { getToggle } = useFeatureToggle();
  const locale = useLocale();
  const location = useLocation();
  const { email, returnURI } = getQueryParams(location);
  const isGuestCheckoutAllowed = !getToggle(Features.GUEST_CHECKOUT_DISABLED);
  const isGuestCheckoutAllowedForSaleAssistant = getToggle(Features.GUEST_CHECKOUT_ENABLED_FOR_SSA);
  const isSkipLoginAllowed = isGuestCheckoutAllowed || (isGuestCheckoutAllowedForSaleAssistant && isSSALogged);
  const { isStandaloneLogin, isVault } = useStandalone();
  const socialProvidersStatus = {};
  const [userSocialProvidersStatus, setUserSocialProvidersStatus] = useState(null);
  const {
    execute: executeGetUserSocialProviders,
    value: getUserSocialProvidersResponse, error: getUserSocialProvidersReqError
  } = useAsync(getUserSocialProviders, false);

  useEffect(() => {
    GTMuaVPVPusher(
      "vpv.social",
      `/${locale.country}/${locale.language}/vpv/my-account/${isCheckout ? "checkout" : "view"}/social-exists`
    );
    GTMAccessImpressionEventsPusher({
      action: "Impression Google Login",
      label: returnURI
    });
  }, []);

  function redirectToGuestCheckout() {
    GTMCheckoutEventsPusher("Customer as Guest");
    redirectToLocalUrl(`/login/checkout/access/guest?email=${encodeURIComponent(email)}`, locale.baseUrl);
  }

  useEffect(() => {
    if (email) {
      executeGetUserSocialProviders(email);
    }
  }, []);

  useEffect(() => {
    if (getUserSocialProvidersResponse) {
      getUserSocialProvidersResponse.json().then(usersSocialProviders => {
        if (usersSocialProviders.length === 0) {
          usersSocialProviders = [SocialProvider.GOOGLE];
        }

        const providersActivedStatus = [];
        for (const provider of Object.values(SocialProvider)) {
          providersActivedStatus[provider] = usersSocialProviders.includes(provider);
        }
        setUserSocialProvidersStatus(providersActivedStatus);
      });
    } else {
      setUserSocialProvidersStatus({});
    }
  }
  ,[getUserSocialProvidersResponse]);

  useEffect(() => {
    if (getUserSocialProvidersReqError) {
      setUserSocialProvidersStatus(socialProvidersStatus);
    }
  }, [getUserSocialProvidersReqError]);

  const content = <div data-testid="social-exists">
    <Title className={"is-title-xl"}>{locale.titles.ACCOUNT_EXISTS}</Title>
    <InformativeText className="is-text-light" innerHTML={
      locale.i18n("messages.SOCIAL_ACCOUNT_EXISTS", {
        email: `<strong data-testid="social-exists-email">${email}</strong>`
      })
    } />
    {
      userSocialProvidersStatus ?
        <Social isCheckout={isCheckout} isStandaloneLogin={isStandaloneLogin} socialProvidersStatus={userSocialProvidersStatus} />
        :
        <Loader />
    }
    {
      isCheckout && isSkipLoginAllowed
      && <button data-testid="skip-login" className={styles.skipButton} onClick={redirectToGuestCheckout}>
        {locale.labels.GUEST_USER_CHECKOUT}
      </button>
    }
  </div>;

  return (
    <>
      {
        isVault ?
          <>
            <StandaloneLogo />
            <StandaloneWrapper>
              <StandaloneHeader socialAccountExists={true} />
              {content}
              <CancelButton onClick={() => GTMAccessClickEventsPusher({
                action: "Click Google Login",
                label: "Cancel"
              })} label="Cancel" />
            </StandaloneWrapper>
          </>
          :
          <>{content}</>
      }
    </>);
}

SocialExists.propTypes = {
  isCheckout: PropTypes.bool,
  isSSALogged: PropTypes.bool
};

export default SocialExists;
