/* eslint-disable indent */
import React from "react";
import * as PropTypes from "prop-types";
import { useLocale } from "../../common/global";
import { InformativeText, SubTitle, Title } from "../ui/typography";
import Social from "../social/Social";
import { useSocialProviders } from "../../common/customHooks";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "../../common/utils";
import { useStandalone } from "../standalone/StandaloneContext";
import { ClientType } from "../../common/constants";

const SourcePageEnum = {
  CHECKOUT: "checkout",
  BAA: "BAA"
};

function AccessHeader({ isCheckout }) {
  const socialProvidersStatus = useSocialProviders();
  const source = getQueryParams(useLocation()).source ?? (isCheckout ? SourcePageEnum.CHECKOUT : undefined);
  const locale = useLocale();
  const { client } = useStandalone();
  const fields = computeFields(source);

  return (<>
    <Title className={"is-title-xl"}>{fields.title}</Title>
    <InformativeText className={"is-text-medium"}>{fields.extraInformation}</InformativeText>
    {Object.values(socialProvidersStatus).includes(true) && <>
      <Social isCheckout={isCheckout} socialProvidersStatus={socialProvidersStatus} />
      <InformativeText className={"is-title-s"}>{locale.messages.OR}</InformativeText>
    </>}
    <SubTitle className={"is-title-l"}>{fields.continueWithEmail}</SubTitle>
    <InformativeText className={"is-text-medium"}>{fields.registerInformation}</InformativeText>
  </>
  );

  function computeFields(sourcePage) {
    if (client?.source === ClientType.YUGA) {
      return getYugaFields(locale);
    }
    switch (sourcePage) {
      case SourcePageEnum.CHECKOUT: return getCheckoutFields(locale);
      case SourcePageEnum.BAA: return getBAAFields(locale);
      default: return getDefaultFields(locale);
    }
  }
}

AccessHeader.propTypes = {
  isCheckout: PropTypes.bool
};

export default AccessHeader;

function getDefaultFields(locale) {
  return {
    title: locale.titles.ACCESS_GUCCI,
    extraInformation: "",
    continueWithEmail: locale.titles.CONTINUE_WITH_EMAIL,
    registerInformation: locale.messages.REGISTRATION_INFORMATION
  };
}

function getCheckoutFields(locale) {
  return {
    title: locale.titles.LOGIN_TO_CHECKOUT,
    extraInformation: "",
    continueWithEmail: locale.titles.USE_YOUR_EMAIL,
    registerInformation: locale.messages.CHECKOUT_INFORMATION
  };
}

function getBAAFields(locale) {
  return {
    title: locale.titles.BAA,
    extraInformation: locale.messages.BAA_DESCRIPTION,
    continueWithEmail: "",
    registerInformation: locale.messages.BAA_INFORMATION
  };
}

function getYugaFields(locale) {
  return {
    title: locale.titles.YUGA,
    extraInformation: locale.messages.YUGA_DESCRIPTION,
    continueWithEmail: "",
    registerInformation: locale.messages.YUGA_INFORMATION
  };
}

