import React from "react";
import styles from "./ProfilingConsent.module.scss";
import * as PropTypes from "prop-types";
import { Checkbox } from "@gucci-private/gucci-react-components";
import { i18n } from "../../../common/i18n";

function ProfilingConsent({ country, language, setProfiling, profiling, setIsProfilingValid, isProfilingValid }) {
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country,language,baseUrl);

  function handleOnChange(event) {
    setProfiling(event.target.checked);
    setIsProfilingValid(event.target.checked);
  }

  return (
    <div className={styles.wrapper}>
      <Checkbox
        id="profiling-consent"
        formValidationFailed={!isProfilingValid}
        checked={profiling}
        onChange={handleOnChange}
        label={locale.i18n("labels.PROFILING_CONSENT_CHECKBOX")}
        withBorder={true}
      />
    </div>
  );
}

ProfilingConsent.propTypes = {
  profiling: PropTypes.bool.isRequired,
  setProfiling: PropTypes.func.isRequired,
  setIsProfilingValid: PropTypes.func.isRequired,
  isProfilingValid: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default ProfilingConsent;
