import React from "react";
import styles from "./PrivacyPolicy.module.scss";
import * as PropTypes from "prop-types";
import { i18n } from "../../../common/i18n";
import { ClientType } from "../../../common/constants";

function PrivacyPolicy({ isCollapsed, toggleCollapsed, country, language, client, existingDosCustomer = false }) {
  const baseUrl = (client && client.source === ClientType.GUCCI_APP) ? `https://www.gucci.com/${country}/${language}` : `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country, language, baseUrl, client?.source);

  const dosCustomerPrivacyLink = <p className={styles.onlyLinkContent} data-testid="privacy-policy-content" dangerouslySetInnerHTML={{
    __html: locale.i18n("texts.PRIVACY_POLICY_ONLY_LINK")
  }} />;
  const privacyHtml = (
    <>
      <p className={styles.content} data-testid="privacy-policy-content" dangerouslySetInnerHTML={{
        __html: locale.i18n("texts.PRIVACY_POLICY_TEXT")
      }} />
      <button type="button" data-testid="privacy-policy-read-more" className={`${styles.readMoreButton} ${!isCollapsed ? styles.active : ""}`}
        onClick={toggleCollapsed}>
        {isCollapsed ? locale.labels.READ_MORE : locale.labels.CLOSE}
      </button>
      {!isCollapsed &&
        <p data-testid="privacy-policy-collapsable-content" className={styles.content} dangerouslySetInnerHTML={{
          __html: locale.i18n("texts.PRIVACY_POLICY_HTML")
        }} />
      }
      {country === "us" && <p className={styles.content} data-testid="age-of-consent" dangerouslySetInnerHTML={{
        __html: locale.i18n("texts.AGE_OF_CONSENT")
      }} />
      }
    </>
  );

  return (
    <div className={styles.wrapper}>
      { existingDosCustomer ? dosCustomerPrivacyLink : privacyHtml }
    </div>
  );
}

PrivacyPolicy.propTypes = {
  toggleCollapsed: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  client: PropTypes.object,
  existingDosCustomer: PropTypes.bool
};
export default PrivacyPolicy;
