import React, { useEffect, useState } from "react";
import Email from "../ui/email/Email";
import ConfirmButton from "../ui/confirmButton/ConfirmButton";
import * as PropTypes from "prop-types";
import styles from "./UserCheckForm.module.scss";
import { useLocale } from "../../common/global";
import AccessHeader from "../accessHeader/AccessHeader";
import StandaloneWrapper from "../standalone/StandaloneWrapper";
import StandaloneLogo from "../standalone/standaloneHeader/StandaloneLogo";
import StandaloneHeader from "../standalone/standaloneHeader/StandaloneHeader";
import CancelButton from "../standalone/CancelButton";
import { GTMAccessImpressionEventsPusher, GTMAccessClickEventsPusher } from "../../common/analytics";
import { ClientType } from "../../common/constants";
import { useStandalone } from "../standalone/StandaloneContext";
import { decode, encodeState, getQueryParams } from "../../common/utils";
import { useLocation } from "react-router-dom";
import { getSourceConfig } from "../../common/config";
import { attemptAutoSignIn } from "../../api/oktaAuth";
import { isSessionExists } from "@gucci-private/gucci-auth";
import Loader from "../ui/loader/Loader";

export function UserCheckForm({ disableConfirm, email, errorMessage, formValidationFailed, isCheckout = false, isEmailValid, onSubmit, setEmail, setIsEmailValid, shouldAutoFocus }) {
  const locale = useLocale();
  const { isVault } = useStandalone();
  const { nonce, returnURI, stateToken, pkceConfig, error: hasError } = getQueryParams(useLocation());
  const [autoLogin, setAutoLogin] = useState();

  useEffect(() => {
    if (hasError) {
      setAutoLogin(false);
    } else {
      isSessionExists().then((exists) => {
        setAutoLogin(exists);
      });
    }
  }, []);

  useEffect(() => {
    if (autoLogin) {
      const state = encodeState({
        checkout: isCheckout,
        rememberMe: false,
        returnURI: returnURI,
        stateToken: stateToken,
        registration: false,
        locale: locale.country + "/" + locale.language
      });

      attemptAutoSignIn({
        state,
        nonce,
        pkceConfig: decode(pkceConfig)
      }).catch(() => {
        setAutoLogin(false);
      });
    }
    return setAutoLogin(false);
  }, [autoLogin]);

  const formElement = <form
    className={styles.accessForm}
    id="form"
    noValidate="novalidate"
    data-testid="user-check-form"
    onSubmit={onSubmit}
  >
    <div>
      <Email
        autofocus={shouldAutoFocus}
        value={email}
        onChange={value => setEmail(value)}
        invalid={!isEmailValid}
        setValidity={setIsEmailValid}
        formValidationFailed={formValidationFailed}
      />

      {errorMessage && (
        <span role="alert" className={styles.formSubmitError} data-testid="error-message">
          {errorMessage}
        </span>
      )}
    </div>

    <ConfirmButton buttonText={isCheckout?locale.labels.PROCEED_TO_CHECKOUT:locale.labels.CONTINUE} disabled={disableConfirm} onClick={() =>
      GTMAccessClickEventsPusher({
        action: "Click Access Page",
        label: "Continue"
      })
    } />
  </form>;

  useEffect(() => {
    GTMAccessImpressionEventsPusher({
      action: "Impression Access",
      label: returnURI
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorMessage && errorMessage !== "") {
      GTMAccessImpressionEventsPusher({
        action: "Impression Access Error",
        label: errorMessage
      });
    }
    // eslint-disable-next-line
  }, [errorMessage]);

  return (
    <>
      {autoLogin === undefined && <Loader />}
      {autoLogin === true && <Loader />}
      {autoLogin === false &&
        <>
          {
            isVault ?
              <>
                <StandaloneLogo />
                <StandaloneWrapper>
                  <StandaloneHeader />
                  {formElement}
                  {ClientType.GUCCI_APP !== getSourceConfig().client.source ?
                    <CancelButton label="Cancel" onClick={() =>
                      GTMAccessClickEventsPusher({
                        action: "Click Access Page",
                        label: "Cancel"
                      })
                    } /> : <></>}
                </StandaloneWrapper>
              </>
              :
              <>
                <AccessHeader isCheckout={isCheckout} />
                {formElement}
              </>
          }
        </>
      }
    </>
  );
}

UserCheckForm.propTypes = {
  disableConfirm: PropTypes.bool,
  email: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  formValidationFailed: PropTypes.bool,
  isCheckout: PropTypes.bool,
  isEmailValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setIsEmailValid: PropTypes.func.isRequired,
  shouldAutoFocus: PropTypes.bool
};

export default UserCheckForm;
