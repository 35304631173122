const CHALLENGE_TOKENS_KEY = "tokens";
const ACCESS_TOKEN_KEY = "accessToken";
const ID_TOKEN_KEY = "idToken";
const TokenManager = {
  setChallengeTokens: (challengeTokens) => {
    localStorage.removeItem(CHALLENGE_TOKENS_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.setItem(CHALLENGE_TOKENS_KEY, JSON.stringify(challengeTokens) || "");
  },
  getCodeChallenge: () => {
    const challengeTokens = localStorage.getItem(CHALLENGE_TOKENS_KEY) ? JSON.parse(localStorage.getItem(CHALLENGE_TOKENS_KEY) || "") : undefined;
    return challengeTokens && challengeTokens.codeChallenge;
  },
  popChallengeTokens: () => {
    const challengeTokens = localStorage.getItem(CHALLENGE_TOKENS_KEY) ? JSON.parse(localStorage.getItem(CHALLENGE_TOKENS_KEY) || "") : undefined;
    localStorage.removeItem(CHALLENGE_TOKENS_KEY);
    return challengeTokens;
  },
  getAccessToken: () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },
  getIdToken: () => {
    return localStorage.getItem(ID_TOKEN_KEY);
  },
  setUserTokens: (userTokens) => {
    localStorage.setItem(ID_TOKEN_KEY, userTokens.idToken.idToken);
    localStorage.setItem(ACCESS_TOKEN_KEY, userTokens.accessToken.accessToken);
  }
};

export default TokenManager;