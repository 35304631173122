import React from "react";
import * as PropTypes from "prop-types";
import Modal from "react-modal";
import styles from "./Overaly.module.scss";
import { deleteSearchParamsFromUrl } from "../../../common/utils";
import { useLocale } from "../../../common/global";
import { MODAL_PARENT_ID } from "../../../common/constants";

function Overlay(props) {
  const locale = useLocale();

  function handleClose() {
    const url = deleteSearchParamsFromUrl(["overlay", "overlayIsReset"]);
    window.history.replaceState(null, "", url.href);

    props.setIsOpen(false);
  }

  return <Modal
    isOpen={props.isOpen}
    className={styles.content}
    overlayClassName={styles.overlay}
    aria={{
      modal: true
    }}
    parentSelector={() => document.getElementById(MODAL_PARENT_ID)}
  >
    <button
      type="button"
      className={styles.closeIcon}
      onClick={handleClose}
      aria-label={locale.labels.CLOSE}
      data-testid={"overlay-close-icon"}
    />

    <h2 className={styles.title}>{props.title}</h2>
    <p data-testid="overlay-message" className={styles.message} dangerouslySetInnerHTML={{
      __html:props.message
    }}/>

    <button
      type="button"
      className={`${styles.closeButton}`}
      onClick={handleClose}
      data-testid={"overlay-close-button"}
    >
      {locale.labels.CLOSE}
    </button>
  </Modal>;
  //TODO: Use ConfirmButton Component instead
}

Overlay.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default Overlay;
