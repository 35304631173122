import { Genders } from "./constants";
import { datadogLogs } from "@datadog/browser-logs";

export function getQueryParams(location) {
  const params = {};
  for (let entry of new URL(`http://we-dont-need.domain/${location.search}`).searchParams.entries()) {
    params[entry[0]] = entry[1];
  }
  return params;
}

export function getPkceConfig() {
  const queryParams = getQueryParams(window.location);
  const standaloneAuthConfig = JSON.parse(localStorage.getItem("standaloneAuthConfig"));
  const pkceConfigFromLocalStorage = standaloneAuthConfig?.pkceConfig;
  return queryParams.pkceConfig || pkceConfigFromLocalStorage;
}

export function getQueryParamPkceConfig() {
  const queryParams = getQueryParams(window.location);
  return queryParams.pkceConfig;
}

export function deleteSearchParamsFromUrl(names = []) {
  const url = new URL(window.location.href);
  names.forEach(name => url.searchParams.delete(name));
  return url;
}

export function extractQueryParamValueFromUrl(paramToExtract) {
  return getQueryParams(window.location)[paramToExtract];
}

export function redirectToLocalUrl(url, baseUrl, replaceHistory = false) {
  const fullUrl = `${baseUrl}${url}`;
  if (replaceHistory) {
    window.history.replaceState(null, "", fullUrl);
  }
  window.location.href = fullUrl;
}

export function objectAt(object, paths) {
  function extractContent(currentObject, currentPath) {
    return currentObject
      ? currentObject[currentPath]
      : undefined;
  }

  return paths.split(".")
    .reduce((currentObject, currentPath) => extractContent(currentObject, currentPath), object);
}

export function isNotEmpty(value){
  return !isEmpty(value);
}

export function isEmpty(data) {
  if (!data) {
    return true;
  }
  if (Array.isArray(data) && data.length === 0) {
    return true;
  }
  return typeof data === "object" && Object.entries(data).length === 0;
}

export function isNotEmptyTrimmedValue(value){
  return isNotEmpty(value.trim());
}

export function isNameValid(value){
  const regex = RegExp(/[`!@#$%^&*()_+\=\[\]{};:"\\|,.<>\/?~]/g);
  return !regex.test(value);
}

function btoaUnicode(str) {
  const utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode("0x" + p1);
  });

  return window.btoa(utf8Bytes);
}

export function encodeState(state) {
  return btoaUnicode(JSON.stringify(state));
}

export function decode(marshalledJson) {
  if (marshalledJson) {
    return JSON.parse(window.atob(marshalledJson));
  }
}

export function generateToken(email) {
  return convertToDecimal(email).reduce((acc, code) => acc + randomAlphabet() + code);
}

function convertToDecimal(email) {
  return email.split("").map(x => x.charCodeAt(0));
}

export function splitKoreanNames(displayName) {
  let result = {
    "last": undefined,
    "first": undefined
  };

  if ((!displayName) || (displayName.length === 0)) {
    return result;
  }

  /* regex for Hangul Unicode characters */
  /* see https://stackoverflow.com/a/52989471/1955626 */
  const match = displayName.match(/[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/g);

  /* if matched chars, joined, match source, we have all Hangul! */
  if (match && (match.join("") === displayName)) {
    result["last"] = displayName.slice(0, 1);
    result["first"] = displayName.slice(1);
  } else {
    result["last"] = displayName;
  }

  return result;
}

function randomAlphabet() {
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  return alphabets.charAt(Math.floor(Math.random() * alphabets.length));
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const doWhenLoaded = (fun) => {
  return (...args) => {
    if (document.readyState === "complete") {
      fun(...args);
    } else {
      document.addEventListener("readystatechange", () => {
        if (document.readyState === "complete") {
          fun(...args);
        }
      });
    }
  };
};

export const is4byteCharPresent = (string) => {
  return /[\u{10000}-\u{10FFFF}]/u.test(string);
};

export const getCartAvailability = () => {
  // TODO: REMOVE ME WHEN FINISHED
  const cartElement = document.getElementsByClassName("shopping-bag-product-count")[0];
  let cartSize = "0";
  if (cartElement) {
    cartSize = cartElement.innerText;
  }
  return parseInt(cartSize) > 0;
};

export function objectToQueryParams(object) {
  return Object.keys(object).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(object[key])).join("&");
}

export const isSuccessResponseCode = (responseCode) => [200, 201, 204].includes(responseCode);

export const isConflictResponseCode = (responseCode) => responseCode === 409;

export function getGenderFromTitle(title) {
  switch (title) {
  case "mrs":
  case "ms":
  case "miss":
    return Genders.FEMALE;
  case "mr":
    return Genders.MALE;
  case "A":
    return Genders.GENDERX;
  default:
    return Genders.NOTSAY;
  }
}

export function isGuestCheckoutAllowedFromOptimizely(isGuestCheckoutAllowedDefaultValue){
  if (window.disableGuestCheckout){
    return false;
  }
  return  isGuestCheckoutAllowedDefaultValue;
}

export const doGuestCheckout = (email, locale, GTMCheckoutEventsPusher) => {
  GTMCheckoutEventsPusher("Guest");
  redirectToLocalUrl(`/login/checkout/access/guest?email=${encodeURIComponent(email)}`, locale.baseUrl);
};

export const reloadIfPkceConfigIsNotPresents = (pkceConfig) => {
  const sessionStorageReloadingName = "reloading";
  if (!pkceConfig && !window.sessionStorage.getItem(sessionStorageReloadingName)) {
    window.sessionStorage.setItem(sessionStorageReloadingName, "true");
    datadogLogs.logger.warn("PkceConfig not presents in the url: Reloading!");
    window.location.reload();
  } else {
    window.sessionStorage.removeItem(sessionStorageReloadingName);
  }
};

export const showLoader = () => {
  const showLoaderEvent = new CustomEvent("Show Loader");
  document.dispatchEvent(showLoaderEvent);
};

export const hideLoader = () => {
  const hideLoaderEvent = new CustomEvent("Hide Loader");
  document.dispatchEvent(hideLoaderEvent);
};

export const subscribeLoaderEvent = (hideLoader, showLoader) => {
  document.addEventListener("Show Loader", showLoader);
  document.addEventListener("Hide Loader", hideLoader);
};

export function loginProbe(event, type) {
  let date = new Date();
  date.setTime(date.getTime() + (60 * 1000));
  document.cookie = `loginTimestamp=${event}-${type}-${Date.now()}; expires=${date}`;
}

export function deviceProbe(country, idpProvider) {
  let device = "";
  if ("ontouchstart" in document.documentElement) {
    device = "mobile or tablet";
  } else {
    // everything else (desktop)
    device = "desktop";
  }
  datadogLogs.logger.info("Device: " + device);
}

export const createScriptDOMElement = ({ testId, text, src }) => {
  const element = document.createElement("script");
  element.type = "text/javascript";
  element.async = true;
  element.setAttribute("data-testid", testId);
  element.text = text;
  element.src = src;

  return element;
};
