import React from "react";
import styles from "./MarketingConsent.module.scss";
import * as PropTypes from "prop-types";
import { Checkbox } from "@gucci-private/gucci-react-components";
import { GTMEmailOptEventsPusher } from "../../../common/analytics";
import { i18n } from "../../../common/i18n";

function MarketingConsent({ isMarketingConsentFlag, setMarketingConsentFlag, country, language }) {
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country,language,baseUrl);

  function onChange(event) {
    setMarketingConsentFlag(event.target.checked);
    const eventAction = event.target.checked ? "opt in" : "opt out";
    GTMEmailOptEventsPusher({
      action: eventAction,
      label: window.location.pathname
    });
  }

  return (
    <div className={styles.wrapper}>
      <Checkbox
        id="marketing-consent"
        checked={isMarketingConsentFlag}
        onChange={onChange}
        label={locale.i18n("labels.MARKETING_CONSENT_CHECKBOX")}
        withBorder={true}
      />
    </div>
  );
}

MarketingConsent.propTypes = {
  isMarketingConsentFlag: PropTypes.bool.isRequired,
  setMarketingConsentFlag: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default MarketingConsent;
