import React from "react";
import Access from "../access/Access";
import { useEffect } from "react";
import { ecommerceCheckoutSignIn } from "../../common/analytics";

function Checkout() {
  useEffect(() => {
    ecommerceCheckoutSignIn();
  }, []);

  return (
    <div data-testid="checkout-access-gucci">
      <Access isCheckout={true} />
    </div>
  );
}

export default Checkout;