import React from "react";
import * as PropTypes from "prop-types";
import styles from "./Loader.module.scss";

function Loader({ additionalClass }) {
  return <div data-testid={"loader"} className={`${styles.loader}${additionalClass ? " "+additionalClass : ""}`} />;
}

Loader.propTypes = {
  additionalClass: PropTypes.string
};
export default Loader;
