import { getUser } from "./customerReferenceApi";

export async function isCRMCustomerExisting(accessToken) {
  const customerResponse = await getUser(accessToken);
  switch (customerResponse.status) {
  case 200:
    return true;
  case 404:
    return false;
  default:
    throw new Error("Customer reference returned " + customerResponse.status);
  }
}
