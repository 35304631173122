import React, { useEffect } from "react";
import { useStandalone } from "../standalone/StandaloneContext";

// Load Google TM library if the application is used in standalone mode
function GoogleTagManagerLoader() {
  const { isStandaloneLogin, client } = useStandalone();

  useEffect(() => {
    if (isStandaloneLogin) {
      const dataLayer = document.createElement("script");
      dataLayer.text = "dataLayer = [];";
      dataLayer.async = true;
      dataLayer.setAttribute("data-testid", "datalayer-script");
      document.body.appendChild(dataLayer);

      const script = document.createElement("script");
      script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${ client.GTM }');`;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(dataLayer);
        document.body.removeChild(script);
      };
    }
  }, []);

  return (<></>);
}

export default GoogleTagManagerLoader;
