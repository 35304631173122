import React from "react";
import styles from "./GucciHeaderAndFooter.module.scss";

function GucciFooter() {
  return (
    <div className={`${styles.gucciFooter} gucci-footer`} />
  );
}

export default GucciFooter;
