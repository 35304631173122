import { hybrisReferenceBaseUrl } from "../common/config";

export function getAssistantCustomerData(country, language) {
  const path = "assisted-service/ajax/assistant-customerdata";
  const requestOptions = {
    method: "GET"
  };
  const url = `${hybrisReferenceBaseUrl(country, language)}/${path}`;
  return fetch(url, requestOptions);
}
