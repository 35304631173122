import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import GucciHeader from "../../gucciHeaderAndFooter/GucciHeader";
import GucciFooter from "../../gucciHeaderAndFooter/GucciFooter";
import styles from "./LoaderWithTexts.module.scss";
import { Span } from "@gucci-private/gucci-react-components";
import Loader from "../../ui/loader/Loader";
import { getSourceConfig } from "../../../common/config";
import { ClientType } from "../../../common/constants";
import { subscribeLoaderEvent } from "../../../common/utils";
import { useLocale } from "../../../common/global";
import { i18n } from "../../../common/i18n";
import { useStandalone } from "../../standalone/StandaloneContext";

function LoaderWithTexts({ withHeader = true, withFooter = true }) {
  const sourceConfig = getSourceConfig();
  const [showLoader, setShowLoader] = useState(false);
  const globalLocale = useLocale();
  const country = globalLocale.country;
  const language = globalLocale.language;
  const baseUrl = `${window.location.origin}/${country}/${language}`;
  const locale = i18n(country, language, baseUrl);
  const { client } = useStandalone();
  const fields = computeFields();

  useEffect(() => {
    subscribeLoaderEvent(() => setShowLoader(false), () => setShowLoader(true));
  }, []);

  const renderSimpleLoader = () => <div className={styles.simpleLoginWrapper}><Loader /></div>;

  function getDefaultFields() {
    return {
      title: locale.messages.LOADER_TITLE,
      subtitle: locale.messages.LOADER_SUBTITLE
    };
  }

  function getYugaFields() {
    return {
      title: locale.messages.LOADER_YUGA_TITLE,
      subtitle: locale.messages.LOADER_YUGA_SUBTITLE
    };
  }

  function computeFields() {
    if (client?.source === ClientType.YUGA) {
      return getYugaFields();
    }
    return getDefaultFields();
  }

  return (
    <div className={`${styles.loaderMainWrapper} ${showLoader ? styles.showLoader: ""}`}>
      {
        sourceConfig?.client?.source === ClientType.GUCCI_STORE ?
          renderSimpleLoader() :
          <div className={`${styles.loaderWithTextsContainer} loader-with-texts-container`}>
            {withHeader && <GucciHeader />}
            <div className={styles.loaderWithTextsWrapper}>
              <div data-testid="loaderWithTexts" className={styles.loader} />
              <div className={styles.textWrapper}>
                <Span size="lg" weight="book" >{fields.title}</Span>
                <Span size="lg" weight="book" >{fields.subtitle}</Span>
              </div>
            </div>
            {withFooter && <GucciFooter />}
          </div>
      }
    </div>
  );
}

LoaderWithTexts.propTypes = {
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool
};
export default LoaderWithTexts;
